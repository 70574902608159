const objRD = {
  D_E_L_E_T_:         '',
  DTAPRRH:            '',
  DTAPRFINAL:         '',
  DTAPRRRO:           '',
  DTINSERCAO:         '',
  QS_ACOCARB:         '',
  QS_ACOINOX:         '',
  QS_ADTRANS:         '',
  QS_ALUMIN:          '',
  QS_APROC:           '',
  QS_APROS:           '',
  QS_APROV:           '',
  QS_APROR:           '',
  QS_AREA:            '',
  QS_AUTOM:           '',
  QS_BENEF:           '',
  QS_BENEFIC:         '',
  QS_CARGO:           '',
  QS_CC:              '',
  QS_CHAPA:           '',
  QS_CLIENTE:         '',
  QS_CODESC:          '',
  QS_CODPERF:         '',
  QS_DESCARG:         '',
  QS_DESCESC:         '',
  QS_DESCRIC:         '',
  QS_DESCSU:          '',
  QS_DESCSUM:         '',
  QS_DESCUST:         '',
  QS_DESGRTR:         '',
  QS_DTABERT:         '',
  DTAPRCA:         '',
  QS_DTFCONT:         '',
  QS_DTFECH:          '',
  QS_DTNECES:         '',
  QS_DTPREVI:         '',
  QS_ESPCON:          '',
  QS_ESPCONH:         '',
  QS_FILIAL:          '',
  QS_FILPOST:         '',
  QS_FILRESP:         '',
  QS_FORMCAO:         '',
  QS_FUNCAO:          '',
  QS_FUNSOLD:         '',
  QS_GRTRIAG:         '',
  QS_INSALUB:         '',
  QS_JUST:            '',
  QS_JUSTIF:          '',
  QS_LIGNIQ:          '',
  QS_MATRESP:         '',
  QS_MOTIVO:          '',
  QS_MSGAPV:          '',
  QS_MSGREP:          '',
  QS_NRVAGA:          0,
  QS_OBSERV:          '',
  QS_OBSERVA:         '',
  QS_OBSERVR:         '',
  QS_OUTRMAT:         '',
  QS_PERF:            '',
  QS_PERICUL:         '',
  QS_PONTOS:          0,
  QS_POSTO:           '',
  QS_PRAZO:           0,
  QS_PROCESS:         '',
  QS_PRZCONT:         '',
  QS_PSOUTRO:         '',
  QS_RECRUT:          '',
  QS_REINSC:          '',
  QS_SALARIO:         0,
  QS_SOLDELT:         '',
  QS_SOLDTIG:         '',
  QS_SOLICIT:         '',
  QS_SOLMAG:          '',
  QS_SOLMIG:          '',
  QS_SOLTUB:          '',
  QS_STATUS:          '',
  QS_TABELA:          '',
  QS_TESTE:           '',
  QS_TIPO:            '',
  QS_TITANIO:         '',
  QS_TPCONTR:         '',
  QS_VAGA:            '',
  QS_VAGAFEC:         0,
  QS_VCUSTO:          0,
  R_E_C_D_E_L_:       0,
  R_E_C_N_O_:         3226,
};

export default objRD;
