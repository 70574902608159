const ICOModel = {

  CO: 'Todos'.toLocaleUpperCase(),

  CC: 'Todos'.toLocaleUpperCase(),

};



export default ICOModel;
