import React, { useEffect, useState } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import {
  Colors,
} from '../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../../components/Modals/styled';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import ApiWS from '../../../../../../services/api.service';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
}



const TriaAcessoCRUDSMEModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [listUserDrop, setListUserDrop] = useState([]);
  const [listGroupDrop, setListGroupDrop] = useState([]);


  function postFetchUser() {
    setLoading(true);

    const url = '/gi/usuario/consultar';
    const payload = [{
      CHECKEMAIL: '',
      EMAIL: '',
      FOTO: '',
      ID: '',
      LOGIN: '',
      MATRICULA: '',
      NOME: '',
      STATUS: '',
      TIPO: '',
    }];

    ApiWS()
      .post(url, payload)
      .then((res) => {
        res.data[0].forEach((user, index) => {
          setListUserDrop((listUserDrop) => [ ...listUserDrop, {
            key: index,
            label: user.NOME,
            value: user.ID,
          }]);
        });
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveAccess', JSON.stringify(err));
      })
      .finally(() => postFetchGroup());
  };

  const postFetchGroup = () => {
    const url = '/sm/manutencaotriagem/consulta/grupo/';
    const payload = {
      CODIGO: '',
      DESCRICAO: '',
    };

    ApiWS()
      .post(url, payload)
      .then((res) => {
        res.data.forEach((gp, index) => {
          setListGroupDrop((listGroupDrop) => [ ...listGroupDrop, {
            key: index,
            label: `${gp.CODIGO}${gp.DESCRICAO}`,
            value: gp.CODIGO,
          }]);
        });
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveAccess', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function showUserForDelete() {
    setListUserDrop([{
      key: 0,
      label: props?.valuesRemove?.USUARIO,
      value: props?.valuesRemove?.IDUSUARIO,
    }]);
    setListGroupDrop([{
      key: 0,
      label: props?.valuesRemove?.DESCRICAO,
      value: props?.valuesRemove?.CODIGO,
    }]);
  };


  function postSaveAccess(values) {
    setLoading(true);

    const url = '/sm/manutencaotriagem/salvar/grupousuario/';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data || 'Salvo com sucesso!',
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data || 'Não foi possível salvar!',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveAccess', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postRemoveAccess(values) {
    setLoading(true);

    const url = '/sm/manutencaotriagem/salvar/grupousuario/';
    const payload = [{ ...values, DEL: '*' }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data || 'Removido com sucesso!',
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data || 'Não pode ser removido!',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveAccess', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    if(props.type === 'NEW') {
      postFetchUser();
    }

    if(props.type === 'REMOVE') {
      showUserForDelete();
    }
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            IDUSUARIO: props?.valuesEdit?.IDUSUARIO || props?.valuesRemove?.IDUSUARIO || '',
            USUARIO: props?.valuesEdit?.USUARIO || props?.valuesRemove?.USUARIO || '',
            IDGRUPO: props.valuesEdit?.CODIGO || props?.valuesRemove?.CODIGO || '',
            GRUPO: props.valuesEdit?.GRUPO || props?.valuesRemove?.GRUPO || '',

            DEL: '',
          }}
          validationSchema={
            Yup.object().shape({
              IDUSUARIO: Yup.string().required('Obrigatório'),
              IDGRUPO: Yup.string().required('Obrigatório'),

            })
          }
          onSubmit={(values) => {
            if (props?.type !== 'REMOVE') {
              postSaveAccess(values);
            }
            else if (props?.type === 'REMOVE') {
              postRemoveAccess(values);
            }
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                <>
                  <DropdownInput
                    disabled={loading || props.type === 'REMOVE'}
                    name={'IDUSUARIO'}
                    label={'Usuário'}
                    placeholder={'Selecione.'}
                    dropArray={listUserDrop}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.IDUSUARIO}
                  />


                  <DropdownInput
                    disabled={loading || props.type === 'REMOVE'}
                    name={'IDGRUPO'}
                    label={'Grupo'}
                    placeholder={'Selecione.'}
                    dropArray={listGroupDrop}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.IDGRUPO}
                  />

                </>


              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default TriaAcessoCRUDSMEModal;
