const IConsultaModel = {

  TIPO: 'TODOS',

  MES: 'TODOS',

  ANO: '',

  CR: 'TODOS',

  STATUS: 'TODOS',

};



export default IConsultaModel;
