import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Formik,
} from 'formik';


import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
} from '../../../../../common/styles/styled.layout';


import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';


import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';

import IConsultaModel from './Consulta.model';

import PEXConsultaValidationSchema from './Consulta.validation';
import { IToast } from '../../../../../components/IToast/Toast';
import { PEX_NavRoutes } from '../../../../../navigation/navs/Corporate';
import { PEXMonths, PEXStatus, PEXTipos } from '../../../../../common/arrays/PEXImportacao';
import { currentCompleteYear } from '../../../../../utils/DateUtils';
import PEXHistAprovadoresModal from '../Modals/HistAprovadoresModal';
import PEXDetalhesModal from '../Modals/DetalhesModal';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import { IconApproval, IconList } from '../../../../../components/Icons';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';


const Consulta_IndexScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(true);

  const [listDropCR, setListDropCR] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhuma consulta aqui!',
    description: 'Não há nenhuma consulta aqui',
  });

  const [listData, setListData] = useState([]);

  const [showModalDetails, setShowModalDetails] = useState(false);
  const [valuesDetails, setValuesDetails] = useState(null);

  const [showModalApproval, setShowModalApproval] = useState(false);
  const [valuesApproval, setValuesApproval] = useState(null);

  const unauthorizedObject = {
    image: Images.error401,
    title: 'Sem autorização de acesso!',
    description: 'Não há nada aqui',
  };

  const [showListData, setShowListData] = useState(true);
  const [listaCarregando, setListaCarregando] = useState(false);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);



  function postDropCR() {
    setLoading(true);

    const url = '/pex/co/consulta/cc';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((conta, index) => {
            setListDropCR((list) => [...list, {
              key: index,
              label: conta.TELA,
              value: conta.CONTA,
            }],
            );
          });
        };
      })

      .catch((error) => {
        console.error('postDropCC', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Contabeis. Contate o suporte!',
          });

          setEmptyObject({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Contabeis. Contate o suporte! ${error.response.status}`,
          });

          setEmptyObject({
            image: Images.other500,
            title: 'Erro na consulta!',
            description: 'Contate o suporte!',
          });
        }
      })

      .finally(() => {
        setLoading(false);
        setShowListData(true);
      });
  };


  function postFethPEX(values: any, page_iteration: number) {
    setLoading(true);

    const url = '/pex/consulta';

    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          setListData(resp.data[0]);

          setTotalResults(resp.data[1][0].TOTAL);
          setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
        }
      })
      .catch((err) => {
        console.error('postFethPEX:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFethPEX(valuesPayload, page_var);
    setPage(page_var);
  };


  function postExport(values: any) {
    setLoading(true);

    const url = '/efetivo/home/exporta';



    const payload = {
      'OS': values.input_os || values.select_os,
      'TIPO': values.input_tipo,
      'FUNCAO': values.input_funcao,
      'MATRICULA': values.input_matricula,
      'NOME': values.input_funcionario,
      'SITUAC': values.input_situacao,
      'FERIAS': values.input_ferias,
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    postDropCR();
  }, []);


  const columns = [
    {
      dataField: 'NUM',
      text: 'Num.',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'CR',
      text: 'CR',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'REV',
      text: 'Rev.',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'USUARIO',
      text: 'Usuário',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'ANO',
      text: 'Ano',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'MES',
      text: 'Mês',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'DTCADASTRO',
      text: 'Dt. Cadastro',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'HORACADASTRO',
      text: 'Hr. Cadastro',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: '',
      text: 'Detalhes',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.DETAILS}
            onClick={() => {
              setValuesDetails({ 'NUM': row.NUM });
              setTimeout(() => {
                setShowModalDetails(true);
              }, 300);
            }}
            icon={
              <IconList
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Aprovadores',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: (row, _cell) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.APPROVALS}
            onClick={() => {
              setValuesApproval({ 'NUM': row?.NUM });
              setTimeout(() => {
                setShowModalApproval(true);
              }, 300);
            }}
            icon={
              <IconApproval
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },

  ];


  const options = {
    custom: true,
    totalSize: listData?.length,
  };


  return (

    <Screen>
      {/* {JSON.stringify(listData)} */}
      <NavHeader>

        <NavUser backHome />


        <NavComp
          navKey={4}
          navArray={PEX_NavRoutes}
          title={'PEX'}
        />

      </NavHeader>


      <Content>

        <ContentStyled fluid>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && !authorized && (
            <EmptyContent
              image={unauthorizedObject.image}
              title={unauthorizedObject.title}
              description={unauthorizedObject.description}
            />
          )}


          {!loading && authorized && (

            <ContainerView>

              <SectionMargin>

                <SectionMargin>

                  <Formik
                    enableReinitialize
                    validateOnMount
                    validationSchema={PEXConsultaValidationSchema}
                    initialValues={IConsultaModel}
                    onSubmit={(values) => {
                      postFethPEX(values, 1);
                    }}>
                    {({
                      values,
                      resetForm,
                      isValid,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setValues,
                    }) => (

                      <FilterContainerForm auto onSubmit={handleSubmit}>

                        <Row>

                          <Col>
                            <DropdownInput
                              disabled={loading || listaCarregando}
                              name={'TIPO'}
                              label={'Tipo'}
                              placeholder={'Todos'}
                              dropArray={PEXTipos}
                              value={values.TIPO}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <TextInput
                              disabled={loading}
                              type={'text'}
                              name={'ANO'}
                              label={'Ano'}
                              placeholder={'Ano'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.ANO || currentCompleteYear()} />
                          </Col>


                          <Col>
                            <DropdownInput
                              disabled={loading || listaCarregando}
                              name={'MES'}
                              label={'Mês'}
                              placeholder={'Todos'}
                              dropArray={PEXMonths}
                              value={values.MES}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              disabled={loading || listaCarregando}
                              name={'CR'}
                              label={'CR'}
                              placeholder={'Todos'}
                              dropArray={listDropCR}
                              value={values.CR}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              disabled={loading || listaCarregando}
                              name={'STATUS'}
                              label={'Status'}
                              placeholder={'Todos'}
                              dropArray={PEXStatus}
                              value={values.STATUS}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                        </Row>


                        <RowButtonFiltersView>

                          <IButton
                            disabled={loading || listaCarregando}
                            text={'Limpar'}
                            backgroundColor={Colors?.gray}
                            type={'reset'}
                            onClick={() => {
                              resetForm();

                              setValues(IConsultaModel);
                            }}
                          />


                          <IButton
                            disabled={loading || listaCarregando}
                            type={'submit'}
                            backgroundColor={Colors?.primary}
                            text={'Filtrar'}
                          />

                        </RowButtonFiltersView>

                      </FilterContainerForm>

                    )}

                  </Formik>

                </SectionMargin>


                <ContainerView>

                  {listaCarregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  {!loading && !listaCarregando && showListData && listData?.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}


                  {!loading && !listaCarregando && showListData && listData?.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          noQuantity={totalPage === 1}
                          data={listData}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                          actionPrevious={() => handlePagination(0)}
                          actionNext={() => handlePagination(1)}
                          currentPage={page}
                          totalPage={totalPage}
                          totalResults={totalResults}
                        />
                      )}
                    </PaginationProvider>
                  )}

                </ContainerView>


              </SectionMargin>

            </ContainerView>

          )}

        </ContentStyled>


        {showModalDetails && valuesDetails && (
          <PEXDetalhesModal
            show={true}
            onHide={() => setShowModalDetails(false)}
            values={valuesDetails}
          />
        )}

        {showModalApproval && valuesApproval && (
          <PEXHistAprovadoresModal
            show={true}
            onHide={() => setShowModalApproval(false)}
            values={valuesApproval}
          />
        )}

      </Content>

    </Screen>

  );
};



export default Consulta_IndexScreen;
