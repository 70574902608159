import React,
{
  useState,
  useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import { RDSituacao } from '../../../../../common/arrays/RDAtendimento';
import { Colors, Images, Metrics } from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../common/excel';
import { ContainerView, Content, ContentStyled, FilterContainerForm, RowButtonFiltersView, Screen, SectionMargin, TableRotateNextCss, TitleGroup } from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconHistory, IconPrinter } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import NameRoutes from '../../../../../navigation/name';
import { RD_NavRoutes } from '../../../../../navigation/navs/HumanResources';
import ApiWS from '../../../../../services/api.service';
import RDHistoricoModal from '../Modals/HistoricoRDModal';
import RdAtendimentoModel from './RD-atendimento.model';

const RD_AtendimentoScreen: React.FC = () => {
  const [modalHistorico, setModalHistorico] = useState(false);
  const [valueHistorico, setValueHistorico] = useState({});


  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [permission] = useState(true);
  const [error] = useState(false);


  const [listOSDrop, setListOSDrop] = useState([]);

  const [listRD, setListRD] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem RD\'s aqui!',
    description: 'Não há nenhuma RD para você',
  });


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  function postOSDrop() {
    setLoading(true);
    setShowList(false);

    const url = '/rd/atendimento/consulta/os';

    ApiWS()
      .get(url)
      .then((res) => {
        res.data.forEach((os, index) => {
          setListOSDrop((listOS) => [
            ...listOS,
            {
              key: index,
              label: os.OS,
              value: os.OS,
            },
          ]);
        });
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  function postFetch(values: any, page_iteration: any) {
    setLoading(true);
    setShowList(false);
    setValuesPayload(values);

    const url = '/rd/atendimento/consulta/rd';

    values = {
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_requestHuman,
    };

    ApiWS()
      .post(url, [values])
      .then((res) => {
        setListRD(res.data);
        setTotalResults(res.data[1][0].TOTAL);
        setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetch(valuesPayload, page_var);
    setPage(page_var);
  };


  useEffect(() => {
    postOSDrop();
  }, []);


  const options = {
    custom: true,
    totalSize: listRD.length,
  };

  const columns = [
    {
      dataField: 'QS_CC',
      text: 'OS',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'QS_VAGA',
      text: 'RD',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: 'SOLICITANTE',
      text: 'Solicitante',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'QS_DESCRIC',
      text: 'Função',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'QS_DESCARG',
      text: 'Cargo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => cell === null ? '' : cell,
    },
    {
      dataField: 'QS_NRVAGA',
      text: 'Vagas',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'QS_VAGAFEC',
      text: 'Vagas Fechadas',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'SALDO',
      text: 'Saldo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_DTABERT1',
      text: 'Dt. Emiss.',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_DTNECES1',
      text: 'Dt. Neces.',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_DTPREVI1',
      text: 'Dt. Prev.',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_STATUS',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_DESGRTR',
      text: 'Grd. Atend.',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: '',
      text: 'Hist.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.HISTORIC}
            onClick={() => {
              setModalHistorico(true);
              setValueHistorico(row);
            }}
            icon={
              <IconHistory
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Hist.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (_cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.PRINTER}
            onClick={() => {
              window.open(`/${NameRoutes.RD_Impressao}/${row.QS_VAGA}`, '_blank').focus();
            }}
            icon={
              <IconPrinter
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },

  ];


  return (
    <ToolkitProvider
      keyField={'id'}
      data={listRD}
      columns={columns}
      exportCSV={{
        blobType: ExcelConfigs.blobType,
        fileName: ExcelConfigs.POC_Consulta,
        onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
        exportAll: ExcelConfigs.exportAll,
        noAutoBOM: false,
        separator: ';',
      }}>
      {(propsTkProvider) => (
        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={4}
              navArray={RD_NavRoutes}
              title={'RD'}
            />
          </NavHeader>

          <Content>
            <ContentStyled fluid>

              <ContainerView>

                <SectionMargin>

                  <TitleGroup>
                    {'Consultar RD'.toUpperCase()}
                  </TitleGroup>

                  {loading && (<LoadingsComponent />)}


                  {permission && !error && (
                    <Formik
                      enableReinitialize
                      validateOnMount
                      // validationSchema={RDp}
                      initialValues={RdAtendimentoModel}
                      onSubmit={(values) => {
                        postFetch(values, 1);
                      }}
                    >
                      {({
                        values,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                      }) => (

                        <FilterContainerForm auto onSubmit={handleSubmit}>

                          <Row>

                            <Col>
                              <DropdownInput
                                disabled={loading}
                                name={'OS'}
                                label={'OS'}
                                placeholder={'Todos'}
                                dropArray={listOSDrop}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.OS}
                              />
                            </Col>


                            <Col>
                              <TextInput
                                disabled={loading}
                                type={'text'}
                                name={'NUM'}
                                label={'RD'}
                                placeholder={'RD'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.NUM}
                              />
                            </Col>

                            <Col>
                              <TextInput
                                disabled={loading}
                                type={'text'}
                                name={'FUNCAO'}
                                label={'Função'}
                                placeholder={'FUNCAO'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.FUNCAO}
                              />
                            </Col>

                          </Row>


                          <Row>

                            <Col>
                              <DropdownInput
                                disabled={loading}
                                name={'SITUAC'}
                                label={'Situação'}
                                dropArray={RDSituacao}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.SITUAC}
                              />
                            </Col>


                            <Col>
                              <TextInput
                                disabled={loading}
                                type={'date'}
                                name={'DTABERTINI'}
                                label={'Data de Abert. Inicial'}
                                placeholder={'Dt. Abert. Ini.'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.DTABERTINI}
                              />
                            </Col>


                            <Col>
                              <TextInput
                                disabled={loading}
                                type={'date'}
                                name={'DTABERTFIM'}
                                label={'Data de Abert. Final'}
                                placeholder={'Dt. Abert. Final'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.DTABERTFIM}
                              />
                            </Col>

                          </Row>


                          <Row>

                            <Col>
                              <TextInput
                                disabled={loading}
                                type={'date'}
                                name={'DTNECESINI'}
                                label={'Data de Neces. Inicial'}
                                placeholder={'Dt. Neces. Ini.'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.DTNECESINI}
                              />
                            </Col>


                            <Col>
                              <TextInput
                                disabled={loading}
                                type={'date'}
                                name={'DTNECESFIM'}
                                label={'Data de Neces. Final'}
                                placeholder={'Dt. Neces. Final'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.DTNECESFIM}
                              />
                            </Col>

                            <Col></Col>

                          </Row>

                          <RowButtonFiltersView>

                            <IButton
                              disabled={loading || listRD.length === 0}
                              backgroundColor={Colors?.excel}
                              text={'Exportar'}
                              onClick={() => {
                                propsTkProvider.csvProps.onExport();
                              }}
                            />


                            <IButton
                              text={'Limpar'}
                              backgroundColor={Colors?.gray}
                              type={'reset'}
                            />


                            <IButton
                              disabled={loading}
                              text={'Filtrar'}
                              backgroundColor={Colors?.primary}
                              type={'submit'}
                            />

                          </RowButtonFiltersView>

                        </FilterContainerForm>

                      )}
                    </Formik>
                  )}


                  {!permission && !error && (
                    <EmptyContent
                      image={Images.error401}
                      title={'Sem permissão!'}
                      description={'Você não tem permissão para acessar essa tela.'}
                    />
                  )}


                  {error && (
                    <EmptyContent
                      image={Images.other500}
                      title={'Erro!'}
                      description={'Ocorreu um erro! Tente novamente, caso o problema persista, contate o suporte.'}
                    />
                  )}


                  {!loading && showList && listRD.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}



                  {!loading && showList && listRD.length > 0 && (

                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          noQuantity={listRD.length < 10}
                          data={listRD}
                          columns={columns}
                          baseProps={propsTkProvider.baseProps}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                          actionPrevious={() => handlePagination(0)}
                          actionNext={() => handlePagination(1)}
                          currentPage={page}
                          totalPage={totalPage}
                          totalResults={totalResults}
                        />
                      )}
                    </PaginationProvider>

                  )}

                </SectionMargin>

              </ContainerView>

            </ContentStyled>

          </Content>
          {modalHistorico && (
            <RDHistoricoModal
              show={modalHistorico}
              todo={valueHistorico}
              onHide={() => {
                setModalHistorico(false);
                setTimeout(() => {
                  setValueHistorico(null);
                }, 300);
              }}
            />
          )}

        </Screen>
      )}

    </ToolkitProvider>
  );
};

export default RD_AtendimentoScreen;
