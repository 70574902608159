import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  Formik,
} from 'formik';


import {
  Colors,
  Images,
} from '../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
} from '../../../../../common/styles/styled.layout';


import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../components/Loadings';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';


import ApiWS from '../../../../../services/api.service';

import IAtualizarMesRefModel from './AtualizarMesRef.model';

import PEXAtualizarMesRefValidationSchema from './AtualizarMesRef.validation';
import { IToast } from '../../../../../components/IToast/Toast';
import { PEX_NavRoutes } from '../../../../../navigation/navs/Corporate';
import { PEXMonths, PEXStatus, PEXTipos } from '../../../../../common/arrays/PEXImportacao';
import { currentCompleteYear } from '../../../../../utils/DateUtils';


const PEX_AtualizarMesRefIndexScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const [err, setErr] = useState(false);

  const [listDropCR, setListDropCR] = useState([]);

  const errObject = {
    image: Images.other500,
    title: 'Erro!',
    description: 'Atualize a página ou contate o suporte!',
  };

  const emptyObject = {
    image: Images.empty,
    title: 'Nenhuma consulta aqui!',
    description: 'Não há nenhuma consulta aqui',
  };

  const unauthorizedObject = {
    image: Images.error401,
    title: 'Sem autorização de acesso!',
    description: 'Não há nada aqui',
  };

  const [valuesFetch, setValuesFetch] = useState([]);
  const [listData, setListData] = useState([
    {
      CONTA_OPE: '1.0',
      DESCR_OPE: 'MÃO DE OBRA',
      CONTA_CON: '1',
      DESCR_CON: '*** ATIVO ***',
    },
  ]);

  const [showListData, setShowListData] = useState(true);
  const [listaCarregando, setListaCarregando] = useState(false);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  function postAtualizarPEX(values: any) {
    setLoading(true);

    const url = '/pex/realizado/atualizamesref';

    const payload = values;

    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        console.error('postAtualizarPEX:::', JSON.stringify(err));

        setErr(false);

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postPermission() {
    setLoading(true);

    const url = '/pex/realizado/permissao';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          setAuthorized(true);
        }
        else {
          setAuthorized(false);
        };
      })
      .catch((error) => {
        console.error('postPermission', JSON.stringify(error.response, null, 2));
        setErr(true);

        setLoading(false);
        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as permissões de acesso. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as permissões de acesso. Contate o suporte! ${error.response.status}`,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const columns = [
  //   {
  //     dataField: 'NUM',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: 'CR',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '10%' };
  //     },
  //   },
  //   {
  //     dataField: 'TIPO',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: 'REV',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: 'USUARIO',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: 'ANO',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: 'MES',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: 'DTCADASTRO',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: 'HORACADASTRO',
  //     text: '',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: '',
  //     text: 'Detalhes',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },
  //   {
  //     dataField: '',
  //     text: 'Aprovadores',
  //     sort: true,
  //     headerStyle: () => {
  //       return { width: '25%' };
  //     },
  //   },

  // ];


  // const options = {
  //   custom: true,
  //   totalSize: listData.length,
  // };

  useEffect(() => {
    postPermission();
  }, []);


  return (

    <Screen>
      {/* {JSON.stringify(listData)} */}
      <NavHeader>

        <NavUser backHome />


        <NavComp
          navKey={5}
          navArray={PEX_NavRoutes}
          title={'PEX'}
        />

      </NavHeader>


      <Content>

        <ContentStyled fluid>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && err && (
            <EmptyContent
              image={errObject.image}
              title={errObject.title}
              description={errObject.description}
            />
          )}


          {!loading && !err && !authorized && (
            <EmptyContent
              image={unauthorizedObject.image}
              title={unauthorizedObject.title}
              description={unauthorizedObject.description}
            />
          )}


          {!loading && !err && authorized && (

            <ContainerView>

              <SectionMargin>

                <SectionMargin>

                  <Formik
                    enableReinitialize
                    validateOnMount
                    validationSchema={PEXAtualizarMesRefValidationSchema}
                    initialValues={IAtualizarMesRefModel}
                    onSubmit={(values) => {
                      postAtualizarPEX(values);
                    }}>
                    {({
                      values,
                      resetForm,
                      isValid,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setValues,
                    }) => (

                      <FilterContainerForm auto onSubmit={handleSubmit}>

                        <Row>

                          <Col>
                            <TextInput
                              disabled={loading}
                              type={'number'}
                              name={'ANO'}
                              label={'Ano'}
                              placeholder={'Ano'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.ANO} />
                          </Col>


                          <Col>
                            <DropdownInput
                              disabled={loading || listaCarregando}
                              name={'MES'}
                              label={'Mês'}
                              placeholder={'Selecione...'}
                              dropArray={PEXMonths}
                              value={values.MES}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                        </Row>


                        <RowButtonFiltersView>

                          <IButton
                            disabled={loading || listaCarregando}
                            type={'submit'}
                            backgroundColor={Colors?.primary}
                            text={'Atualizar'}
                          />

                        </RowButtonFiltersView>

                      </FilterContainerForm>

                    )}

                  </Formik>

                </SectionMargin>


                <ContainerView>

                  {listaCarregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  {!loading && !listaCarregando && showListData && listData.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}


                  {/* {!loading && !listaCarregando && showListData && listData.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          noQuantity={totalPage === 1}
                          data={listData}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                          actionPrevious={() => handlePagination(0)}
                          actionNext={() => handlePagination(1)}
                          currentPage={page}
                          totalPage={totalPage}
                          totalResults={totalResults}
                        />
                      )}
                    </PaginationProvider>
                  )} */}

                </ContainerView>


              </SectionMargin>

            </ContainerView>

          )}

        </ContentStyled>

      </Content>

    </Screen>

  );
};



export default PEX_AtualizarMesRefIndexScreen;
