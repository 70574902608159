import React, { useEffect, useState } from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import { Colors, Images, Metrics } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import { TitleGroup } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconAddTag, IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import { currentDateToInput, formataData } from '../../../../../../../utils/DateUtils';
import PermissionChangeModal from './003PermissionChangeModal';



interface IProps {
  idUser: string;
  type?: any;
}



const UserGroupSection: React.FC<IProps> = ({ idUser, type }) => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [valuesModal, setValuesModal] = useState({});

  const emptyObject = {
    image: Images.empty,
    title: 'Sem Permissões!',
    description: 'Usuário não possui nenhuma permissão.',
  };

  const [listSystemDrop, setListSystemDrop] = useState([]);
  const [listPermissionDrop, setListPermissionDrop] = useState([]);
  const [listCCDrop, setListCCDrop] = useState([]);

  const [listPermissionGrid, setListPermissionsGrid] = useState([]);

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  function postFetchSystemDrop(values) {
    setLoading(true);
    setListSystemDrop([]);

    const url = 'gi/sistema/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data[0].forEach((group, index) => {
            setListSystemDrop((list) => [...list, {
              key: index,
              label: group.DESCR,
              value: group.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postFetchPermissionDrop(values) {
    setLoading(true);
    setListPermissionDrop([]);

    const url = 'gi/regra/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data[0].forEach((group, index) => {
            setListPermissionDrop((list) => [...list, {
              key: index,
              label: group.DESCR,
              value: group.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postFetchCCDrop(values) {
    setLoading(true);
    setListCCDrop([]);

    const url = 'gi/centrocusto/empresa/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data[0].forEach((item, index) => {
            setListCCDrop((list) => [...list, {
              key: index,
              label: `${item?.DESCEMP?.substring(0, 3)} - ${item?.CC} - ${item?.DESCR}`,
              value: item?.IDCCEMP,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postFetchPermissionGrid(values, page_iteration) {
    setLoading(true);
    setShowList(false);
    setValuesPayload(values);
    setListPermissionsGrid([]);

    const url = 'gi/usuario/permissao/consultar';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListPermissionsGrid(res.data[0]);
          setTotalResults(res.data[1][0].TOTAL);
          setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchPermissionGrid', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  function postAddPermission_x_User(values, resetForm) {
    setLoading(true);

    let url = '';

    url = 'gi/usuario/permissao/adicionar';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });
          postFetchPermissionGrid({ IDUSUARIO: idUser }, 1);
          resetForm();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postAddPermission_x_User', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postRemovePermission_x_User(values) {
    setLoading(true);

    let url = '';

    url = 'gi/usuario/permissao/remover';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });
          postFetchPermissionGrid({ IDUSUARIO: idUser }, 1);
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemovePermission_x_User', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchPermissionGrid(valuesPayload, page_var);
    setPage(page_var);
  };


  useEffect(() => {
    postFetchSystemDrop({ DESCR: '', IDDEP: '' });
  }, []);

  useEffect(() => {
    postFetchPermissionDrop({ DESCR: '', IDSIS: '' });
  }, []);

  useEffect(() => {
    postFetchCCDrop({ IDCC: '' });
  }, []);

  useEffect(() => {
    postFetchPermissionGrid({ IDUSUARIO: idUser }, 1);
  }, []);


  const options = {
    custom: true,
    totalSize: listPermissionGrid.length,
  };

  const columns = [
    {
      dataField: 'IDREGRA',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'REGRA',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'CC',
      text: 'Centro de Custo',
      sort: true,
    },
    {
      dataField: 'DTINI',
      text: 'De',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTINI && formataData(row?.DTINI.replaceAll('-', '').substring(0, 8));
      },
    },
    {
      dataField: 'DTFIM',
      text: 'Até',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTFIM && formataData(row?.DTFIM.replaceAll('-', '').substring(0, 8));
      },
    },
    {
      dataField: '',
      text: loading || type !== 'REMOVE' && 'Editar',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <>
            {loading || type !== 'REMOVE' && (
              <ButtonTableAction
                tooltip={TextTooltip.EDIT}
                icon={
                  <IconEdit color={Colors?.black} size={16} />
                }
                onClick={() => {
                  setShowModal(true);
                  setValuesModal(row);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      dataField: '',
      text: loading || type !== 'REMOVE' && 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <>
            {loading || type !== 'REMOVE' && (
              <ButtonTableAction
                tooltip={TextTooltip.REMOVE}
                icon={
                  <IconTrash color={Colors?.black} size={16} />
                }
                onClick={() => {
                  postRemovePermission_x_User(row);
                }}
              />
            )}
          </>
        );
      },
    },
  ];


  const columnsConsult = [
    {
      dataField: 'IDREGRA',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'REGRA',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'CC',
      text: 'Centro de Custo',
      sort: true,
    },
    {
      dataField: 'DTINI',
      text: 'De',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTINI && formataData(row?.DTINI.replaceAll('-', '').substring(0, 8));
      },
    },
    {
      dataField: 'DTFIM',
      text: 'Até',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTFIM && formataData(row?.DTFIM.replaceAll('-', '').substring(0, 8));
      },
    },
  ];



  return (
    <>

      <TitleGroup>
        {'Permissões'.toUpperCase()}
      </TitleGroup>


      {loading && (
        <LoadingsComponent />
      )}

      {type !== 'CONSULT' && (

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            IDUSUARIO: idUser || '',
            IDREGRA: '',
            IDSIS: '',
            IDCCEMP: '',
            DTINI: currentDateToInput(),
            DTFIM: null,
          }}
          validationSchema={
            Yup.object().shape({
              IDUSUARIO: Yup.string().required('Obrigatório!'),
              IDREGRA: Yup.string().required('Obrigatório!'),
              IDSIS: Yup.string(),
              IDCCEMP: Yup.string().required('Obrigatório!'),
              DTINI: Yup.string().required('Obrigatório!'),
            })
          }
          onSubmit={(values, { resetForm }) => {
            postAddPermission_x_User(values, resetForm);
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              {!loading && (
                <>
                  <Row>

                    <Col xs={6}>
                      <DropdownInput
                        disabled={loading || type === 'REMOVE'}
                        name={'IDSIS'}
                        label={'Sistema'}
                        placeholder={'Todos'}
                        defaultValue={values.IDSIS}
                        dropArray={listSystemDrop}
                        onChange={(e) => {
                          handleChange(e);
                          postFetchPermissionDrop({ DESCR: '', IDSIS: e.target.value });
                        }}
                        onBlur={handleBlur}
                        value={values.IDSIS}
                      />
                    </Col>


                    <Col xs={6}>
                      <DropdownInput
                        disabled={loading || type === 'REMOVE'}
                        name={'IDREGRA'}
                        label={'Regra'}
                        placeholder={'Selecione'}
                        defaultValue={values.IDREGRA}
                        dropArray={listPermissionDrop}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.IDREGRA}
                      />
                    </Col>


                    <Col xs={6}>
                      <DropdownInput
                        disabled={loading || type === 'REMOVE'}
                        name={'IDCCEMP'}
                        label={'CC'}
                        placeholder={'Selecione'}
                        defaultValue={values.IDCCEMP}
                        dropArray={listCCDrop}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.IDCCEMP}
                      />
                    </Col>


                    <Col xs={2}>
                      <TextInput
                        disabled={loading || type === 'REMOVE'}
                        name={'DTINI'}
                        label={'De'}
                        type={'date'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DTINI}
                      />
                    </Col>


                    <Col xs={2}>
                      <TextInput
                        disabled={loading || type === 'REMOVE'}
                        name={'DTFIM'}
                        label={'Até'}
                        type={'date'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DTFIM}
                      />
                    </Col>


                    <Col xs={1}>
                      <IButton
                        disabled={loading || type === 'REMOVE'}
                        type={'submit'}
                        backgroundColor={Colors?.white}
                        iconLeft={<IconAddTag size={30} color={Colors?.buttonOk} />}
                      />
                    </Col>

                  </Row>

                </>
              )}

            </Form>

          )}
        </Formik>

      )}

      {!loading && showList && listPermissionGrid.length === 0 && (
        <EmptyContent
          title={emptyObject.title}
          description={emptyObject.description}
        />
      )}


      {!loading && showList && listPermissionGrid.length > 0 && (

        <PaginationProvider
          pagination={paginationFactory(options)}>
          {({
            paginationProps,
            paginationTableProps,
          }) => (
            <TableCuston
              noQuantity={totalPage === 1}
              data={listPermissionGrid}
              columns={type === 'CONSULT' ? columnsConsult : columns}
              paginationProps={paginationProps}
              paginationTableProps={paginationTableProps}
              actionPrevious={() => handlePagination(0)}
              actionNext={() => handlePagination(1)}
              currentPage={page}
              totalPage={totalPage}
              totalResults={totalResults}
            />
          )}
        </PaginationProvider>

      )}



      {showModal && (
        <PermissionChangeModal
          show={showModal}
          values={valuesModal}
          onHide={() => setShowModal(false)}
          fetchNow={() => postFetchPermissionGrid({ IDUSUARIO: idUser }, 1)}
          listSystemDrop={listSystemDrop}
          listPermissionDrop={listPermissionDrop}
          listCCDrop={listCCDrop}
        />
      )}

    </>
  );
};



export default UserGroupSection;

