import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../common/constants';

import {
  ObsTitleGroup,
  SectionMargin,
  TableDefaultStyled,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';



interface IProps {
  show: boolean;
  numRD: any;
  aprovadores: Array<any>;
  onHide?: any;
}



const RDApproversModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {`Aprovadores RD: ${props.numRD}`.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          {(!props.aprovadores || props.aprovadores === null || props.aprovadores.length === 0) && (
            <h5>SEM Aprovadores</h5>
          )}


          {props.aprovadores && props.aprovadores.length > 0 && (
            <div>

              <SectionMargin>
                <ObsTitleGroup>Aprovadores RRO</ObsTitleGroup>

                <TableDefaultStyled>
                  <thead className="thead-two">
                    <tr>
                      <th>Nome</th>
                      <th>Email</th>
                    </tr>
                  </thead>

                  <tbody className="tbody-two">
                    {props.aprovadores.map((apr, key) => (
                      apr.FUNCAO === 'RRO' && (
                        <tr key={key}>
                          <td>{apr.NOME}</td>
                          <td>{apr.EMAIL}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </TableDefaultStyled>
              </SectionMargin>


              <div>
                <ObsTitleGroup>Aprovadores Final</ObsTitleGroup>

                <TableDefaultStyled>
                  <thead className="thead-two">
                    <tr>
                      <th>Nome</th>
                      <th>Email</th>
                    </tr>
                  </thead>

                  <tbody className="tbody-two">
                    {props.aprovadores.map((apr, key) => (
                      apr.FUNCAO === 'GESTOR' && (
                        <tr key={key}>
                          <td>{apr.NOME}</td>
                          <td>{apr.EMAIL}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </TableDefaultStyled>

              </div>


              <div>
                <ObsTitleGroup>Aprovadores CA</ObsTitleGroup>

                <TableDefaultStyled>
                  <thead className="thead-two">
                    <tr>
                      <th>Nome</th>
                      <th>Email</th>
                    </tr>
                  </thead>

                  <tbody className="tbody-two">
                    {props.aprovadores.map((apr, key) => (
                      apr.FUNCAO === 'CA' && (
                        <tr key={key}>
                          <td>{apr.NOME}</td>
                          <td>{apr.EMAIL}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </TableDefaultStyled>

              </div>
            </div>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default RDApproversModal;
