import React from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import { RDAvaliacao, RDRecomendacao } from '../../../../../../common/arrays/RDAtendimento';

import {
  FilterContainerForm,
  SubTitleGroup,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';


const RDSectionAvaliacaoOperacao = ({ handleChange, handleBlur, values, setFieldValue }) => {
  const fields = (values) => {
    let listField = [];

    listField = [
      { type: 'drop', name: 'recomendacao_operacao', label: 'Recomendado?', xs: 4, options: RDRecomendacao, value: values.recomendacao_operacao },
      { type: 'area', name: 'justificativa_operacao', label: 'Justificativa', xs: 8, value: values.justificativa_operacao },
    ];

    return listField;
  };

  return (

    <FilterContainerForm>
      <TitleGroup>
        {'AVALIAÇÃO DA ADMINISTRAÇÃO DA OPERAÇÃO / RELAÇÕES HUMANAS'.toUpperCase()}
      </TitleGroup>

      <Row>


        {fields(values).map((field) => (

          <Col xs={field?.xs}>

            {field.type === 'drop' && (
              <DropdownInput
                name={field.name}
                label={field.label}
                placeholder={'Selecione ...'}
                dropArray={field.options}
                value={field.value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}


            {field.type === 'area' && (
              <TextInput
                textHeight={150}
                min={150}
                max={150}
                type={'text'}
                label={field.label}
                placeholder={field.placeholder}
                value={field.values}
              />
            )}

          </Col>
        ))}

      </Row>

    </FilterContainerForm>

  );
};



export default RDSectionAvaliacaoOperacao;
