export function convertDateTodayToExtense(parseDate: Date) {
  const data = new Date(parseDate);

  const day = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ][data.getDay()];

  const date = data.getDate();

  const month = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ][data.getMonth()];

  const year = data.getFullYear();

  return `${day}, ${date} de ${month} de ${year}`;
}



export const formataData = (date: string) => {
  let dtFormatada = '';

  try {
    const dateParse = date.trim().replaceAll(' ', '');

    if (dateParse !== null && dateParse !== undefined && dateParse !== '') {
      dtFormatada = date.substring(6, 8) + '/';
      dtFormatada += date.substring(4, 6) + '/';
      dtFormatada += date.substring(0, 4);
    }
  }
  catch (error) {
    console.error('ERRO AO FORMATAR DATA:::', error);
  }

  return dtFormatada;
};



export const formataDataReverse = (date) => {
  let dtFormatada = '';
  try {
    if (date !== null && date !== undefined && date !== '') {
      dtFormatada = date.substring(6, 10);
      dtFormatada += date.substring(3, 5);
      dtFormatada += date.substring(0, 2);
    }
  }
  catch (error) {
    console.error('ERRO AO FORMATAR DATA REVERSE:::', error);
  }
  return dtFormatada;
};

export const currentDateFormated = () => {
  return formataData(
    new Date(Date.now())
      .toISOString()
      .replaceAll('-', ''),
  );
};

export const formataParaInputDate = (date) => {
  let dtFormatada = '';

  if (date !== null && date !== undefined && date !== '') {
    dtFormatada = date.substring(0, 4);
    dtFormatada += '-' + date.substring(4, 6);
    dtFormatada += '-' + date.substring(6, 8);
  }
  else {
    console.log('Erro ao tratar DATA RVERSE');
  }
  return dtFormatada;
};


// IN: 2023-01-01 | OUT: 01/01/2023
export function DateTimezoneSpliceFormat(date: string) {
  const dateTimezone = new Date(date).toISOString().split('T')[0];

  const datePart = dateTimezone.match(/\d+/g);
  const year = datePart[0]; // .substring(2); // get only two digits
  const month = datePart[1]; const day = datePart[2];

  return day + '/' + month + '/' + year;
};

export function FormataMesAno(value: any) {
  return (value !== null || value !== undefined)
    ? value.replace(/^(\d{4})(\d{2})/, '$2/$1')
    : value;
};

export function currentDateToInput(somaDias: any = 0) {
  const addZero = (num: any) => {
    return num <= 9 ? `0${num}` : num;
  };


  const dt = new Date();
  dt.setDate(dt.getDate() + somaDias);


  return `${dt.getFullYear()}` +
    `-${addZero(dt.getMonth() + 1)}` +
    `-${addZero(dt.getDate())}`;
};

export function currentCompleteYear() {
  const year = new Date(Date.now());

  return year.getFullYear();
};
