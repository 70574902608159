import * as Yup from 'yup';


const PEXImportacaoValidationSchema = Yup.object().shape({

  CO: Yup.string(),

  CC: Yup.string(),

});



export default PEXImportacaoValidationSchema;
