import React,
{
  useState,
  useEffect,
} from 'react';

import {
  toast,
} from 'react-toastify';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  ContentStyled,
  Content,
  Screen,
  TableDefaultStyled,
  TitleGroup,
  ContainerView,
} from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconInfoSquare,
  IconPrinter,
  IconTrash,
  IconUserCheck,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';
import NameRoutes from '../../../../../navigation/name';

import {
  RD_NavRoutes,
} from '../../../../../navigation/navs/HumanResources';

import ApiWS from '../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';
import { ordenaListaRecentePrimeiro } from '../../../../../utils/ListUtils';

import RDApproversModal from '../Modals/ApproversModal';



const RD_HomeScreen: React.FC = () => {
  const [carregando, setCarregando] = useState(true);
  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem RD\'s aqui!',
    description: 'Não há nenhuma RD para você',
  });

  const [solicitacoes, setSolicitacoes] = useState([]);
  const [removeRD, setRemoveRD] = useState(null);
  const [telaRemoveConfirmacao, setTelaRemoveConfirmacao] = useState(false);
  const [telaAprovadores, setTelaAprovadores] = useState(false);
  const [valueAprovadores, setValueAprovadores] = useState(null);



  const findPendentes = async () => {
    try {
      setCarregando(true);

      const url = '/rd/consulta/pendentes/solicitante/';
      const response = await ApiWS().get(url);

      setSolicitacoes(ordenaListaRecentePrimeiro(response.data));
    }
    catch (error) {
      console.error('findPendentes', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };

  const formataData = (date: String) => {
    let newDate = '';
    try {
      newDate = date.slice(8, 10) + '/';
      newDate += date.slice(5, 7) + '/';
      newDate += date.slice(0, 4);
    }
    catch (error) {
      console.error('Erro ao formatar Data: ', error);
    }
    return newDate;
  };

  const removeItem = async (numRD: any) => {
    try {
      const url = '/rd/consulta/pendentes/remover';
      const response = await ApiWS().post(url, { numRD: numRD });

      if (response.status === 200) {
        toast.success('RD Removida com sucesso', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        findPendentes();
      }
      else {
        toast.error('Erro ao tentar deletar RD', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }

      setTelaRemoveConfirmacao(false);
      setTimeout(() => {
        setRemoveRD(null);
      }, 300);
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }
  };

  async function findAprovadores(os: any) {
    let listaAprovadores = [];

    try {
      const url = '/rd/consulta/pendentes/aprovadores';
      const response = await ApiWS().post(url, os);

      listaAprovadores = response.data;
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }

    return listaAprovadores;
  };

  useEffect(() => {
    findPendentes();
  }, []);

  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={RD_NavRoutes}
          title={'RD'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}

          {!carregando && solicitacoes.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}

          {solicitacoes.length > 0 && (
            <ContainerView>

              <TitleGroup>
                {'RD\'s Pendentes'.toUpperCase()}
              </TitleGroup>

              <TableDefaultStyled>
                <thead>
                  <tr>
                    <th scope="col">OS</th> {/* 1 */}
                    <th scope="col">Nro. RD</th> {/* 2 */}
                    <th scope="col">Solicitante</th> {/* 3 */}
                    <th scope="col">Função</th> {/* 4 */}
                    <th scope="col">Dt. de Emissão</th> {/* 5 */}
                    <th scope="col">Dt. Necessidade</th> {/* 6 */}
                    <th scope="col">Status</th> {/* 7 */}
                    <th scope="col" colSpan={4}>Ações</th> {/* 8 - 11 */}
                  </tr>-
                </thead>


                <tbody>
                  {solicitacoes.map((rd) => (
                    <tr>
                      <td>{rd.OS.replaceAll(' ', '')}</td>{/* 1 */}
                      <td>{rd.RD}</td>{/* 2 */}
                      <td>{rd.Solicitante}</td>{/* 3 */}
                      <td>{rd.FUNCAO}</td>{/* 4 */}
                      <td>{formataData(rd.DataEmissao)}</td>{/* 5 */}
                      <td>{formataData(rd.DataNecess)}</td>{/* 6 */}
                      <td>{rd.Status}</td>{/* 7 */}

                      {/* Observações 8 */}
                      <td>
                        <ButtonTableAction
                          tooltip={
                            rd.QS_OBSERVA
                              && rd.QS_OBSERVA !== null
                              && rd.QS_OBSERVA !== ''
                              && rd.QS_OBSERVA !== undefined
                              ? rd.QS_OBSERVA
                              : TextTooltip.NO_OBSERVATION
                          }
                          icon={
                            <IconInfoSquare
                              color={
                                rd.QS_OBSERVA
                                  && rd.QS_OBSERVA !== null
                                  && rd.QS_OBSERVA !== ''
                                  && rd.QS_OBSERVA !== undefined
                                  ? Colors?.black
                                  : Colors?.gray
                              }
                              size={16}
                            />
                          }
                        />
                      </td>

                      {/* Impressão  9 */}
                      <td>
                        {
                          <ButtonTableAction
                            tooltip={TextTooltip.PRINTER}
                            onClick={() => {
                              window.open(`/${NameRoutes.RD_Impressao}/${rd.RD}`, '_blank').focus();
                            }}
                            icon={
                              <IconPrinter
                                color={Colors?.black}
                                size={16}
                              />
                            }
                          />
                        }
                      </td>

                      {/* Remover  10 */}
                      <td>
                        {
                          <ButtonTableAction
                            tooltip={
                              rd.Status === 'Aprovacao RH'
                                ? TextTooltip.REMOVE
                                : TextTooltip.ACESSLESS
                            }
                            onClick={() => {
                              if (rd.Status === 'Aprovacao RH') {
                                setRemoveRD({ RD: rd.RD });
                                setTelaRemoveConfirmacao(true);
                              }
                            }}
                            icon={
                              <IconTrash
                                color={
                                  rd.Status === 'Aprovacao RH'
                                    ? Colors?.black
                                    : Colors?.gray
                                }
                                size={16}
                              />
                            }
                          />
                        }
                      </td>

                      {/* Aprovadores  11 */}
                      <td>
                        {
                          <ButtonTableAction
                            tooltip={TextTooltip.APPROVALS}
                            onClick={async () => {
                              const resultAproveds = await findAprovadores({ cc: rd.OS });
                              setValueAprovadores({
                                CC: rd.OS,
                                RD: rd.RD,
                                APROVADORES: resultAproveds,
                              });
                              setTelaAprovadores(true);
                            }}
                            icon={
                              <IconUserCheck
                                color={Colors?.black}
                                size={16}
                              />
                            }
                          />
                        }
                      </td>


                    </tr>
                  ))}
                </tbody>
              </TableDefaultStyled>

            </ContainerView>
          )}

          {/* {solicitacoes.length === 0 && (
            <EmptyContent
              title={'Sem RD\'s aqui!'}
            />
          )} */}


        </ContentStyled>
      </Content>

      {removeRD && (
        <ConfirmationModal
          show={telaRemoveConfirmacao}
          head={`Deletar RD: ${removeRD.RD}`}
          orient={'Tem certeza que deseja deletar a RD ' + removeRD.RD + '?'}
          positive={() => {
            removeItem(removeRD.RD);
          }}
          negative={() => {
            setTelaRemoveConfirmacao(false);
            setTimeout(() => {
              setRemoveRD(null);
            }, 300);
          }}
        />
      )}

      {valueAprovadores && (
        <RDApproversModal
          show={telaAprovadores}
          numRD={valueAprovadores.RD}
          aprovadores={valueAprovadores.APROVADORES}
          onHide={() => {
            setTelaAprovadores(false);
            setTimeout(() => {
              setValueAprovadores(null);
            }, 300);
          }}
        />
      )}

    </Screen>

  );
};



export default RD_HomeScreen;
