import NameRoutes from '../name';



export const PFF_NavRoutes = [
  {
    key: 1,
    label: 'Lançamento',
    path: `/${NameRoutes.PFF_Lancamento}`,
  },
  {
    key: 2,
    label: 'Consulta',
    path: `/${NameRoutes.PFF_Consulta}`,
  },
  {
    key: 3,
    label: 'Aprovação',
    path: `/${NameRoutes.PFF_Aprovacao}`,
  },
];


export const PEX_NavRoutes = [
  {
    key: 1,
    label: 'Contas Operacionais',
    path: `/${NameRoutes.PEX_ContasOperacionais}`,
  },
  {
    key: 2,
    label: 'Importação',
    path: `/${NameRoutes.PEX_Importacao}`,
  },
  {
    key: 3,
    label: 'Aprovação',
    path: `/${NameRoutes.PEX_Aprovacao}`,
  },
  {
    key: 4,
    label: 'Consulta',
    path: `/${NameRoutes.PEX_Consulta}`,
  },
  {
    key: 5,
    label: 'Atualizar Mês de Ref.',
    path: `/${NameRoutes.PEX_AtualizarMesRef}`,
  },
];
