import * as Yup from 'yup';


const NewCOValidationSchema = Yup.object().shape({

  CONTA_OPE1: Yup.string(),

  CONTA_OPE2: Yup.string(),

  DESCR: Yup.string(),

  CONTA_CON: Yup.string(),

  ORDEM: Yup.string(),

});



export default NewCOValidationSchema;
