import React,
{
  useState,
  useEffect,
} from 'react';

import {
  toast,
} from 'react-toastify';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  ContainerView,
  Content,
  ContentStyled,
  Screen,
  SectionMargin,
  TableAccordionStyled,
  TitleGroup,
  TableDefaultStyled,
  TableViewButton,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconApproval,
  IconAttachment,
  IconColapseExpand,
  IconColapseRetract,
  IconHistory,
  IconInfoSquare,
  IconPrinter,
  IconUserCheck,
} from '../../../../../components/Icons';

import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';

import NameRoutes from '../../../../../navigation/name';

import {
  SME_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';

import {
  formataData,
} from '../../../../../utils/DateUtils';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';

import SmeAnexosModal from '../Modals/AnexoSmeModal';
import SMApproversModal from '../Modals/ApproversModal';
import HistoricoAprovacaoSMEModal from '../Modals/HistoricoAprovacaoSMEModal';
import SmeHistoricoModal from '../Modals/HistoricoSmeModal';

import {
  FilterSMEPendentContainer,
  FilterView,
} from './SME-aprovacao.styled';
import './styled.css';



const SME_Aprovacao: React.FC = () => {
  const pasta = localStorage.getItem('@intranet/pasta');
  // const user = useSelector((state: RootState) => state.user.data);


  const [carregando, setCarregando] = useState(false);

  const [os_state, setOs_state] = useState('00');

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem SME\'s pendentes!',
    description: 'Não há nenhuma SME pendente',
  });

  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
  });

  const [solicitacoes, setSolicitacoes] = useState([]);
  const [solicitacoesAgrupadas, setSolicitacoesAgrupadas] = useState([]);
  const [listaNomeOS, setListaNomeOS] = useState([]);
  const [itens, setItens] = useState([]);

  const [listDoAprovador, setListDoAprovador] = useState([]);

  const [telaAnexo, setTelaAnexo] = useState(false);
  const [valueAnexo, setValueAnexo] = useState(null);

  const [showHistoricoAprovacaoSME, setShowHistoricoAprovacaoSME] = useState(false);
  const [valuesHistoricoAprovacaoSME, setValuesHistoricoAprovacaoSME] = useState(null);
  const [showHistoricoSME, setShowHistoricoSME] = useState(false);
  const [valuesHistoricoSME, setValuesHistoricoSME] = useState(null);



  const agrupaItensEmSM = (osParaFiltro) => {
    const newArr = [];
    let currentItem = { CP_NUM: '', CP_FILIAL: '' };

    solicitacoes.forEach((item) => {
      if ((item.CP_NUM !== currentItem.CP_NUM)
        || (item.CP_NUM === currentItem.CP_NUM && item.CP_FILIAL !== currentItem.CP_FILIAL)) {
        if (osParaFiltro === '00') {
          newArr.push({ ...item });
          currentItem = item;
        }
        else if (item.CP_CCSOL.replaceAll(' ', '') === osParaFiltro) {
          newArr.push({ ...item });
          currentItem = item;
        }
      }
    });
    return newArr.sort();
  };



  const listaItensDaSM = (numSM: any, filial: number) => {
    const newArr = [];

    itens.forEach((item) => {
      if (item.CP_NUM === numSM && item.CP_FILIAL === filial) {
        newArr.push(item);
      }
    });
    return newArr;
  };



  const listOS = (lista) => {
    const arr = [];
    lista.map((os: any) => arr.push(os.CP_CCSOL.replaceAll(' ', '')));
    const uniqArr = [...new Set(arr)];
    setListaNomeOS(uniqArr);
  };



  const findPendentes = async (os = '00') => {
    setCarregando(true);
    setListDoAprovador([]);
    setSolicitacoes([]);
    setItens([]);

    const url = '/sm/aprovacao/consulta/';
    const payload = { OS: os };

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        let listItens = [];
        const message = resp.data;
        // PEGA A DATA ATUAL E SOMA MAIS 15 DIAS
        // O PRAZO CONTA A PARTIR DA APROVAÇÃO
        if (message.status !== 500) {
          message.forEach((item) => {
            const dt = new Date(Date.now());
            if (item.CP_ORIGPC === '07') {
              dt.setDate(dt.getDate() + 9);
            }
            else {
              dt.setDate(dt.getDate() + 17);
            }

            item.CP_DATPRF =
              dt.getFullYear()
              + '' + ((dt.getMonth() + 1) < 10 ? '0' + (dt.getMonth() + 1) : (dt.getMonth() + 1))
              + '' + (dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate());

            item = { ...item, 'DATPRFOLD': item.CP_DATPRF };
            item = { ...item, 'DESCOMPOLD': item.CP_DESCOMP };
            item = { ...item, 'QUANTOLD': item.CP_QUANT };
            listItens = [...listItens, item];
          });

          listItens.forEach((item) => {
            setSolicitacoes((solicitacoes) => [...solicitacoes, item]);
            setItens((itens) => [...itens, item]);
            if (os === '00') {
              listOS(listItens);
            }
          });
          if (os === '00') {
            listOS(message);
          }
        }
      })
      .catch((error) => {
        console.error('findPendentes', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          setEmptyObject({
            image: Images.other500,
            title: 'Erro!',
            description: 'Sem conexão com o servidor',
          });
        }
        else {
          setEmptyObject(returnErrorObject(error.response.status));
        }
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  function retornaLimiteComCalculo(orientacao) {
    const dt = new Date(Date.now());
    let dtFormatada = '';

    if (orientacao === '07') {
      dt.setDate(dt.getDate() + 9);
    }
    else {
      dt.setDate(dt.getDate() + 17);
    }

    dtFormatada = dt.getFullYear()
      + '-' + ((dt.getMonth() + 1) < 10 ? '0' + (dt.getMonth() + 1) : (dt.getMonth() + 1))
      + '-' + ((dt.getDate() + 1) < 10 ? '0' + dt.getDate() : dt.getDate());

    return dtFormatada;
  };



  const handleMarcaTodosItensSM = (sm, isChecked) => {
    const chk = document.getElementsByName(`chkItem${sm.CP_NUM}`);
    const inputsParaDesabilitar = document.getElementsByClassName(`input-sm-${sm.CP_NUM}`);

    if (isChecked) {
      for (let i = 0; i < inputsParaDesabilitar.length; i++) {
        inputsParaDesabilitar[i].setAttribute('disabled', 'disabled');
      }

      chk.forEach((c: HTMLInputElement) => {
        c.checked = true;
      });
      listaItensDaSM(sm.CP_NUM, sm.CP_FILIAL).forEach((item) => {
        if (item.QUANTOLD === undefined) {
          item.QUANTOLD = item.CP_QUANT;
        }
        if (item.DESCOMPOLD === undefined) {
          item.DESCOMPOLD = item.CP_DESCOMP;
        }
        if (item.DATPRFOLD === undefined) {
          item.DATPRFOLD = item.CP_DATPRF;
        }
        const itemParaAprov = {
          'CLPFF': item.CP_CLPFF,
          'DATPRF': item.CP_DATPRF.replaceAll('-', ''),
          'DATPRFOLD': item.DATPRFOLD,
          'DESCOMP': item.CP_DESCOMP,
          'DESCOMPOLD': item.DESCOMPOLD,
          'DTENTR': item.CP_DATPRF.replaceAll('-', ''),
          'FILIAL': item.CP_FILIAL,
          'ITEM': item.CP_ITEM,
          'GRUPOAT': item.CP_RGRUPAT,
          'NUM': item.CP_NUM,
          'QUANT': item.CP_QUANT,
          'QUANTOLD': item.QUANTOLD,
          'VLPFF': item.CP_VLPFF,
          'statusAprovador': {
            'RRO': 'APROVADOROS',
            'FINAL': 'APROVADORFINAL',
            'CA': 'APROVADORCA',
          }[item.CP_TIPO],
          'CP_NIVEL': item.CP_NIVEL,
          'CP_ITEM': item.CP_ITEM,
        };
        setListDoAprovador((listDoAprovador) => [...listDoAprovador, itemParaAprov]);
      });
    }
    else {
      for (let i = 0; i < inputsParaDesabilitar.length; i++) {
        inputsParaDesabilitar[i].removeAttribute('disabled');
      }
      chk.forEach((c: HTMLInputElement) => {
        c.checked = false;
      });

      const arrTemp = [];
      for (let i = 0; i < listDoAprovador.length; i++) {
        if (sm.CP_NUM === listDoAprovador[i].NUM) {
          arrTemp.push(listDoAprovador[i]);
          setListDoAprovador(listDoAprovador.filter((i) =>
            !~arrTemp.indexOf(i),
          ));
        }
      }
    }
  };



  const handleMarcaUnicoItem = (item, isChecked) => {
    const chk = document.getElementsByName(`chkSm${item.CP_NUM}`);

    if (item.QUANTOLD === undefined) {
      item.QUANTOLD = item.CP_QUANT;
    }
    if (item.DESCOMPOLD === undefined) {
      item.DESCOMPOLD = item.CP_DESCOMP;
    }
    if (item.DATPRFOLD === undefined) {
      item.DATPRFOLD = item.CP_DATPRF;
    }

    const itemParaAprov = {
      'CLPFF': item.CP_CLPFF,
      'DATPRF': item.CP_DATPRF.replaceAll('-', ''),
      'DATPRFOLD': item.DATPRFOLD,
      'DESCOMP': item.CP_DESCOMP,
      'DESCOMPOLD': item.DESCOMPOLD,
      'DTENTR': item.CP_DATPRF.replaceAll('-', ''),
      'FILIAL': item.CP_FILIAL,
      'GRUPOAT': item.CP_RGRUPAT,
      'ITEM': item.CP_ITEM,
      'NUM': item.CP_NUM,
      'QUANT': item.CP_QUANT,
      'QUANTOLD': item.QUANTOLD,
      'VLPFF': item.CP_VLPFF,
      'statusAprovador': {
        'RRO': 'APROVADOROS',
        'FINAL': 'APROVADORFINAL',
        'CA': 'APROVADORCA',
      }[item.CP_TIPO],
      'CP_NIVEL': item.CP_NIVEL,
      'CP_ITEM': item.CP_ITEM,
    };
    if (isChecked) { // Se ativar o checkbox do item
      // Adiciona a lista para aprovacao ou reprovacao
      setListDoAprovador((listDoAprovador) => [...listDoAprovador, itemParaAprov]);
      // Ativa o checkbox geral da SM
      chk.forEach((c: HTMLInputElement) => {
        c.checked = true;
      });
    }
    else { // Se desativar o checkbox do item
      const listTemp = listDoAprovador;
      // percorre a lista para aprovacao
      for (let i = 0; i < listDoAprovador.length; i++) {
        // se for o num da sm e o item
        if (item.CP_NUM === listDoAprovador[i].NUM
          && item.CP_ITEM === listDoAprovador[i].ITEM) {
          // entao remove da lista de aprovacao
          listTemp.splice(i, 1);
          setListDoAprovador(listTemp);
        }
      }
      // como esta desativando o checkbox do item
      // verifica para saber se ainda existe item da sm
      // caso nao, desativa o chkbx geral da sm
      let count = 0;
      listDoAprovador.forEach((i) => {
        if (i.NUM === item.CP_NUM) {
          count++;
        }
      });
      if (count === 0) {
        chk.forEach((c: HTMLInputElement) => {
          c.checked = false;
        });
      }
    }
  };



  async function aprovar() {
    setCarregando(true);
    ApiWS()
      .post('/sm/aprovacao/aprovar', listDoAprovador)
      .then((resp) => {
        setSolicitacoes([]);
        setItens([]);
        listOS([]);

        if (resp.status === 200) {
          toast.success('SME aprovada com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          findPendentes();
        }
      })
      .catch((err) => {
        toast.error('Ocorreu um erro na aprovação', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      })
      .finally(() => {
        setCarregando(false);
      });
  };



  async function reprovar() {
    setCarregando(true);
    const listParaReprovar = [];
    listDoAprovador.forEach((item) => {
      listParaReprovar.push(
        {
          'FILIAL': item.FILIAL,
          'NUM': item.NUM,
          'ITEM': item.ITEM,
          'statusAprovador': item.statusAprovador,
          'CP_NIVEL': item.CP_NIVEL,
          'CP_QUANT': item.QUANT,
        },
      );
    });

    ApiWS()
      .post('/sm/aprovacao/reprovar', listParaReprovar)
      .then((resp) => {
        setSolicitacoes([]);
        setItens([]);
        listOS([]);

        if (resp.status === 200) {
          toast.success('SME reprovada com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          findPendentes();
        }
      })
      .catch((err) => {
        toast.error('Erro ao tentar reprovar SME', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      })
      .finally(() => {
        setCarregando(false);
      });
  };



  async function handleModalAprovadores(values: any) {
    let listaAprovadores = [];

    try {
      const url = '/sm/consulta/pendentes/aprovadores';
      const response = await ApiWS().post(url, values);

      listaAprovadores = response.data;
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }
    return listaAprovadores;
  };


  const handleMostarItens = (id: any) => {
    const itens = document.getElementById(`table${id}`);

    if (itens.classList.value === 'oculto') {
      itens.classList.add('visivel');
      itens.classList.remove('oculto');
    }
    else {
      itens.classList.add('oculto');
      itens.classList.remove('visivel');
    };
  };


  function handleVisaoItens(numSM: number, filial: number) {
    const newArray = solicitacoesAgrupadas.map((sm) => {
      return (
        (sm.CP_NUM === numSM && sm.CP_FILIAL === filial)
          ? { ...sm, mostraItens: !sm.mostraItens }
          : sm
      );
    });

    setSolicitacoesAgrupadas(newArray);
  };


  function postPrinter(values: any) {
    setCarregando(true);

    const url = `/sme/atendimento/impressao/${values.CP_FILIAL}/${values.CP_NUM}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  useEffect(() => {
    findPendentes();
  }, []);


  useEffect(() => {
    setSolicitacoesAgrupadas(agrupaItensEmSM('00'));
  }, [solicitacoes]);


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={SME_NavRoutes}
          title={'SME'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!carregando && solicitacoes.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}



          {!carregando && solicitacoes.length > 0 && (
            <ContainerView>

              <SectionMargin>
                <TitleGroup>
                  {'SME\'s Pendentes de aprovação'.toUpperCase()}
                </TitleGroup>


                <FilterSMEPendentContainer>
                  <FilterView>
                    <label>{'Filtro por CC:'}&nbsp;</label>
                    <select
                      value={os_state}
                      onChange={(e) => {
                        setOs_state(e.target.value);
                        findPendentes(e.target.value);
                      }}>
                      <option value="00">Todos</option>
                      {
                        listaNomeOS.map((os) => (
                          <option value={os}>{os}</option>
                        ))
                      }
                    </select>
                  </FilterView>


                  <div>
                    <IButton
                      disabled={carregando}
                      backgroundColor={Colors?.buttonOk}
                      text={'Aprovar'}
                      onClick={() => {
                        if (listDoAprovador.length > 0) {
                          aprovar();
                        }
                        else {
                          IToast({
                            type: 'warning',
                            message: 'É preciso selecionar pelo menos um item para aprovar.',
                          });
                        }
                      }}
                    />

                    <IButton
                      disabled={carregando}
                      backgroundColor={Colors?.buttonCancel}
                      text={'Reprovar'}
                      onClick={() => {
                        if (listDoAprovador.length > 0) {
                          reprovar();
                        }
                        else {
                          IToast({
                            type: 'warning',
                            message: 'É preciso selecionar pelo menos um item para reprovar.',
                          });
                        }
                      }}
                    />
                  </div>
                </FilterSMEPendentContainer>



                <TableAccordionStyled>
                  <thead>
                    <tr>
                      <th colSpan={2}>Itens</th>
                      <th>Filial</th>
                      <th>CC</th>
                      <th>SME</th>
                      <th>Data</th>
                      <th>Solicitante</th>
                      <th>Utilização</th>
                      <th>Orientação</th>
                      <th colSpan={5}>Ações</th>
                    </tr>
                  </thead>


                  <tbody>
                    {solicitacoesAgrupadas.map((sm, index) => (
                      <>
                        <tr key={index}>
                          <td className="actions">
                            <TableViewButton>
                              <input
                                type='checkbox'
                                name={`chkSm${sm.CP_NUM}`}
                                onChange={(e) => {
                                  handleMarcaTodosItensSM(sm, e.target.checked);
                                }}
                              />
                            </TableViewButton>
                          </td>

                          <td className="actions">
                            <ButtonTableAction
                              key={'right'}
                              placement={'right'}
                              tooltip={sm.mostraItens ? TextTooltip.ITEMS_RETRACT : TextTooltip.ITEMS_SHOW}
                              onClick={() => {
                                handleMostarItens(sm.CP_NUM);
                                handleVisaoItens(sm.CP_NUM, sm.CP_FILIAL);
                              }}
                              icon={
                                sm.mostraItens
                                  ? <IconColapseExpand color={Colors?.black} size={16} />
                                  : <IconColapseRetract color={Colors?.black} size={16} />
                              }
                            />
                          </td>

                          <td>{sm.CP_FILIAL.replaceAll(' ', '')}</td>
                          <td>{sm.CP_CCSOL.replaceAll(' ', '')}</td>
                          <td>{sm.CP_NUM.replaceAll(' ', '')}</td>
                          <td>{formataData(sm.CP_EMISSAO.replaceAll(' ', ''))}</td>
                          <td>{sm.CP_SOLICIT}</td>

                          <td>
                            {{
                              '18': 'ADMINISTRATIVO',
                              '14': 'FABRICAÇÃO',
                              '17': 'OPERACIONAL',
                              '16': 'REVENDA',
                              '15': 'SERVIÇO',
                            }[sm.CP_OBS.replaceAll(' ', '')]}
                          </td>

                          <td> {sm.CP_ORIGPC} </td>


                          {/* Botão Observacao */}
                          <td className={'actions'}>
                            <ButtonTableAction
                              tooltip={
                                sm.CP_OBSERVA && sm.CP_OBSERVA.trim() !== '' && sm.CP_OBSERVA !== null
                                  ? sm.CP_OBSERVA
                                  : TextTooltip.NO_OBSERVATION
                              }
                              icon={
                                sm.CP_OBSERVA && sm.CP_OBSERVA !== '' && sm.CP_OBSERVA !== null
                                  ? <IconInfoSquare
                                    color={Colors?.black}
                                    size={16}
                                  />
                                  : <IconInfoSquare
                                    color={Colors?.gray}
                                    size={16}
                                  />
                              }
                              backgroundColor={
                                !sm.CP_OBSERVA &&
                                Colors?.gray
                              }
                            />
                          </td>


                          {/* Botão Impressao */}
                          <td className="actions">
                            <ButtonTableAction
                              tooltip={TextTooltip.PRINTER}
                              onClick={() => {
                                postPrinter(sm);
                              }}
                              icon={
                                <IconPrinter
                                  color={Colors?.black}
                                  size={16}
                                />
                              }
                            />
                          </td>


                          {/* Botão Modal Anexos */}
                          <td className="actions">
                            {sm.ANEXO === 'S' ? (
                              <ButtonTableAction
                                disabled={true}
                                tooltip={TextTooltip.ATTACHMENT}
                                onClick={() => {
                                  setValueAnexo({
                                    FILIAL: sm.CP_FILIAL,
                                    NUM: sm.CP_NUM,
                                  });
                                  setTelaAnexo(true);
                                }}
                                icon={
                                  <IconAttachment
                                    color={Colors?.black}
                                    size={16}
                                  />
                                }
                              />
                            ) : (
                              <ButtonTableAction
                                tooltip={TextTooltip.LESS_ATTACHMENT}
                                icon={
                                  <IconAttachment
                                    color={Colors?.gray}
                                    size={16}
                                  />
                                }
                              />
                            )}
                          </td>


                          {/* Botão Modal Historico Aprovadores
                          <td className="actions">
                            <ButtonTableAction
                              tooltip={TextTooltip.APPROVALS}
                              onClick={() => {
                                setShowHistoricoAprovacaoSME(true);
                                setValuesHistoricoAprovacaoSME({
                                  'FILIAL': sm.CP_FILIAL,
                                  'CC': sm.CP_CCSOL,
                                  'NUM': sm.CP_NUM,
                                });
                              }}
                              icon={
                                <IconApproval
                                  color={Colors?.black}
                                  size={16}
                                />
                              }
                            />
                          </td> */}
                        </tr>



                        <tr className="no-style">
                          <td colSpan={14} className="no-style">
                            <tr id={`table${sm.CP_NUM}`} className={'oculto'}>

                              <TableDefaultStyled>
                                <thead className="thead-two">
                                  <tr>
                                    <th></th>
                                    <th>Item</th>
                                    <th>Codigo</th>
                                    <th>Descrição</th>
                                    <th>Desc. Compl.</th>
                                    <th>UN</th>
                                    <th>Preço Unit.</th>
                                    <th>Qtd. Comp.</th>
                                    <th>Qtd. Solic.</th>
                                    <th>Qtd. Aprov.</th>
                                    <th>Dt. Neces.</th>
                                    <th>Vl. Orçado</th>
                                    <th>Cl. Orçam.</th>
                                    <th></th>
                                  </tr>
                                </thead>


                                <tbody className="tbody-two">
                                  {listaItensDaSM(sm.CP_NUM, sm.CP_FILIAL).map((item) => (
                                    <tr className="no-horizontal-border">
                                      <td>
                                        <TableViewButton>
                                          <input
                                            type='checkbox'
                                            name={`chkItem${sm.CP_NUM}`}
                                            onChange={(e) => {
                                              const descomp = document.querySelector(`#input_DESCOMP-${item.CP_NUM}-${item.CP_ITEM}`);
                                              const quant = document.querySelector(`#input_QUANT-${item.CP_NUM}-${item.CP_ITEM}`);
                                              const date = document.querySelector(`#input_DATE-${item.CP_NUM}-${item.CP_ITEM}`);
                                              const clpff = document.querySelector(`#input_CLPFF-${item.CP_NUM}-${item.CP_ITEM}`);
                                              handleMarcaUnicoItem(item, e.target.checked);
                                              if (e.target.checked) {
                                                descomp.setAttribute('disabled', 'disabled');
                                                quant.setAttribute('disabled', 'disabled');
                                                date.setAttribute('disabled', 'disabled');
                                                clpff.setAttribute('disabled', 'disabled');
                                              }
                                              else {
                                                descomp.removeAttribute('disabled');
                                                quant.removeAttribute('disabled');
                                                date.removeAttribute('disabled');
                                                clpff.removeAttribute('disabled');
                                              }
                                            }}
                                          />
                                        </TableViewButton>
                                      </td>

                                      <td>{item.CP_ITEM}</td>
                                      <td>{item.CP_PRODUTO}</td>
                                      <td>{item.CP_DESCRI}</td>

                                      <td>
                                        <input
                                          id={`input_DESCOMP-${item.CP_NUM}-${item.CP_ITEM}`}
                                          className={`input-sm-${item.CP_NUM}`}
                                          type="text"
                                          defaultValue={item.CP_DESCOMP}
                                          onChange={(e) => {
                                            item.CP_DESCOMP = e.target.value;
                                          }}
                                          maxLength={20}
                                        />
                                      </td>

                                      <td>{item.CP_UM}</td>
                                      <td>{item.CP_PRECO}</td>
                                      <td>{item.QTD_COMPRADO}</td>
                                      <td>{item.CP_QTSOLIC}</td>

                                      <td>
                                        <input
                                          id={`input_QUANT-${item.CP_NUM}-${item.CP_ITEM}`}
                                          className={`td-qtd input-sm-${item.CP_NUM}`}
                                          type="number"
                                          min='1'
                                          max={item.CP_QTSOLIC}
                                          defaultValue={item.CP_QUANT}
                                          onChange={(e) => {
                                            item.CP_QUANT = e.target.value;
                                            if ((item.CP_QUANT > item.CP_QTSOLIC || item.CP_QUANT <= 0) && item.CP_QUANT !== '') {
                                              setMessageValue({
                                                head: 'Erro!',
                                                orient: 'A Quantidade para aprovar não pode ser maior que a solicitada ou menor que 1.',
                                              });
                                              setMessageModal(true);
                                              e.target.value = item.CP_QTSOLIC;
                                              item.CP_QUANT = item.CP_QTSOLIC;
                                            }
                                          }}
                                        />
                                      </td>

                                      <td>
                                        <input
                                          id={`input_DATE-${item.CP_NUM}-${item.CP_ITEM}`}
                                          className={`input-sm-${item.CP_NUM}`}
                                          type="date"
                                          defaultValue={retornaLimiteComCalculo(item.CP_ORIGPC)}
                                          onChange={(e) => {
                                            const dt = new Date(Date.now());
                                            if (item.CP_ORIGPC === '07') {
                                              dt.setDate(dt.getDate() + 9);
                                            }
                                            else {
                                              dt.setDate(dt.getDate() + 17);
                                            }

                                            item.CP_DATPRF =
                                              dt.getFullYear()
                                              + '' + ((dt.getMonth() + 1) < 10 ? '0' + (dt.getMonth() + 1) : (dt.getMonth() + 1))
                                              + '' + (dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate());

                                            const dtAtual =
                                              item.CP_DATPRF.substring(0, 4)
                                              + '-' + item.CP_DATPRF.substring(4, 6)
                                              + '-' + item.CP_DATPRF.substring(6, 8);
                                            const dtMin = new Date(dtAtual);

                                            if (e.target.valueAsDate >= dtMin) {
                                              item.CP_DATPRF = e.target.value.replaceAll('-', '');
                                            }
                                            else {
                                              setMessageValue({
                                                head: 'Erro!',
                                                orient: `Data Necessidade não pode ser menor que ${formataData(item.CP_DATPRF)}.`,
                                              });
                                              setMessageModal(true);
                                              const idInput = `#input_DATE-${item.CP_NUM}-${item.CP_ITEM}`;
                                              document.querySelector(idInput).classList.add('invalid');
                                              item.CP_DATPRF = e.target.value.replaceAll('-', '');
                                              e.target.value = dtAtual;
                                              item.CP_DATPRF = dtAtual;
                                            }
                                          }}
                                        />
                                      </td>

                                      <td>R$ {item.CP_VLPFF}</td>

                                      <td>
                                        <select
                                          id={`input_CLPFF-${item.CP_NUM}-${item.CP_ITEM}`}
                                          className={`input-sm-${item.CP_NUM}`}
                                          defaultValue={item.CP_CLPFF} // Caso queira padronizar a opção quando clicar em 1
                                          onChange={(e) => {
                                            item.CP_CLPFF = e.target.value;
                                          }}>
                                          <option value="" >Selecione...</option>
                                          <option value="CMD">CMD-Mod</option>
                                          <option value="CMI">CMI-Moi</option>
                                          <option value="CAP">CAP-Apoio</option>
                                          <option value="CMM">CMM-Movimentação</option>
                                          <option value="CCE">CEE-Canteiro</option>
                                          <option value="CEQ">CEQ-Equipamento</option>
                                          <option value="CMA">CMA-Materiais</option>
                                          <option value="CMC">CMC-Consumiveis</option>
                                          <option value="CMV">CMV-Merc.Vend.</option>
                                          <option value="CSB">CSB-SubContrato</option>
                                          <option value="CDG">CDG-Despesas Gerais</option>
                                          <option value="CRC">CRC-Contigencias</option>
                                        </select>
                                      </td>


                                      {/* Botão Modal Historico */}
                                      <td className="actions">
                                        <ButtonTableAction
                                          tooltip={`${TextTooltip.HISTORIC}: Item ${item.CP_ITEM} | SM ${item.CP_NUM}`}
                                          onClick={() => {
                                            setShowHistoricoSME(true);
                                            setValuesHistoricoSME(sm);
                                          }}
                                          icon={
                                            <IconHistory
                                              color={Colors?.black}
                                              size={16}
                                            />
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </TableDefaultStyled>

                            </tr>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>

                </TableAccordionStyled>

              </SectionMargin>

            </ContainerView>
          )}

        </ContentStyled>
      </Content>



      {valueAnexo && (
        <SmeAnexosModal
          show={telaAnexo}
          anexar={false}
          sm={valueAnexo}
          onHide={() => {
            setTelaAnexo(false);
            setTimeout(() => {
              setValueAnexo(null);
            }, 300);
          }}
        />
      )}


      {showHistoricoAprovacaoSME && valuesHistoricoAprovacaoSME && (
        <HistoricoAprovacaoSMEModal
          show={showHistoricoAprovacaoSME}
          onHide={() => setShowHistoricoAprovacaoSME(false)}
          value={valuesHistoricoAprovacaoSME}
        />
      )}


      {showHistoricoSME && valuesHistoricoSME && (
        <SmeHistoricoModal
          show={showHistoricoSME}
          onHide={() => setShowHistoricoSME(false)}
          todo={valuesHistoricoSME}
        />
      )}


      <ConfirmationModal
        show={messageModal}
        head={messageValue.head}
        orient={messageValue.orient}
        negative={() => {
          setMessageModal(false);
        }}
      />

    </Screen>

  );
};



export default SME_Aprovacao;
