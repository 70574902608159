import React, {
  useEffect,
  useState,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';


import {
  Colors, Metrics,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import LoadingsComponent from '../../../../../components/Loadings';


import {
  ModalBodyFilterStyled, ModalContainerStyled, ModalFooterStyled, ModalTableContainer,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';

import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  values?: any;
  onHide?: any;
  codigoItem?: string;
}



const EstoqueModal: React.FC<IProps> = (props) => {
  const [carregando, setCarregando] = useState(false);

  const [listEstoque, setListEstoque] = useState([]);

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);



  const handleConsultaEstoque = async (page_iteration) => {
    setCarregando(true);

    const values = {
      'B1_COD': props.codigoItem.trim(),
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    };

    ApiWS()
      .post('/sm/nova_sm/consulta/estoque', values)
      .then((resp) => {
        setListEstoque(resp.data[0]);
        setTotalResults(resp.data[1][0].TOTAL);
        setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    handleConsultaEstoque(page_var);
    setPage(page_var);
  };

  useEffect(() => {
    handleConsultaEstoque(1);
  }, []);


  const columns = [
    {
      dataField: 'ARMAZEM',
      text: 'ARMAZEM',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'DESCARMAZEM',
      text: 'Descrição',
      sort: true,
      headerStyle: () => {
        return { width: '35%' };
      },
    },
    {
      dataField: 'QTDDISP',
      text: 'Disponível',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'QTDTOTAL',
      text: 'Total',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'VLULTCOMP',
      text: 'Ult. Compra',
      sort: true,
      headerStyle: () => {
        return { width: '13%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listEstoque.length,
  };



  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {`Estoque do Item: ${props.codigoItem}`.toUpperCase()}
        </TitleGroup>


        <ModalBodyFilterStyled>

          <ModalTableContainer>

            {carregando && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}


            {!carregando && listEstoque.length > 0 && (
              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={totalPage === 1}
                    data={listEstoque}
                    columns={columns}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                    actionPrevious={() => handlePagination(0)}
                    actionNext={() => handlePagination(1)}
                    currentPage={page}
                    totalPage={totalPage}
                    totalResults={totalResults}
                  />
                )}
              </PaginationProvider>
            )}
          </ModalTableContainer>

        </ModalBodyFilterStyled>



        <ModalFooterStyled>

          <IButton
            disabled={carregando}
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />

        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default EstoqueModal;
