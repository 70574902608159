import React from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import { RDAvaliacao } from '../../../../../../common/arrays/RDAtendimento';

import {
  FilterContainerForm,
  SubTitleGroup,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';


const RDSectionAvaliacaoLiderDir = ({ handleChange, handleBlur, values, setFieldValue }) => {
  const fieldsCol1 = (values) => {
    let listField = [];

    listField = [
      { type: 'drop', name: 'assiduidade', label: 'Assiduidade (faltas / atrasos)', xs: 12, options: RDAvaliacao, value: values.assiduidade },
      { type: 'drop', name: 'iniciativa', label: 'Iniciativa', xs: 12, options: RDAvaliacao, value: values.iniciativa },
      { type: 'drop', name: 'comprometimento', label: 'Comprometimento', xs: 12, options: RDAvaliacao, value: values.comprometimento },
      { type: 'drop', name: 'rel_interpessoal', label: 'Relacionamento interpessoal', xs: 12, options: RDAvaliacao, value: values.rel_interpessoal },
      { type: 'drop', name: 'etica', label: 'Honestidade / Ética', xs: 12, options: RDAvaliacao, value: values.etica },
    ];


    return listField;
  };


  const fieldsCol2 = (values) => {
    let listField = [];

    listField = [
      { type: 'drop', name: 'con_tecnico', label: 'Conhecimento Técnico', xs: 12, options: RDAvaliacao, value: values.con_tecnico },
      { type: 'drop', name: 'qual_servicos', label: 'Qualidade dos Serviços', xs: 12, options: RDAvaliacao, value: values.qual_servicos },
      { type: 'drop', name: 'aten_prazo', label: 'Atendimento do Prazo', xs: 12, options: RDAvaliacao, value: values.aten_prazo },
      { type: 'drop', name: 'higiene_area', label: 'Higiene e Limpeza da Área de Trabalho', xs: 12, options: RDAvaliacao, value: values.higiene_area },
      { type: 'drop', name: 'desem_seg', label: 'Desempenho de Segurança', xs: 12, options: RDAvaliacao, value: values.desem_seg },
    ];

    return listField;
  };

  return (

    <FilterContainerForm>
      <TitleGroup>
        {'Avaliação do Líder Direto'.toUpperCase()}
      </TitleGroup>

      <Row>

        <Col xs={6}>
          <SubTitleGroup noMarginTop noMarginBottom>
            {'Comportamental'.toUpperCase()}
          </SubTitleGroup>


          {fieldsCol1(values).map((field) => (

            <Col xs={field?.xs}>

              {field.type === 'drop' && (
                <DropdownInput
                  name={field.name}
                  label={field.label}
                  placeholder={'Selecione ...'}
                  dropArray={field.options}
                  value={field.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}

            </Col>
          ))}
        </Col>


        <Col xs={6}>
          <SubTitleGroup noMarginTop noMarginBottom>
            {'Técnico / Segurança do Trabalho'.toUpperCase()}
          </SubTitleGroup>


          {fieldsCol2(values).map((field) => (

            <Col xs={field?.xs}>

              {field.type === 'drop' && (
                <DropdownInput
                  name={field.name}
                  label={field.label}
                  placeholder={'Selecione ...'}
                  dropArray={field.options}
                  value={field.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}

            </Col>
          ))}

        </Col>

      </Row>

    </FilterContainerForm>

  );
};



export default RDSectionAvaliacaoLiderDir;
