const IRdInclusaoModel = {

  // Requisicao
  matricula: '123456',
  colaborador: 'João Nascimento da Silva',
  cargo: 'Operador de Muck',
  admissao: '2020-05-01',
  data_demissao: '2024-05-19',
  cc: '9990101',
  contrato: 'Normal',
  recomendacao: '',

  // Avaliacao Lider,
  assiduidade: '',
  iniciativa: '',
  comprometimento: '',
  rel_interpessoal: '',
  etica: '',
  con_tecnico: '',
  qual_servicos: '',
  aten_prazo: '',
  higiene_area: '',
  desem_seg: '',

  // Avaliacao Operacao,
  recomendacao_operacao: '',
  justificativa_operacao: '',

};



export default IRdInclusaoModel;
