import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  useHistory,
} from 'react-router';

import {
  Form,
  Formik,
} from 'formik';

import { SmeOrientacaoArray } from '../../../../../../common/arrays/SmeSolicitacao';
import {
  Colors,
} from '../../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../../components/IToast/Toast';
import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../../components/Modals/styled';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';

import NameRoutes from '../../../../../../navigation/name';

import ApiWS from '../../../../../../services/api.service';
import SmeModalCopiaModel from './model';
import SmeCopiaModalValidationSchema from './validation';



interface IProps {
  show: boolean;
  dadosParaSM: any;
  onHide?: any;
}



const SMECopiaModal: React.FC<IProps> = (props) => {
  const history = useHistory();


  const [carregando, setCarregando] = useState(false);
  const [msg, setMsg] = useState('');

  const [selectCodUtilizacao, setSelectCodUtilizacao] = useState('18');
  const [selectCodOrientacao, setSelectCodOrientacao] = useState('00');
  const [statePrazo, setStatePrazo] = useState('0');

  const [dropOrientacao, setDropOrientacao] = useState([]);

  const postDropOrientacao = async () => {
    try {
      const url = '/sm/nova_sm/consulta/orientacao';
      const response = await ApiWS().post(url);

      response.data.forEach((item) => {
        console.log(`${String(item.COD)?.trim()};${String(item.PRAZO)?.trim()}`);
        setDropOrientacao((dropOrientacao) => [...dropOrientacao, { key: item.ID, label: item.DESCR, value: `${String(item.COD)?.trim()};${String(item.PRAZO)?.trim()}` }]);
      });
    }
    catch (error) {
      console.log('postDropOrientacao', JSON.stringify(error.response, null, 2));
    }
  };

  const [dropUtilizacao, setDropUtilizacao] = useState([]);

  const postDropUtilizacao = async () => {
    try {
      const url = '/sm/nova_sm/consulta/utilizacao';
      const response = await ApiWS().post(url);

      response.data.forEach((item) => {
        setDropUtilizacao((dropUtilizacao) => [...dropUtilizacao, { key: item.X5_CHAVE, label: item.X5_DESCRI, value: item.X5_CHAVE }]);
      });
    }
    catch (error) {
      console.log('postDropUtilizacao', JSON.stringify(error.response, null, 2));
    }
  };


  async function copiaSME(values: any) {
    setCarregando(true);
    setMsg('');

    const url = '/sm/nova_sm/consulta/copiaSm';
    const payload = {
      'Num': values.input_num,
      'Filial': values.input_filial,
    };


    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          const copiaSMEObjeto = {
            tipo: 'COPIA',
            solicitante: props.dadosParaSM.usu_nm_usuario,
            os: props.dadosParaSM.cco_vl_centro_custo,
            setor_operacao: props.dadosParaSM.cco_ds_centro_custo,
            filial: values.input_filial,
            funcaoSolicitante: props.dadosParaSM.fun_ds_funcao,
            sme: resp.data,
            utilizacao: selectCodUtilizacao,
            orientacao: selectCodOrientacao,
            prazo: statePrazo,
          };

          if (resp.data.length > 0) {
            history.push(`/${NameRoutes.SME_Nova}`, copiaSMEObjeto);
          }
          else {
            setMsg('SME inxestente, verifique e tente outro número!');
          }
        }

        console.log(resp.data);
        if (resp.status === 203) {
          IToast({
            type: 'warning',
            message: resp.data,
          });
        }
      })
      .catch((error) => {
        console.error('copiaSME', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  useEffect(() => {
    postDropOrientacao();
    postDropUtilizacao();
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Cópia SME'.toUpperCase()}
        </TitleGroup>



        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={SmeCopiaModalValidationSchema}
          initialValues={SmeModalCopiaModel}
          onSubmit={(values) => {
            copiaSME(values);
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>
                <TextInput
                  editable={!carregando}
                  type={'text'}
                  name={'input_filial'}
                  label={'Filial'}
                  placeholder={'Numero Filial'}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('input_filial', e.target.value.replace(/\D/, ''));
                  }}
                  onBlur={handleBlur}
                />


                <TextInput
                  editable={!carregando}
                  type={'text'}
                  name={'input_num'}
                  label={'SME'}
                  placeholder={'Numero SME'}
                  min={0}
                  max={6}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('input_num', e.target.value.replace(/\D/, ''));
                  }}
                  onBlur={handleBlur}
                />


                <DropdownInput
                  name={'input_utilizacao'}
                  label={'Utilização'}
                  placeholder={'Selecione ...'}
                  dropArray={dropUtilizacao}
                  onChange={(e: any) => {
                    handleChange(e);
                    setSelectCodUtilizacao(e.target.value);
                    setFieldValue('input_utilizacao', e.target.value);
                  }}
                  onBlur={handleBlur}
                />


                <DropdownInput
                  name={'input_orientacao'}
                  label={'Orientação'}
                  placeholder={'Selecione ...'}
                  dropArray={dropOrientacao}
                  onChange={(e: any) => {
                    handleChange(e);
                    // setInputLimite(handleOrientacaoPrazo(e.target.value));
                    setSelectCodOrientacao(e.target.value.substring(0, e.target.value.indexOf(';')));
                    setFieldValue('input_orientacao', e.target.value);
                    setStatePrazo(e.target.value.substring(e.target.value.indexOf(';') + 1, e.target.value.length));
                  }}
                  onBlur={handleBlur}
                />



                {msg !== '' && (
                  <p className="text-danger">
                    {msg}
                  </p>
                )}
              </ModalBodyStyled>



              <ModalFooterStyled>
                <IButton
                  backgroundColor={Colors?.buttonOk}
                  text={'Copiar'}
                  type={'submit'}
                />


                <IButton
                  backgroundColor={Colors?.buttonCancel}
                  text={'Fechar'}
                  onClick={props.onHide}
                />
              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default SMECopiaModal;
