import React,
{
  useState,
  useRef,
  useEffect,
} from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../common/constants';

import {
  ObsTitleGroup,
  TableActionButton,
  TableDefaultStyled,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import {
  IconDowload,
  IconTrash,
} from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  anexar?: boolean;
  buttonText?: string;
  onHide?: any;

  sm?: any;
}



const SmeAnexosModal: React.FC<IProps> = (props) => {
  const [filesDoBanco, setFilesDoBanco] = useState([]);
  const filesElement = useRef(null);

  const [loading, setLoading] = useState(false);



  const onclick_downloadAnexo = (nomeArquivo) => {
    const pasta = window.localStorage.getItem('@intranet/pasta');

    window.open(`${defaultBaseURL}/${pasta}/anexos/${nomeArquivo}`);
  };


  const findAnexos = async (sm) => {
    setLoading(true);
    ApiWS()
      .get(`/sm/envio_anexos1/${sm.FILIAL}/${sm.NUM}`)
      .then((resp) => {
        setFilesDoBanco(resp.data);
      })
      .catch((err) => {
        // setErro(err);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function onclick_uploadAnexo(): Promise<void> {
    setLoading(true);
    const dataForm = new FormData();
    for (const file of filesElement.current.files) {
      dataForm.append('file', file);
      dataForm.append('filial', props.sm.FILIAL);
      dataForm.append('numSM', props.sm.NUM);
      console.log(file);
    }
    ApiWS()
      .post('/sm/envio_anexos1/', dataForm)
      .then((_resp) => {
        findAnexos(props.sm);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }


  async function onclick_remove(idArquivo): Promise<void> {
    setLoading(true);
    ApiWS()
      .get('/sm/remove_anexos/' + idArquivo)
      .then((_resp) => {
        findAnexos(props.sm);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }


  useEffect(() => {
    findAnexos(props.sm);
  }, [props.sm]);



  return (

    <Modal
      {...props}
      show={props.show}
      backdrop={'static'}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {`Anexos Filial: ${props.sm.FILIAL} | SME: ${props.sm.NUM}`.toUpperCase()}
        </TitleGroup>

        {
          loading &&
            <LoadingsComponent fontSize={'12px'} size={'0.9rem'}/>
        }


        <ModalBodyStyled>

          {props.anexar && (
            <div>
              <ObsTitleGroup>
                Obs: Somente serão aceitos arquivos em: (PDF, Word, Excel, Power Point e Imagens)
              </ObsTitleGroup>


              <div className="mb-4">
                <input
                  type="file"
                  multiple
                  accept="
                    application/msword,
                    application/vnd.ms-Excel,
                    application/vnd.ms-PowerPoint,
                    text/plain,
                    application/pdf,
                    image/*"
                  ref={filesElement}
                />
              </div>
            </div>
          )}



          <TableDefaultStyled>
            <thead>
              <tr>
                <th>Nome do Arquivo</th>
                <th>Tamanho</th>
                {props.anexar
                  ? (<th colSpan={2}>Ações</th>)
                  : (<th>Ações</th>)
                }
              </tr>
            </thead>


            <tbody>
              {filesDoBanco.map((file, key) => (
                <tr key={key}>
                  <td>{file.originalNome}</td>
                  <td>{(parseFloat(file.tamanho) / 100000).toFixed(2)} MB</td>

                  {/* Botão Baixar */}
                  <td className="actions">
                    <TableActionButton
                      onClick={() => {
                        onclick_downloadAnexo(file.nome);
                      }}>
                      <span>{file.sup_sm_originalNome}</span>

                      <IconDowload
                        color={Colors?.black}
                        size={16}
                      />
                    </TableActionButton>
                  </td>

                  {/* Botão Deletar */}
                  {props.anexar && (
                    <td className="actions">
                      <TableActionButton
                        onClick={() => {
                          onclick_remove(file.ID);
                        }}>
                        <IconTrash
                          color={Colors?.black}
                          size={16}
                        />
                      </TableActionButton>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </TableDefaultStyled>
        </ModalBodyStyled>



        <ModalFooterStyled>
          {props.anexar && (
            <IButton
              backgroundColor={Colors?.buttonOk}
              text={'Enviar'}
              onClick={onclick_uploadAnexo}
            />
          )}


          <IButton
            backgroundColor={Colors?.buttonCancel}
            text={props.buttonText}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



SmeAnexosModal.defaultProps = {
  anexar: true,
  buttonText: 'Fechar',
};



export default SmeAnexosModal;
