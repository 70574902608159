/* eslint-disable import-helpers/order-imports */
import React,
{
  useState,
  useEffect,
} from 'react';

import {
  Col,
  Form,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  useSelector,
} from 'react-redux';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  Screen,
  SectionMargin,
  TableAccordionStyled,
  TableActionButton,
  TableDefaultStyled,
  TableViewButton,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import {
  IconAttachment,
  IconColapseExpand,
  IconHistory,
  IconInfoSquare,
  IconPrinter,
  IconColapseRetract,
  IconEdit,
  IconApproval,
} from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import NameRoutes from '../../../../../navigation/name';
import {
  SME_NavRoutes,
} from '../../../../../navigation/navs/Supplies';
import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';
import {
  RootState,
} from '../../../../../store';
import {
  formataData,
} from '../../../../../utils/DateUtils';
import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';
import SmeAnexosModal from '../Modals/AnexoSmeModal';
import SmeHistoricoModal from '../Modals/HistoricoSmeModal';
import {
  TransferSMEButtonContainer,
  TransferSMEMargin,
  CheckBoxFilterSMEView,
  MoveGroupInputView,
} from './SME-triagem.styled';
import { SmeTriagemTransferirValidationSchema, SmeTriagemFiltrarValidationSchema } from './SME-triagem.validation';


import './styled.css';
// eslint-disable-next-line import-helpers/order-imports
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// eslint-disable-next-line import-helpers/order-imports
import ExcelConfigs from '../../../../../common/excel';
// eslint-disable-next-line import-helpers/order-imports
import { accentRemove } from '../../../../../utils/StringUtils';
// eslint-disable-next-line import-helpers/order-imports
import BootstrapTable from 'react-bootstrap-table-next';

import { SmeTriagemFiltroModel, SmeTriagemTransferirModel } from './SME-triagem.model';
import SMEAlteraOrientacaoModal from '../Modals/AlteraOrientacaoModal';
import { IToast } from '../../../../../components/IToast/Toast';
import HistoricoAprovacaoSMEModal from '../Modals/HistoricoAprovacaoSMEModal';



const SME_TriagemScreen: React.FC = () => {
  const pasta = localStorage.getItem('@intranet/pasta');

  const user = useSelector((state: RootState) => state.user.data);


  const [wait, setWait] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem SME\'s aqui!',
    description: 'Não há nenhuma SME para você, faça uma nova busca',
  });

  const [osType, setOsType] = useState('');

  const [listaCarregando, setListaCarregando] = useState(false);
  const [showListSM, setShowListSM] = useState(false);

  const [valueHistorico, setValueHistorico] = useState(null);
  const [modalHistorico, setModalHistorico] = useState(false);

  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
  });

  const [listGruposOrigem, setListGruposOrigem] = useState([]);
  const [listGruposDestino, setListGruposDestino] = useState([]);
  const [listItens, setListItens] = useState([]);
  const [listItensAgrupadoEmSm, setListItensAgrupadoEmSm] = useState([]);

  const [listDaTransferencia, setListDaTransferencia] = useState([]);
  const [progressModal, setProgressModal] = useState(false);
  const [listProgressTransferValue, setListProgressTransferValue] = useState(null);

  const [valueAnexo, setValueAnexo] = useState(null);
  const [telaAnexo, setTelaAnexo] = useState(false);

  const [valueChangeOrientation, setValueChangeOrientation] = useState(null);
  const [showChangeOrientation, setShowChangeOrientation] = useState(false);

  const [valueSelectGrupoOrigem, setValueSelectGrupoOrigem] = useState(null);
  const [codSelectGrupoOrigem, setCodSelectGrupoOrigem] = useState(null);

  const [valueSelectGrupoDestino, setValueSelectGrupoDestino] = useState(null);
  const [codSelectGrupoDestino, setCodSelectGrupoDestino] = useState(null);

  const [formValues, setFormValues] = useState(null);

  const [listTransfEmpty, setListTransfEmpty] = useState(true);

  const [userChangeOrientation, setUserChangeOrientation] = useState(false);


  const [listGruposProduto, setListGruposProduto] = useState([]);

  const unauthorizedObject = {
    image: Images.error401,
    title: 'Sem autorização de acesso!',
    description: 'Não há nada aqui',
  };

  const [valuesHistoricoAprovacaoSME, setValuesHistoricoAprovacaoSME] = useState(null);
  const [showHistoricoAprovacaoSME, setShowHistoricoAprovacaoSME] = useState(false);


  const agrupaItensEmSM = (listaEmSM) => {
    const newArr = [];

    let currentItem = { CP_NUM: '', CP_FILIAL: '' };
    for (let i = 0; i < listaEmSM.length; i++) {
      if ((listaEmSM[i].CP_NUM !== currentItem.CP_NUM)
        || (listaEmSM[i].CP_NUM === currentItem.CP_NUM && listaEmSM[i].CP_FILIAL !== currentItem.CP_FILIAL)) {
        newArr.push({ ...listaEmSM[i], mostraItens: false });

        currentItem = listaEmSM[i];
      }
    }

    return newArr;
  };


  const listaItensDaSM = (numSM, filial) => {
    const newArr = [];

    listItens.forEach((item) => {
      if (item.CP_NUM === numSM
        && item.CP_FILIAL === filial) {
        newArr.push(item);
      }
    });
    return newArr;
  };


  const onClick_MostarItens = (id) => {
    const itens = document.querySelector(`#table${id}`);
    // const btn = document.querySelector(`#btn${id}`);

    if (itens.classList.value === 'oculto') {
      itens.classList.add('visivel');
      itens.classList.remove('oculto');
    }
    else {
      itens.classList.add('oculto');
      itens.classList.remove('visivel');
    };
  };


  const onSelect_MarcarParaTransferir = (item, isChecked) => {
    const checkBoxElement = document.getElementsByName(`chkSm${item.CP_FILIAL}${item.CP_NUM}`);
    const inputQtdTransf = document.querySelector(`#input_QUANTPEN-${item.CP_FILIAL}-${item.CP_NUM}-${item.CP_ITEM}`);

    const itemParaTransferir = {
      ASME: item.CP_ASME,
      CCSOL: item.CP_CCSOL,
      CLPFF: item.CP_CLPFF,
      CONTA: item.CP_CONTA,
      DATPRF: item.CP_DATPRF,
      DESCRI: item.CP_DESCRI,
      DESCRICOMP: item.CP_DESCOMP,
      FILIAL: item.CP_FILIAL,
      GRUPODESTINO: codSelectGrupoDestino,
      GRUPOORIGEM: item.CP_RGRUPAT,
      ITEM: item.CP_ITEM,
      LOCAL: item.CP_LOCAL,
      LOGIN: user.login,
      NUM: item.CP_NUM,
      OBS: item.CP_OBS,
      OP: item.CP_OP,
      ORIGPC: item.CP_ORIGPC,
      PRODUTO: item.CP_PRODUTO,
      QTDTRIADA: item.QUANTPEN,
      QUANT: item.CP_QUANT,
      QUJE: item.CP_QUJE,
      SOLICITANTE: item.CP_SOLICIT,
      UN: item.CP_UM,
      USER: user.id,
      VLPFF: item.CP_VLPFF,
    };


    if (isChecked) {
      inputQtdTransf.setAttribute('disabled', 'disabled');

      setListDaTransferencia((listDaTransferencia) => [
        ...listDaTransferencia,
        itemParaTransferir,
      ]);

      checkBoxElement.forEach((cbItem: HTMLInputElement) => {
        cbItem.checked = true;
      });
    }
    else {
      inputQtdTransf.removeAttribute('disabled');

      const listTemp = listDaTransferencia;

      // percorre a lista para aprovacao
      for (let i = 0; i < listDaTransferencia.length; i++) {
        if (item.CP_NUM === listDaTransferencia[i].NUM
          && item.CP_ITEM === listDaTransferencia[i].ITEM) {
          listTemp.splice(i, 1);
          setListDaTransferencia(listTemp);
        }
      }

      // como esta desativando o checkbox do item
      // verifica para saber se ainda existe item da sm
      // caso nao, desativa o chkbx geral da sm
      let count = 0;
      listDaTransferencia.forEach((index) => {
        if (index.NUM === item.CP_NUM) {
          count++;
        }
      });

      if (count === 0) {
        checkBoxElement.forEach((cbItem: HTMLInputElement) => {
          cbItem.checked = false;
        });
      }
    }
  };


  const onSelect_MarcaTodosItensSM = (sm, isChecked) => {
    const checkBoxElement = document.getElementsByName(`chkItem${sm.CP_FILIAL}${sm.CP_NUM}`);
    const inputsParaDesabilitar = document.getElementsByClassName(`input-sm-${sm.CP_FILIAL}-${sm.CP_NUM}`);

    if (isChecked) {
      for (let i = 0; i < inputsParaDesabilitar.length; i++) {
        inputsParaDesabilitar[i].setAttribute('disabled', 'disabled');
      }

      // inputsParaDesabilitar.setAttribute('disabled', 'disabled');
      checkBoxElement.forEach((c: HTMLInputElement) => {
        c.checked = true;
      });

      listaItensDaSM(sm.CP_NUM, sm.CP_FILIAL).forEach((item) => {
        const itemParaTransferir = {
          ASME: item.CP_ASME,
          CCSOL: item.CP_CCSOL,
          CLPFF: item.CP_CLPFF,
          CONTA: item.CP_CONTA,
          DATPRF: item.CP_DATPRF,
          DESCRI: item.CP_DESCRI,
          DESCRICOMP: item.CP_DESCOMP,
          FILIAL: item.CP_FILIAL,
          GRUPODESTINO: codSelectGrupoDestino,
          GRUPOORIGEM: item.CP_RGRUPAT,
          ITEM: item.CP_ITEM,
          LOCAL: item.CP_LOCAL,
          LOGIN: user.login,
          NUM: item.CP_NUM,
          OBS: item.CP_OBS,
          OP: item.CP_OP,
          ORIGPC: item.CP_ORIGPC,
          PRODUTO: item.CP_PRODUTO,
          QTDTRIADA: item.QUANTPEN,
          QUANT: item.CP_QUANT,
          QUJE: item.CP_QUJE,
          SOLICITANTE: item.CP_SOLICIT,
          UN: item.CP_UM,
          USER: user.id,
          VLPFF: item.CP_VLPFF,
        };
        setListDaTransferencia((listDaTransferencia) => [...listDaTransferencia, itemParaTransferir]);
      });
    }
    else {
      for (let i = 0; i < inputsParaDesabilitar.length; i++) {
        inputsParaDesabilitar[i].removeAttribute('disabled');
      }
      checkBoxElement.forEach((cbItem: HTMLInputElement) => {
        cbItem.checked = false;
      });

      const arrTemp = [];
      for (let i = 0; i < listDaTransferencia.length; i++) {
        if (sm.CP_NUM === listDaTransferencia[i].NUM) {
          arrTemp.push(listDaTransferencia[i]);
          setListDaTransferencia(listDaTransferencia.filter((i) =>
            !~arrTemp.indexOf(i),
          ));
        }
      }
    }
  };


  async function onClick_Filtrar(values: typeof SmeTriagemFiltroModel) {
    const payload = {
      'CHECK': values.radio_os_type,
      'GRUPOTRIAG': values.select_grupo_origem,
      'OS': values.input_os,
      'DESCRI': values.input_descricao,
      'SOLICITANTE': values.input_solicitante,
      'NUM': values.input_num_sm,
      'CODGRUPO': values.select_grupo_produto,
    };

    try {
      setListaCarregando(true);
      setShowListSM(false);

      const url = '/sm/triagem/consulta/';
      const response = await ApiWS().post(url, payload);
      setListItens(response.data);
      setListItensAgrupadoEmSm(agrupaItensEmSM(response.data));
    }
    catch (error) {
      console.error('filtrarFunction', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListaCarregando(false);
      setShowListSM(true);
    }
  };


  async function onClick_Transferir(values: typeof SmeTriagemTransferirModel, gerarSC = false) {
    setCarregando(true);

    listDaTransferencia.map((item) => (
      item.OBSTRIAGEM = values.OBSTRIAGEM
    ));

    if ((codSelectGrupoOrigem !== null || codSelectGrupoOrigem !== '')
      && (values.select_grupo_destino !== null || values.select_grupo_destino !== '')
      && listDaTransferencia.length > 0) {
      for (let i = 0; i < listDaTransferencia.length; i++) {
        if (gerarSC) {
          listDaTransferencia[i].GRUPODESTINO = listDaTransferencia[i].GRUPOORIGEM;
        }
        else if (!gerarSC) {
          listDaTransferencia[i].GRUPODESTINO = codSelectGrupoDestino;
        }
      }

      try {
        setWait(true);

        const url = '/sm/triagem/tranferencia/';
        const response = await ApiWS().post(url, listDaTransferencia);
        if (response.status === 200) {
          setProgressModal(true);
          setListProgressTransferValue(listDaTransferencia);
        }
      }
      catch (error) {
        console.error('transferirFunction', JSON.stringify(error.response, null, 2));

        toast.error('Ocorreu um erro ao fazer a transferência', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.log(error);
      }
      finally {
        setWait(false);
        setCarregando(false);
        setListDaTransferencia([]);
      }
    }
    else {
      setCarregando(false);
      toast.error('Há um erro. Confira as seleções dos itens ou contate o suporte.', {
        position: 'bottom-center',
        autoClose: Metrics.messageAutoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };


  function renderListGruposOrigem(listGrpResp: any) {
    const listGrpTriagemMap = listGrpResp.map((triagem: any) => {
      const valueTriagem = triagem.ZE_GRTRIAG !== '' || triagem.ZE_GRTRIAG !== null || triagem.ZE_GRTRIAG !== undefined ? triagem.ZE_GRTRIAG : null;

      const groupTriagemMapped = {
        'key': triagem.ZE_GRTRIAG,
        'label': triagem.ZU_DESGRTR,
        'value': valueTriagem,
      };
      return groupTriagemMapped;
    });

    setListGruposOrigem([
      // emptyList,
      ...listGrpTriagemMap,
    ]);
  };


  async function findGruposDoUsuario() {
    try {
      setCarregando(true);

      const url = '/sm/triagem/consulta/grupo/';
      const response = await ApiWS().post(url);
      renderListGruposOrigem(response.data);
    }
    catch (error) {
      console.error('findGruposDoUsuario', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };


  function renderListGrpDestino(listGrpResp: any) {
    const listGrpTriagemMap = listGrpResp.map((triagem) => {
      const valueTriagem = triagem.ZI_GRPDEST !== '' || triagem.ZI_GRPDEST !== null || triagem.ZI_GRPDEST !== undefined ? triagem.ZI_GRPDEST : null;

      const destinoTriagemMapped = {
        'key': triagem.ZI_GRPDEST,
        'label': triagem.ZI_DSCDEST,
        'value': valueTriagem,
      };
      return destinoTriagemMapped;
    });

    setListGruposDestino([
      // emptyList,
      ...listGrpTriagemMap,
    ]);
  };


  async function onChange_findGruposDestino(codGrupoOrigem: any) {
    try {
      setWait(true);

      const url = `/sm/triagem/consulta/grupotrnsf/${codGrupoOrigem}`;
      const response = await ApiWS().get(url);
      renderListGrpDestino(response.data);
    }
    catch (error) {
      console.error('onChange_findGruposDestino', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setWait(false);
    }
  };


  function handleVisaoItens(numSM: number) {
    const newArray = listItensAgrupadoEmSm.map((sm) => {
      return sm.CP_NUM === numSM ? { ...sm, mostraItens: !sm.mostraItens } : sm;
    });

    setListItensAgrupadoEmSm(newArray);
  };


  function getFethPermissionChangeOrientation() {
    setCarregando(true);
    setListaCarregando(true);


    const url = '/sm/triagem/permissao/alterarorientacao/';


    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setUserChangeOrientation(true);
        }
        else {
          setUserChangeOrientation(false);
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        setEmptyObject({
          image: Images.other500,
          title: 'Erro',
          description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('getFethPermissionChangeOrientation', JSON.stringify(err));
      })
      .finally(() => {
        setCarregando(false);
        setListaCarregando(false);
      });
  };


  function renderListGruposProduto(listGrpResp: any) {
    const listGrpTriagemMap = listGrpResp.map((triagem: any) => {
      const groupTriagemMapped = {
        'key': triagem.CODGRUPO,
        'label': triagem.GRUPO,
        'value': triagem.CODGRUPO,
      };
      return groupTriagemMapped;
    });

    setListGruposProduto([
      // emptyList,
      ...listGrpTriagemMap,
    ]);
  };


  async function findGruposDoProduto() {
    try {
      setCarregando(true);

      const url = '/sm/triagem/consulta/grupoproduto/';
      const response = await ApiWS().post(url);
      renderListGruposProduto(response.data);
    }
    catch (error) {
      console.error('findGruposDoProduto', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };


  function postExport(values: any) {
    setCarregando(true);

    const url = 'sme/triagem/exporta';

    const payload = {
      'CHECK': values.radio_os_type,
      'GRUPOTRIAG': values.select_grupo_origem,
      'OS': values.input_os,
      'DESCRI': values.input_descricao,
      'SOLICITANTE': values.input_solicitante,
      'NUM': values.input_num_sm,
      'CODGRUPO': values.select_grupo_produto,
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
        setShowListSM(true);
      });
  };


  function postPrinter(values: any) {
    setCarregando(true);

    const url = `/sme/atendimento/impressao/${values.CP_FILIAL}/${values.CP_NUM}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  useEffect(() => {
    findGruposDoUsuario();
    findGruposDoProduto();
    getFethPermissionChangeOrientation();
  }, []);


  useEffect(() => {
    listDaTransferencia.length > 0 ? setListTransfEmpty(false) : setListTransfEmpty(true);
  }, [listDaTransferencia.length]);


  const columns = [
    {
      dataField: 'CP_FILIAL',
      text: 'Filial',
      csvFormatter: (cell) => {
        return `'${cell}`;
      },
    },
    {
      dataField: 'CP_CCSOL',
      text: 'CC',
      csvFormatter: (cell) => {
        return `'${cell}`;
      },
    },
    {
      dataField: 'CP_NUM',
      text: 'SME',
      csvFormatter: (cell) => {
        return `'${cell}`;
      },
    },
    {
      dataField: 'CP_EMISSAO',
      text: 'Emissao',
      csvFormatter: (cell) => {
        return formataData(cell);
      },
    },
    {
      dataField: 'CP_OBS',
      text: 'Utilizacao',
    },
    {
      dataField: 'CP_ORIGPC',
      text: 'Orientacao',
    },
    // ---- ITENS -----------------
    {
      dataField: 'CP_ITEM',
      text: 'Item',
    },
    {
      dataField: 'CP_PRODUTO',
      text: 'Produto',
    },
    {
      dataField: 'CP_DESCRI',
      text: 'Descricao',
    },
    {
      dataField: 'CP_UM',
      text: 'UN',
    },
    {
      dataField: 'CP_QUANT',
      text: 'Qtd. Aprovada',
    },
    {
      dataField: 'SALDO',
      text: 'Qtd. Pendente',
    },
    {
      dataField: 'QUANTPEN',
      text: 'Qtd. Transf.',
    },
    {
      dataField: 'CP_DATPRF',
      text: 'Dt. Neces.',
      csvFormatter: (cell) => {
        return formataData(cell);
      },
    },
    {
      dataField: 'CP_DTAPROV',
      text: 'Dt. Aprv.',
      csvFormatter: (cell) => {
        return formataData(cell);
      },
    },
    {
      dataField: 'CP_DTPENTR',
      text: 'Data Prev. Entrega',
      csvFormatter: (cell) => {
        return formataData(cell);
      },
    },
    {
      dataField: 'CP_DESCOMP',
      text: 'Descr. Complem.',
    },
    {
      dataField: 'SITUCAO',
      text: 'Situacao',
    },
    {
      dataField: 'C1_NUM',
      text: 'SC.',
      csvFormatter: (cell) => {
        if (cell === undefined || cell === null) {
          return '';
        }
        else {
          return cell;
        }
      },
    },
    {
      dataField: 'C1_ITEM',
      text: 'Item SC',
      csvFormatter: (cell) => {
        if (cell === undefined || cell === null) {
          return '';
        }
        else {
          return cell;
        }
      },
    },
  ];

  return (

    <ToolkitProvider
      keyField={'id'}
      data={listItens}
      columns={columns}
      exportCSV={{
        blobType: ExcelConfigs.blobType,
        fileName: ExcelConfigs.SME_Triagem,
        onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
        exportAll: ExcelConfigs.exportAll,
        noAutoBOM: false,
        separator: ';',
      }}>
      {(propsTkProvider) => (

        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={4}
              navArray={SME_NavRoutes}
              title={'SME'}
            />
          </NavHeader>



          <Content>
            <ContentStyled fluid>


              <ContainerView>

                <SectionMargin>
                  <TitleGroup>
                    {'Triagem de SME'.toUpperCase()}
                  </TitleGroup>



                  {listaCarregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}

                  {carregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}

                  {wait && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}

                  <SectionMargin>
                    <Row>
                      <Col sm={8}>
                        <Formik
                          enableReinitialize
                          validateOnMount
                          validationSchema={SmeTriagemFiltrarValidationSchema}
                          initialValues={SmeTriagemFiltroModel}
                          onSubmit={(values) => {
                            setFormValues(values);
                            onClick_Filtrar(values);
                          }}>
                          {({
                            values,
                            resetForm,
                            isValid,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            setValues,
                          }) => (

                            <FilterContainerForm onSubmit={handleSubmit}>

                              <Col>
                                <Row>
                                  <Col>
                                    <DropdownInput
                                      name={'select_grupo_origem'}
                                      label={'Do Grupo'}
                                      placeholder={'Selecione ...'}
                                      dropArray={listGruposOrigem}
                                      value={codSelectGrupoOrigem}
                                      onChange={(e: any) => {
                                        const selectComponentValue = e.target.value;
                                        const selectComponentTarget = [...e.target.children];
                                        const optionSelected = selectComponentTarget.filter((item) => item.selected === true);

                                        handleChange(e);
                                        setValueSelectGrupoOrigem(optionSelected[0].label);
                                        setCodSelectGrupoOrigem(selectComponentValue);
                                        onChange_findGruposDestino(selectComponentValue);
                                        setFieldValue('select_grupo_origem', selectComponentValue);
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </Col>


                                  <Col>
                                    <TextInput
                                      type={'text'}
                                      name={'input_os'}
                                      label={'CC'}
                                      placeholder={'CC'}
                                      value={values.input_os || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Col>
                                </Row>


                                <Row>
                                  <Col>
                                    <TextInput
                                      type={'text'}
                                      name={'input_descricao'}
                                      label={'Descrição'}
                                      placeholder={'Descrição'}
                                      value={values.input_descricao || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Col>


                                  <Col>
                                    <TextInput
                                      type={'text'}
                                      name={'input_solicitante'}
                                      label={'Solicitante'}
                                      placeholder={'Solicitante'}
                                      value={values.input_solicitante || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Col>
                                </Row>


                                <Row>
                                  <Col>
                                    <TextInput
                                      type={'text'}
                                      name={'input_num_sm'}
                                      label={'Número SME'}
                                      placeholder={'Número'}
                                      min={0}
                                      max={7}
                                      value={values.input_num_sm || ''}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Col>


                                  <Col>
                                    <DropdownInput
                                      name={'select_grupo_produto'}
                                      label={'Grp. Prod.'}
                                      placeholder={'Selecione ...'}
                                      dropArray={listGruposProduto}
                                      value={values.select_grupo_produto}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Col>
                                </Row>


                                <Row>
                                  <Col>
                                    <Form.Group>
                                      <CheckBoxFilterSMEView>

                                        <Form.Check
                                          checked={osType === ''}
                                          type="checkbox"
                                          name="radio_os_type"
                                          label="Todas"
                                          onChange={(e: any) => {
                                            handleChange(e);
                                            setOsType('');
                                            setFieldValue('radio_os_type', '');
                                          }}
                                          onBlur={handleBlur}
                                        />


                                        <Form.Check
                                          checked={osType === '0'}
                                          type="checkbox"
                                          name="radio_os_type"
                                          label="Aguardando Aprovação de PC"
                                          onChange={(e: any) => {
                                            handleChange(e);
                                            setOsType('0');
                                            setFieldValue('radio_os_type', '0');
                                          }}
                                          onBlur={handleBlur}
                                        />


                                        <Form.Check
                                          checked={osType === '6'}
                                          type="checkbox"
                                          name="radio_os_type"
                                          label="Compra / Locação"
                                          onChange={(e: any) => {
                                            handleChange(e);
                                            setOsType('6');
                                            setFieldValue('radio_os_type', '6');
                                          }}
                                          onBlur={handleBlur}
                                        />


                                        <Form.Check
                                          checked={osType === '1'}
                                          type="checkbox"
                                          name="radio_os_type"
                                          label="Pendente de Atendimento"
                                          onChange={(e: any) => {
                                            handleChange(e);
                                            setOsType('1');
                                            setFieldValue('radio_os_type', '1');
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </CheckBoxFilterSMEView>
                                    </Form.Group>
                                  </Col>
                                </Row>



                                <Row>
                                  <Col>
                                    <TransferSMEButtonContainer>

                                      <IButton
                                        disabled={listItens.length <= 0 || carregando || listaCarregando}
                                        type={'button'}
                                        text={'Exportar'}
                                        backgroundColor={Colors?.excel}
                                        onClick={() => {
                                          postExport(values);
                                        }}
                                      />


                                      <IButton
                                        text={'Limpar'}
                                        backgroundColor={Colors?.gray}
                                        type={'reset'}
                                        onClick={() => {
                                          resetForm();

                                          setCodSelectGrupoOrigem('');
                                          setListItensAgrupadoEmSm([]);
                                          setOsType('');
                                          setListItens([]);

                                          setValues(SmeTriagemFiltroModel);
                                        }}
                                      />


                                      <IButton
                                        disabled={!isValid || carregando || listaCarregando || codSelectGrupoOrigem === null}
                                        text={'Filtrar'}
                                        backgroundColor={Colors?.primary}
                                        type={'submit'}
                                      />
                                    </TransferSMEButtonContainer>
                                  </Col>
                                </Row>

                              </Col>

                            </FilterContainerForm>

                          )}
                        </Formik>
                      </Col>



                      <Col sm={4}>
                        <Formik
                          enableReinitialize
                          validateOnMount
                          validationSchema={SmeTriagemTransferirValidationSchema}
                          initialValues={SmeTriagemTransferirModel}
                          onSubmit={(values) => {
                            onClick_Transferir(values);
                          }}>
                          {({
                            values,
                            isValid,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                          }) => (

                            <FilterContainerForm onSubmit={handleSubmit}>

                              <MoveGroupInputView>

                                <DropdownInput
                                  editable={listDaTransferencia.length > 0}
                                  name={'select_grupo_destino'}
                                  label={'Para Grupo'}
                                  placeholder={listItensAgrupadoEmSm.length > 0 ? 'Selecione ...' : 'Escolha um Grupo para Transferir'}
                                  dropArray={listGruposDestino}
                                  value={codSelectGrupoDestino}
                                  onChange={(e: any) => {
                                    const selectComponentValue = e.target.value;
                                    const selectComponentTarget = [...e.target.children];
                                    const optionSelected = selectComponentTarget.filter((item) => item.selected === true);

                                    handleChange(e);
                                    setValueSelectGrupoDestino(optionSelected[0].label);

                                    setCodSelectGrupoDestino(selectComponentValue);
                                    setFieldValue('select_grupo_destino', selectComponentValue);
                                  }}
                                  onBlur={handleBlur}
                                />


                                <TextInput
                                  disabled={listDaTransferencia.length === 0}
                                  textHeight={80}
                                  type={'text'}
                                  name={'OBSTRIAGEM'}
                                  label={'Observações'}
                                  placeholder={'Observações'}
                                  max={250}
                                  value={values.OBSTRIAGEM || ''}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />

                              </MoveGroupInputView>



                              <TransferSMEButtonContainer>
                                <TransferSMEMargin>

                                  <IButton
                                    disabled={carregando || listaCarregando || listTransfEmpty}
                                    text={'Gerar SC'}
                                    type={'button'}
                                    onClick={() => {
                                      const gerarSC = true;
                                      onClick_Transferir(values, gerarSC);
                                    }}
                                  />

                                </TransferSMEMargin>


                                <TransferSMEMargin>

                                  <IButton
                                    disabled={!isValid || carregando || listaCarregando || (codSelectGrupoOrigem === '' || codSelectGrupoOrigem === null) || listDaTransferencia.length === 0}
                                    text={'Transferir'}
                                    backgroundColor={Colors?.primary}
                                    type={'submit'}
                                  />

                                </TransferSMEMargin>
                              </TransferSMEButtonContainer>

                            </FilterContainerForm>

                          )}
                        </Formik>
                      </Col>

                    </Row>
                  </SectionMargin>



                  <ContainerView>

                    {!carregando && !listaCarregando && showListSM && listItensAgrupadoEmSm.length === 0 && (
                      <EmptyContent
                        image={emptyObject.image}
                        title={emptyObject.title}
                        description={emptyObject.description}
                      />
                    )}



                    {!carregando && !listaCarregando && showListSM && listItensAgrupadoEmSm.length > 0 && (
                      <TableAccordionStyled>
                        <thead>
                          <tr>
                            <th colSpan={2}>Itens</th>
                            <th>CC</th>
                            <th>SM</th>
                            <th>Data</th>
                            <th>Utilização</th>
                            <th>Orientação</th>
                            <th colSpan={4}>Ações</th>
                          </tr>
                        </thead>


                        <tbody>
                          {listItensAgrupadoEmSm.map((sm, index) => (
                            <>

                              <tr key={index}>
                                <td className="actions">
                                  <TableViewButton>
                                    <input
                                      type='checkbox'
                                      name={`chkSm${sm.CP_FILIAL}${sm.CP_NUM}`}
                                      onChange={(e) => {
                                        onSelect_MarcaTodosItensSM(sm, e.target.checked);
                                      }}
                                    />
                                  </TableViewButton>
                                </td>

                                <td className="actions">
                                  <ButtonTableAction
                                    key={'right'}
                                    placement={'right'}
                                    tooltip={
                                      sm.mostraItens
                                        ? TextTooltip.ITEMS_RETRACT
                                        : TextTooltip.ITEMS_SHOW
                                    }
                                    onClick={() => {
                                      onClick_MostarItens(`${sm.CP_FILIAL}${sm.CP_NUM}`);
                                      handleVisaoItens(sm.CP_NUM);
                                    }}
                                    icon={
                                      sm.mostraItens
                                        ? <IconColapseExpand color={Colors?.black} size={16} />
                                        : <IconColapseRetract color={Colors?.black} size={16} />
                                    }
                                  />
                                </td>

                                <td>{sm.CP_CCSOL}</td>
                                <td>{sm.CP_NUM}</td>
                                <td>{formataData(sm.CP_EMISSAO)}</td>
                                <td>{sm.CP_OBS}</td>
                                <td>{sm.CP_ORIGPC}</td>


                                {/* Botão Observacao */}
                                <td className={'actions'}>
                                  <ButtonTableAction
                                    tooltip={
                                      sm.CP_OBSERVA && sm.CP_OBSERVA.trim() !== '' && sm.CP_OBSERVA !== null
                                        ? sm.CP_OBSERVA
                                        : TextTooltip.NO_OBSERVATION
                                    }
                                    icon={
                                      sm.CP_OBSERVA && sm.CP_OBSERVA !== '' && sm.CP_OBSERVA !== null
                                        ? <IconInfoSquare
                                          color={Colors?.black}
                                          size={16}
                                        />
                                        : <IconInfoSquare
                                          color={Colors?.gray}
                                          size={16}
                                        />
                                    }
                                    backgroundColor={
                                      !sm.CP_OBSERVA &&
                                      Colors?.gray
                                    }
                                  />
                                </td>


                                {/* Botão Impressao */}
                                <td className="actions">
                                  <ButtonTableAction
                                    tooltip={TextTooltip.PRINTER}
                                    onClick={() => {
                                      postPrinter(sm);
                                    }}
                                    icon={
                                      <IconPrinter
                                        color={Colors?.black}
                                        size={16}
                                      />
                                    }
                                  />
                                </td>


                                {/* Botão Modal Anexos */}
                                <td className="actions">
                                  {sm.ANEXO === 'S' ? (
                                    <ButtonTableAction
                                      tooltip={TextTooltip.ATTACHMENT}
                                      onClick={() => {
                                        setValueAnexo({
                                          FILIAL: sm.CP_FILIAL,
                                          NUM: sm.CP_NUM,
                                        });
                                        setTelaAnexo(true);
                                      }}
                                      icon={
                                        <IconAttachment
                                          color={Colors?.black}
                                          size={16}
                                        />
                                      }
                                    />
                                  ) : (
                                    <ButtonTableAction
                                      tooltip={TextTooltip.LESS_ATTACHMENT}
                                      icon={
                                        <IconAttachment
                                          color={Colors?.gray}
                                          size={16}
                                        />
                                      }
                                    />
                                  )}
                                </td>


                                {/* Se possue permissão => Botão Modal Anexos */}
                                {userChangeOrientation && (
                                  <td className="actions">
                                    <ButtonTableAction
                                      tooltip={TextTooltip.CHANGE_ORIENTATION}
                                      onClick={() => {
                                        setValueChangeOrientation({
                                          FILIAL: sm.CP_FILIAL,
                                          NUM: sm.CP_NUM,
                                          ORIENTOLD: sm.CP_ORIGPC,
                                        });
                                        setShowChangeOrientation(true);
                                      }}
                                      icon={
                                        <IconEdit
                                          color={Colors?.black}
                                          size={16}
                                        />
                                      }
                                    />
                                  </td>

                                )}

                              </tr>



                              <tr className="no-style">
                                <td colSpan={11} className="no-style">
                                  <tr id={`table${sm.CP_FILIAL}${sm.CP_NUM}`} className={'oculto'}>

                                    <TableDefaultStyled>
                                      <thead className="thead-two">
                                        <tr>
                                          <th></th> {/* 01 */}
                                          <th>Item</th> {/* 02 */}
                                          <th>Cod. Prod.</th> {/* 03 */}
                                          <th>Desc.</th> {/* 04 */}
                                          <th>UN</th> {/* 05 */}
                                          <th>Qtd. Aprov.</th> {/* 06 */}
                                          <th>Qtd. Pen.</th> {/* 07 */}
                                          <th>Qtd. Transf.</th> {/* 08 */}
                                          <th>Dt. Necess.</th> {/* 09 */}
                                          <th>Dt. Prev. Ent.</th> {/* 10 */}
                                          <th>Desc. Comple.</th> {/* 11 */}
                                          <th>Situação</th> {/* 12 */}
                                          <th>PC</th> {/* 13 */}
                                          <th>Item PC</th> {/* 13 */}
                                          <th>SC</th> {/* 13 */}
                                          <th>Item SC</th> {/* 14 */}
                                          <th colSpan={3}>Histórico</th> {/* 15 */}
                                        </tr>
                                      </thead>



                                      <tbody className="tbody-two">
                                        {listaItensDaSM(sm.CP_NUM, sm.CP_FILIAL).map((item) => (
                                          <tr className="no-horizontal-border">
                                            <td>
                                              <TableViewButton>
                                                <input
                                                  type='checkbox'
                                                  name={`chkItem${sm.CP_FILIAL}${sm.CP_NUM}`}
                                                  onChange={(e) =>
                                                    onSelect_MarcarParaTransferir(item, e.target.checked)
                                                  }
                                                />
                                              </TableViewButton>
                                            </td> {/* 01 */}

                                            <td>{item.CP_ITEM}</td>  {/* 02 */}
                                            <td>{item.CP_PRODUTO}</td> {/* 03 */}
                                            <td>{item.CP_DESCRI}</td> {/* 04 */}
                                            <td>{item.CP_UM}</td> {/* 05 */}
                                            <td>{item.CP_QUANT}</td> {/* 06 */}
                                            <td>{item.SALDO}</td> {/* 07 */}
                                            <td>
                                              <input
                                                id={`input_QUANTPEN-${item.CP_FILIAL}-${item.CP_NUM}-${item.CP_ITEM}`}
                                                type="number"
                                                className={`input-sm-${item.CP_FILIAL}-${sm.CP_NUM}`}
                                                defaultValue={item.QUANTPEN}
                                                min='1'
                                                max={item.QUANTPEN}
                                                onChange={(e) => {
                                                  item.QUANTPEN = e.target.valueAsNumber;
                                                  if ((item.QUANTPEN > item.SALDO || item.QUANTPEN <= 0) && item.QUANTPEN !== '') {
                                                    setMessageValue({
                                                      head: 'Erro!',
                                                      orient: 'A Quantidade para transferir deve ser entre 1 e o valor pendente.',
                                                    });
                                                    setMessageModal(true);
                                                    e.target.value = item.SALDO;
                                                  }
                                                }}
                                              />
                                            </td> {/* 08 */}

                                            <td>{formataData(item.CP_DATPRF)}</td> {/* 09 */}
                                            <td>{formataData(item.CP_DTPENTR)}</td> {/* 10 */}
                                            <td>{item.CP_DESCOMP}</td> {/* 11 */}
                                            <td>{item.SITUACAO}</td> {/* 12 */}
                                            <td>{item.C7_NUM}</td> {/* 13 */}
                                            <td>{item.C7_ITEM}</td> {/* 13 */}
                                            <td>{item.C1_NUM}</td> {/* 13 */}
                                            <td>{item.C1_ITEM}</td> {/* 14 */}


                                            {/* Botão Modal Historico */}
                                            <td className="actions">
                                              <TableActionButton
                                                onClick={() => {
                                                  setValueHistorico(item);
                                                  setModalHistorico(true);
                                                }}>
                                                <IconHistory
                                                  color={Colors?.black}
                                                  size={16}
                                                />
                                              </TableActionButton>
                                            </td> {/* 15 */}

                                            {/* Botão Modal Historico */}
                                            <td className="actions">
                                              <TableActionButton
                                                onClick={() => {
                                                  setValuesHistoricoAprovacaoSME({ CC: item.CP_CCSOL, FILIAL: item.CP_FILIAL, NUM: item.CP_NUM, ITEM: item.CP_ITEM });
                                                  setShowHistoricoAprovacaoSME(true);
                                                }}>
                                                <IconApproval
                                                  color={Colors?.black}
                                                  size={16}
                                                />
                                              </TableActionButton>
                                            </td> {/* 16 */}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </TableDefaultStyled>

                                  </tr>
                                </td>
                              </tr>

                            </>
                          ))}
                        </tbody>
                      </TableAccordionStyled>
                    )}

                  </ContainerView>

                  <div className={'d-none'}>
                    <BootstrapTable {...propsTkProvider.baseProps} />
                  </div>



                  {listGruposOrigem.length <= 0 && !carregando && (

                    <EmptyContent
                      image={unauthorizedObject.image}
                      title={unauthorizedObject.title}
                      description={unauthorizedObject.description}
                    />
                  )}

                </SectionMargin>

              </ContainerView>

            </ContentStyled>

          </Content>


          {valuesHistoricoAprovacaoSME && showHistoricoAprovacaoSME && (
            <HistoricoAprovacaoSMEModal
              show={showHistoricoAprovacaoSME}
              onHide={() => setShowHistoricoAprovacaoSME(false)}
              value={valuesHistoricoAprovacaoSME}
            />
          )}


          {valueHistorico && (
            <SmeHistoricoModal
              show={modalHistorico}
              todo={valueHistorico}
              onHide={() => {
                setModalHistorico(false);
                setTimeout(() => {
                  setValueHistorico(null);
                }, 300);
              }}
            />
          )}


          {listProgressTransferValue && (
            <ConfirmationModal
              show={progressModal}
              head='Feito!'
              orient={'Os itens foram transferidos!'}
              others={
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    totalSize: listProgressTransferValue.length,
                  })}>
                  {({
                    paginationProps,
                    paginationTableProps,
                  }) => (
                    <TableCuston
                      noQuantity
                      data={listProgressTransferValue}
                      columns={[
                        {
                          dataField: 'NUM',
                          text: 'SME',
                          sort: true,
                        },
                        {
                          dataField: 'ITEM',
                          text: 'Item',
                          sort: true,
                        },
                        {
                          dataField: '',
                          text: 'Origem',
                          formatter: () => {
                            return valueSelectGrupoOrigem;
                          },
                        },
                        {
                          dataField: '',
                          text: 'Destino',
                          formatter: () => {
                            return valueSelectGrupoDestino;
                          },
                        },
                      ]}
                      paginationProps={paginationProps}
                      paginationTableProps={paginationTableProps}
                    />
                  )}
                </PaginationProvider>
              }
              negative={() => {
                onClick_Filtrar(formValues);
                setProgressModal(false);
                setTimeout(() => {
                  setListProgressTransferValue(null);
                }, 300);
              }}
            />
          )}


          {valueAnexo && (
            <SmeAnexosModal
              show={telaAnexo}
              anexar={false}
              sm={valueAnexo}
              onHide={() => {
                setTelaAnexo(false);
                setTimeout(() => {
                  setValueAnexo(null);
                }, 300);
              }}
            />
          )}


          {showChangeOrientation && valueChangeOrientation && (
            <SMEAlteraOrientacaoModal
              show={showChangeOrientation}
              sm={valueChangeOrientation}
              onHide={() => {
                setShowChangeOrientation(false);
                setTimeout(() => {
                  setValueChangeOrientation(null);
                }, 300);
              }}
              fetchNow={() => onClick_Filtrar(formValues)}
            />
          )}


          <ConfirmationModal
            show={messageModal}
            head={messageValue.head}
            orient={messageValue.orient}
            negative={() => {
              setMessageModal(false);
            }}
          />

        </Screen>

      )}
    </ToolkitProvider>

  );
};



export default SME_TriagemScreen;
