import * as Yup from 'yup';


const PEXAtualizarMesRefValidationSchema = Yup.object().shape({

  MES: Yup.string().required('Obrigatório'),

  ANO: Yup.string().required('Obrigatório'),

});



export default PEXAtualizarMesRefValidationSchema;
