import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useParams,
} from 'react-router';

import {
  Colors,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  TablePrinterStyled,
} from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';

import {
  IconCheck, IconNot, IconPrinter,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';

import ApiWS from '../../../../../services/api.service';

import {
  RootState,
} from '../../../../../store';
import { intranet_config } from '../../../../../utils/Config';

import {
  currentDateFormated,
  formataData,
} from '../../../../../utils/DateUtils';

import objRD from './RD-impressao.model';

import {
  PrinterContainer,
  A4LandscapeContent,
} from './RD-impressao.styled';



const RD_ImpressaoScreen: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);


  const { id: param_rd } = useParams() as any;


  const [carregando, setCarregando] = useState(false);
  const [rd, setRD] = useState(objRD);

  const [aprovadores, setAprovadores] = useState([]);

  async function findAprovadores(os: any) {
    try {
      const url = '/rd/consulta/pendentes/aprovadores';
      const response = await ApiWS().post(url, { 'cc': os });

      setAprovadores(response.data);
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }
  };


  async function findRD(numRD: any) {
    try {
      setCarregando(true);

      const url = `/rd/impressao/${numRD}`;
      const response = await ApiWS().get(url);

      setRD(response.data[0]);

      await findAprovadores(response.data[0].QS_CC);
      console.log(response.data[0]);
    }
    catch (error) {
      console.error('findRD', JSON.stringify(error.response, null, 2));
    }
    finally {
      setCarregando(false);
    }
  };


  async function printerLandscapePage() {
    await findRD(param_rd);
    // window.print();
  }



  function renderLoadingComponent() {
    return (

      <PrinterContainer>
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.primary}
        />
      </PrinterContainer>

    );
  }



  useEffect(() => {
    printerLandscapePage();
  }, []);



  function renderLoadingPrinter(): JSX.Element {
    return (
      <A4LandscapeContent className="page-a4-landscape">

        <TablePrinterStyled>
          <tbody>
            <tr>
              <td rowSpan={2} className="image-td">
                <img
                  alt="Logo"
                  src={intranet_config.Images.logo}
                />
              </td>
              <th rowSpan={2} className="name-printer-td">
                RD - Requisição de Pessoal
              </th>
              <th colSpan={2}>Nº</th>
              <td colSpan={2}>{rd.QS_VAGA}</td>
            </tr>

            <tr>
              <th colSpan={2}>Data</th>
              <td colSpan={2}>{formataData(rd.QS_DTABERT)}</td>

            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <tbody>
            <tr className="text-center">
              <th>Nª da OS</th>
              <th>Setor - Operação</th>
              <th>Dt. Prev. Atend.</th>
              <th>Data Necessidade</th>
            </tr>

            <tr className="text-center">
              <td>{rd.QS_CC}</td>
              <td>{rd.QS_DESCUST}</td>
              <td>{formataData(rd.QS_DTPREVI)}</td>
              <td>{formataData(rd.QS_DTNECES)}</td>

            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <tbody>
            <tr className="text-center">
              <th>Quantidade</th>
              <th>Cód. Função</th>
              <th>Desc. Função</th>
              <th>Cód. Cargo</th>
              <th>Desc. Cargo</th>
            </tr>

            <tr className="text-center">
              <td>{rd.QS_NRVAGA}</td>
              <td>{rd.QS_FUNCAO}</td>
              <td>{rd.QS_DESCRIC}</td>
              <td>{rd.QS_CARGO}</td>
              <td>{rd.QS_DESCARG}</td>

            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Motivo</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Justificativa</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={5} colSpan={5}>{
                {
                  '01': 'Aumento de Quadro',
                  '02': 'Vaga Prevista',
                  '03': 'Substituição',
                  '04': 'Outros',
                }[rd.QS_MOTIVO]
              }</td>
              <td rowSpan={3}>{rd.QS_JUSTIF}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Tipo Contrato</th>
              <th>Prazo Contrato</th>
              <th>Descrição Sumária das Atribuições</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{
                {
                  '01': 'Efetivo',
                  '02': 'Temporário',
                  '03': 'Estagiário',
                  '04': 'Outros',
                }[rd.QS_TPCONTR]
              }</td>
              <td>{rd.QS_PRZCONT}</td>
              <td colSpan={6}>{rd.QS_DESCSUM}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Escolaridade</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Formação Profissional</th>
              <th>Carecteristicas Pessoais Básicas</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3}>{rd.QS_DESCESC}</td>
              <td rowSpan={3} colSpan={9} className='text-center'>{rd.QS_FORMCAO}</td>
              <td rowSpan={3} colSpan={9}>{rd.QS_PERF}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Especialização / Conhecimentos</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3}>{rd.QS_ESPCONH}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Remuneração Básica</th>
              <th></th>
              <th></th>
              <th></th>
              <th colSpan={7}>Benefícios</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Salário Base (Mensal):</b> R$ {rd.QS_VCUSTO}</td>
              &nbsp; <td rowSpan={4} colSpan={17}>{rd.QS_BENEFIC}</td>
            </tr>
            <tr>
              <td><b>Soldador:</b> {rd.QS_FUNSOLD.replaceAll(' ', '') === '1' ? 'Sim' : 'Não'}</td>
            </tr>
            <tr>
              <td><b>Periculosidade:</b> {rd.QS_PERICUL.replaceAll(' ', '') === '1' ? 'Sim' : 'Não'}</td>
            </tr>
            <tr>
              <td><b>Insalubridade:</b> {rd.QS_INSALUB.replaceAll(' ', '') === '1' ? 'Sim' : 'Não'}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        {
          parseInt(rd.QS_FUNSOLD) === 1 &&
          <>
            <TablePrinterStyled>
              <thead>
                <tr>
                  <th>Processos de Soldagem  </th>
                  <th>Eletrodo Revestido</th>
                  <th>TIG</th>
                  <th>MIG</th>
                  <th>MIG</th>
                  <th>A. Tubular</th>
                  <th>Outros</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> - - - </td>
                  <td>
                    {parseInt(rd.QS_SOLDELT) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rd.QS_SOLDTIG) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rd.QS_SOLMIG) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rd.QS_SOLMAG) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rd.QS_SOLTUB) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td> {rd.QS_PSOUTRO}</td>
                </tr>
              </tbody>
            </TablePrinterStyled>


            <TablePrinterStyled>
              <thead>
                <tr>
                  <th>Materiais de Soldagem</th>
                  <th>Aço Carbono</th>
                  <th>Aço Inox</th>
                  <th>Ligas de Níquel</th>
                  <th>Titânio</th>
                  <th>Alumínio</th>
                  <th>Outros</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> - - - </td>
                  <td>
                    {parseInt(rd.QS_ACOCARB) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rd.QS_ACOINOX) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rd.QS_LIGNIQ) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rd.QS_TITANIO) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>
                    {parseInt(rd.QS_ALUMIN) === 1
                      ? <IconCheck size={18} color={'Black'}/>
                      : <IconNot size={18} color={'Black'}/>
                    }
                  </td>
                  <td>{rd.QS_OUTRMAT}</td>
                </tr>
              </tbody>
            </TablePrinterStyled>
          </>
        }


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Observações do Solicitante</th>
              <th>Observações do RH</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{rd.QS_OBSERVA}</td>
              <td>{rd.QS_OBSERVR}</td>
            </tr>
          </tbody>
        </TablePrinterStyled>


        <TablePrinterStyled>
          <thead>
            <tr>
              <th>Status</th>
              <th>Solicitada <IconCheck color='Back' /></th>
              <th>
                Aprovada pelo RH
                {{
                  'APROVADO': <IconCheck />,
                  'REPROVADO': <IconNot />,
                }[rd.QS_APROR]}
              </th>
              <th>
                Aprovada pelo CA
                {{
                  'APROVADO': <IconCheck />,
                  'REPROVADO': <IconNot />,
                }[rd.QS_APROC]}
              </th>
              <th>
                Aprovada na OS
                {{
                  'APROVADO': <IconCheck />,
                  'REPROVADO': <IconNot />,
                }[rd.QS_APROS]}
              </th>
              <th>
                Aprovada na Final
                {{
                  'APROVADO': <IconCheck />,
                  'REPROVADO': <IconNot />,
                }[rd.QS_APROV]}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Sol./Aprov.</b><tr><b>Data/Hora</b></tr></td>


              <td>
                {rd.QS_SOLICIT}


                <tr>{rd.DTINSERCAO !== null && rd.DTINSERCAO}</tr>
              </td>


              <td>
                Aprovação RH
                <tr>{rd.DTAPRRH !== null && rd.DTAPRRH}</tr>
              </td>


              <td>{aprovadores.map((aprv) => (
                <tr>{aprv.FUNCAO === 'COORDENADOR ADM' && aprv.NOME}</tr>
              ))}

              <tr>{rd.DTAPRCA !== null && rd.DTAPRCA}</tr>

              </td>


              <td>{aprovadores.map((aprv) => (
                <tr>{aprv.FUNCAO === 'RRO' && aprv.NOME}</tr>
              ))}

              <tr>{rd.DTAPRRRO !== null && rd.DTAPRRRO}</tr>

              </td>

              <td>{aprovadores.map((aprv) => (
                <tr>{aprv.FUNCAO === 'GESTOR' && aprv.NOME}</tr>
              ))}

              <tr>{rd.DTAPRFINAL !== null && rd.DTAPRFINAL}</tr>

              </td>

            </tr>
          </tbody>
        </TablePrinterStyled>

        <div>
          <p><i>* - Relatório Gerado por: {user.name} em: {currentDateFormated()}</i></p>
          <ButtonTableAction
            tooltip={TextTooltip.PRINTER}
            onClick={() => {
              window.print();
            }}
            icon={
              <IconPrinter
                color={Colors?.black}
                size={72}
              />
            }
          />
        </div>

      </A4LandscapeContent>
    );
  }


  return carregando ? renderLoadingComponent() : renderLoadingPrinter();
};



export default RD_ImpressaoScreen;
