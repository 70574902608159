import React from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  YesNoArray,
} from '../../../../../../common/arrays';

import {
  FilterContainerForm,
  SubTitleGroup,
  TextBold,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';

import {
  SoldadorFieldHelp,
  SoldadorFieldTextHelp,
} from '../RD-inclusao.styled';



const RDCriterios = () => {
  const fields = () => {
    let listField = [];

    listField = [
      { type: 'text', name: 'criterio', label: '4 - Supera', placeholder: 'Apresenta comportamento e conhecimento técnico acima do esperado.', xs: 6 },
      { type: 'text', name: 'criterio', label: '3 - Atende', placeholder: 'Atende em termos técnicos e comportamentais para o exercio do cargo.', xs: 6 },
      { type: 'text', name: 'criterio', label: '2 - Atende parcialmente', placeholder: 'Atende aos requisitos do cargo/empresa, necessitando desenvolvimento.', xs: 6 },
      { type: 'text', name: 'criterio', label: '1 - Não atende', placeholder: 'Não atende aos requisitos para ocupar o cargo.', xs: 6 },
    ];

    return listField;
  };

  return (

    <FilterContainerForm>
      <TitleGroup>
        {'Critérios'.toUpperCase()}
      </TitleGroup>

      <Row>
        {fields().map((field) => (

          <Col xs={field?.xs}>

            {field.type === 'text' && (
              <TextInput
                editable={false}
                type={'text'}
                label={field.label}
                placeholder={field.placeholder}
              />
            )}

          </Col>
        ))}
      </Row>

    </FilterContainerForm>

  );
};



export default RDCriterios;
