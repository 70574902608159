import React, { useEffect, useState } from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import { Colors, Images, Metrics } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import { TitleGroup } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconAddTag, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import { currentDateToInput, formataData } from '../../../../../../../utils/DateUtils';



interface IProps {
  idUser: string;
  type?: any;
}



const UserGroupSection: React.FC<IProps> = ({ idUser, type }) => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(true);

  const emptyObject = {
    image: Images.empty,
    title: 'Sem Grupos!',
    description: 'Usuário não está incluso em nenhum grupo.',
  };

  const [listGroupsDrop, setListGroupsDrop] = useState([]);
  const [listGroupsGrid, setListGroupsGrid] = useState([]);

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  function postFetchGroupDrop(values) {
    setLoading(true);

    const url = 'gi/grupo/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data[0].forEach((group, index) => {
            setListGroupsDrop((list) => [...list, {
              key: index,
              label: group.DESCR,
              value: group.ID,
            }]);
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchGroupDrop', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postFetchGroupGrid(values, page_iteration) {
    setLoading(true);
    setShowList(false);
    setValuesPayload(values);
    setListGroupsGrid([]);

    const url = 'gi/usuario/grupo/consultar';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListGroupsGrid(res.data[0]);
          setTotalResults(res.data[1][0].TOTAL);
          setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchGroupGrid', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  function postAddGroup_x_User(values, resetForm) {
    setLoading(true);

    let url = '';

    url = 'gi/usuario/grupo/adicionar';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });
          postFetchGroupGrid(values, 1);
          resetForm();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postAddGroup_x_User', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postRemoveGroup_x_User(values) {
    setLoading(true);

    let url = '';

    url = 'gi/usuario/grupo/remover';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });
          postFetchGroupGrid(values, 1);
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveGroup_x_User', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchGroupGrid(valuesPayload, page_var);
    setPage(page_var);
  };


  useEffect(() => {
    postFetchGroupDrop({ DESCR: '' });
  }, []);

  useEffect(() => {
    postFetchGroupGrid({ IDUSUARIO: idUser }, 1);
  }, []);


  const options = {
    custom: true,
    totalSize: listGroupsGrid.length,
  };

  const columns = [
    {
      dataField: 'IDGRUPO',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'GRUPO',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'DTINI',
      text: 'De',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTINI && formataData(row?.DTINI.replaceAll('-', '').substring(0, 8));
      },
    },
    {
      dataField: 'DTFIM',
      text: 'Até',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTFIM && formataData(row?.DTFIM.replaceAll('-', '').substring(0, 8));
      },
    },
    {
      dataField: '',
      text: loading || type !== 'REMOVE' && 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <>
            {loading || type !== 'REMOVE' && (
              <ButtonTableAction
                tooltip={TextTooltip.REMOVE}
                icon={
                  <IconTrash color={Colors?.black} size={16} />
                }
                onClick={() => {
                  postRemoveGroup_x_User(row);
                }}
              />

            )}
          </>
        );
      },
    },
  ];

  const columnsConsult = [
    {
      dataField: 'IDGRUPO',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'GRUPO',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'DTINI',
      text: 'De',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTINI && formataData(row?.DTINI.replaceAll('-', '').substring(0, 8));
      },
    },
    {
      dataField: 'DTFIM',
      text: 'Até',
      sort: true,
      formatter: (_cell, row) => {
        return row?.DTFIM && formataData(row?.DTFIM.replaceAll('-', '').substring(0, 8));
      },
    },
  ];


  return (
    <>
      <TitleGroup>
        {'Grupos'.toUpperCase()}
      </TitleGroup>


      {loading && (
        <LoadingsComponent />
      )}

      {type !== 'CONSULT' && (

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            IDUSUARIO: idUser || '',
            IDGRUPO: '',
            DTINI: currentDateToInput(),
            DTFIM: null,
          }}
          validationSchema={
            Yup.object().shape({
              IDGRUPO: Yup.string().required('Obrigatório!'),
              IDUSUARIO: Yup.string().required('Obrigatório!'),
              DTINI: Yup.string().required('Obrigatório!'),
            })
          }
          onSubmit={(values, { resetForm }) => {
            postAddGroup_x_User(values, resetForm);
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              {!loading && (
                <>
                  <Row>

                    <Col xs={5}>
                      <DropdownInput
                        disabled={loading || type === 'REMOVE'}
                        name={'IDGRUPO'}
                        label={'Grupo'}
                        placeholder={'Selecione'}
                        defaultValue={values.IDGRUPO}
                        dropArray={listGroupsDrop}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.IDGRUPO}
                      />
                    </Col>


                    <Col xs={3}>
                      <TextInput
                        disabled={loading || type === 'REMOVE'}
                        name={'DTINI'}
                        label={'De'}
                        type={'date'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DTINI}
                      />
                    </Col>


                    <Col xs={3}>
                      <TextInput
                        disabled={loading || type === 'REMOVE'}
                        name={'DTFIM'}
                        label={'Até'}
                        type={'date'}
                        placeholder={'1999-01-01'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DTFIM}
                      />
                    </Col>


                    <Col xs={1}>
                      <IButton
                        disabled={loading || type === 'REMOVE'}
                        type={'submit'}
                        backgroundColor={Colors?.white}
                        iconLeft={<IconAddTag size={30} color={Colors?.buttonOk} />}
                      />
                    </Col>

                  </Row>


                  <Row>



                  </Row>
                </>
              )}

            </Form>

          )}
        </Formik>

      )}
      {
        !loading && showList && listGroupsGrid.length === 0 && (
          <EmptyContent
            title={emptyObject.title}
            description={emptyObject.description}
          />
        )
      }


      {
        !loading && showList && listGroupsGrid.length > 0 && (

          <PaginationProvider
            pagination={paginationFactory(options)}>
            {({
              paginationProps,
              paginationTableProps,
            }) => (
              <TableCuston
                noQuantity={totalPage === 1}
                data={listGroupsGrid}
                columns={type === 'CONSULT' ? columnsConsult : columns}
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
                actionPrevious={() => handlePagination(0)}
                actionNext={() => handlePagination(1)}
                currentPage={page}
                totalPage={totalPage}
                totalResults={totalResults}
              />
            )}
          </PaginationProvider>

        )
      }
    </>
  );
};



export default UserGroupSection;

