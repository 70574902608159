import React, { useEffect, useState } from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import * as Yup from 'yup';
import YesNoArray from '../../../../../common/arrays/YesNo';
import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  title: any;
  onHide?: any;
  type: any;
  valuesEdit?: any;
  valuesRemove?: any;
  fetchNow: any;
}



const SistemasCUDGIModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [listDepartment, setListDepartment] = useState([]);


  function postFetchDepartment(values) {
    setLoading(true);

    const url = 'gi/departamento/consultar';
    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          res.data[0].forEach((dep, index) => {
            setListDepartment(
              (list) => [...list, {
                key: index,
                label: dep.DESCR,
                value: dep.ID,
              }],
            );
          });
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchDepartment', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  function postSaveSystem(values) {
    setLoading(true);

    let url = '';

    if (props.type === 'NEW') {
      url = 'gi/sistema/salvar';
    }

    if (props.type === 'EDIT') {
      url = 'gi/sistema/alterar';
    }

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postSaveSystem', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postRemoveSystem(values) {
    setLoading(true);

    let url = '';

    url = 'gi/sistema/excluir';

    const payload = [values];


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: res.data,
          });

          props.fetchNow();
          props.onHide();
        }
        else {
          IToast({
            type: 'warning',
            message: res.data,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postRemoveSystem', JSON.stringify(err));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    postFetchDepartment({ DESCR: '' });
  }, []);



  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {props.title.toUpperCase()}
        </TitleGroup>



        {loading && (
          <LoadingsComponent />
        )}



        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            ID: props?.valuesEdit?.ID || props?.valuesRemove?.ID || '',
            DESCR: props.valuesEdit?.DESCR || props?.valuesRemove?.DESCR || '',
            IDDEP: props.valuesEdit?.IDDEP || props?.valuesRemove?.IDDEP || '',
            MANUAL: props?.valuesEdit?.MANUAL || props?.valuesRemove?.MANUAL || '',
            IMAGEM: props?.valuesEdit?.IMAGEM || props?.valuesRemove?.IMAGEM || '',
            ORDEM: props?.valuesEdit?.ORDEM || props?.valuesRemove?.ORDEM || '',
            COR: props?.valuesEdit?.COR || props?.valuesRemove?.COR || '',
            VERSAO: props?.valuesEdit?.VERSAO || props?.valuesRemove?.VERSAO || '',
            ROTA: props?.valuesEdit?.ROTA || props?.valuesRemove?.ROTA || '',
            DISP: props?.valuesEdit?.DISP || props?.valuesRemove?.DISP || '1',
          }}
          validationSchema={
            Yup.object().shape({
              ID: props.type !== 'NEW' ? Yup.string().required('Obrigatório') : Yup.string(),
              DESCR: Yup.string().required('Obrigatório'),
              IDDEP: Yup.string().required('Obrigatório'),
              COR: Yup.string().required('Obrigatório'),
              VERSAO: Yup.string().required('Obrigatório'),
              ROTA: Yup.string().required('Obrigatório'),
              DISP: Yup.string().required('Obrigatório'),
            })
          }
          onSubmit={(values) => {
            if (props?.type !== 'REMOVE') {
              postSaveSystem(values);
            }
            else if (props?.type === 'REMOVE') {
              postRemoveSystem(values);
            }
          }}>
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <Form onSubmit={handleSubmit}>

              <ModalBodyStyled>

                <TextInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'DESCR'}
                  label={'Novo Sistema'}
                  type={'text'}
                  placeholder={'Descrição'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.DESCR}
                />


                <DropdownInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'IDDEP'}
                  label={'Departamento'}
                  placeholder={'Todos'}
                  dropArray={listDepartment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.IDDEP || ''}
                />


                <TextInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'MANUAL'}
                  label={'Manual do Sistema'}
                  type={'text'}
                  placeholder={'Nome do Manual do novo sistema.'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.MANUAL || ''}
                />


                <TextInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'COR'}
                  label={'Cor do Sistema'}
                  type={'text'}
                  placeholder={'Cor do Sistema no card da Home Page.'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.COR || ''}
                />


                <TextInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'VERSAO'}
                  label={'Versão do Sistema'}
                  type={'text'}
                  placeholder={'Versão do Sistema.'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.VERSAO || ''}
                />


                <TextInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'ROTA'}
                  label={'Rota do Sistema'}
                  type={'text'}
                  placeholder={'Rota do Sistema.'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ROTA || ''}
                />


                <DropdownInput
                  disabled={loading || (props?.type === 'REMOVE')}
                  name={'DISP'}
                  label={'Disponível'}
                  dropArray={YesNoArray}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.DISP || ''}
                />

              </ModalBodyStyled>



              <ModalFooterStyled>

                <IButton
                  disabled={loading}
                  backgroundColor={Colors?.buttonCancel}
                  text={'Cancelar'}
                  onClick={() => props.onHide()}
                />


                <IButton
                  disabled={loading}
                  backgroundColor={Colors?.buttonOk}
                  text={props.type === 'REMOVE' ? 'Remover' : 'Salvar'}
                  type={'submit'}
                />

              </ModalFooterStyled>

            </Form>

          )}
        </Formik>

      </ModalContainerStyled>

    </Modal>

  );
};



export default SistemasCUDGIModal;
