import { Formik } from 'formik';
import React, {
  useState,
} from 'react';

import {
  Col,
  Modal, Row,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../common/constants';

import {
  FilterContainerForm, TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';
import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  onHide?: any;
  values: any;
}


const PEXRemoveCOModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);


  function postRemove(values) {
    setLoading(true);

    const url = '/pex/co/excluir';
    const payload = values;

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: resp.data,
          });
        }
      })

      .catch((error) => {
        console.error('postRemove', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao remover. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao salvar. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };

  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Remover Conta Operacional'.toUpperCase()}
        </TitleGroup>


        {loading && (
          <LoadingsComponent
            type={'SCREEN'}
            color={Colors?.tertiary}
          />
        )}


        {!loading && (
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={props.values}
            onSubmit={(values) => {
              postRemove(values);
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (<>

              <ModalBodyStyled>

                <FilterContainerForm auto onSubmit={handleSubmit}>

                  <Row>

                    <Col xs={4}>
                      <TextInput
                        disabled
                        type={'text'}
                        name={'CONTA_OPE'}
                        label={'Conta Operacional'}
                        placeholder={'Conta Operacional'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.CONTA_OPE} />
                    </Col>


                    <Col xs={8}>
                      <TextInput
                        disabled
                        type={'text'}
                        name={'DESCR_OPE'}
                        label={'Descr. Operacional'}
                        placeholder={'Descr. Operacional'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.DESCR_OPE} />
                    </Col>

                  </Row>


                  <Row>

                    <Col xs={4}>
                      <TextInput
                        disabled
                        type={'text'}
                        name={'CONTA_CON'}
                        label={'Conta Contábil'}
                        placeholder={'Conta Contábil'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.CONTA_CON} />
                    </Col>

                    <Col xs={8}>
                      <TextInput
                        disabled
                        type={'text'}
                        name={'DESCR_CON'}
                        label={'Descr. Contábil'}
                        placeholder={'Descr. Contábil'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DESCR_CON} />
                    </Col>

                  </Row>

                </FilterContainerForm>

              </ModalBodyStyled>


              <ModalFooterStyled>
                <IButton
                  backgroundColor={Colors?.gray}
                  text={'Fechar'}
                  onClick={props.onHide}
                />

                <IButton
                  type={'submit'}
                  backgroundColor={Colors?.primary}
                  text={'Remover'}
                  onClick={props.onHide}
                />
              </ModalFooterStyled>


            </>)}

          </Formik>
        )}


      </ModalContainerStyled>

    </Modal>

  );
};



export default PEXRemoveCOModal;
function setEmptyObject(arg0: { image: string; title: string; description: string; }) {
  throw new Error('Function not implemented.');
}

