import React,
{
  useState,
  useRef,
} from 'react';

import {
  Col,
  Form,
  Popover,
  Row,
} from 'react-bootstrap';


import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  Colors, Metrics,
} from '../../../../../../../common/constants';

import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import {
  FilterContainerForm,
} from '../../../../../../../common/styles/styled.layout';


import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';


import { IconClose, IconAddTag, IconSelect, IconHelpOutline } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';



import ApiWS from '../../../../../../../services/api.service';



import AnexosPcbModal from '../../../Modals/AnexosPcbModal';
import {
  ColTagsView,
  InputFileButtonContainer,
  PcbAnexoRowButtonFilterView,
  TagStyled,
} from '../tabs.styled';
import PcbAnexosModel from './PBC-tab_anexar.model';
import PcbAnexosValidationSchema from './PBC-tab_anexar.validation';


const Tab_AttachedSendScreen: React.FC = () => {
  const filesElement = useRef(null);
  const [loading, setLoading] = useState(false);

  const [screenListAttached, setScreenListAttached] = useState(false);


  const [pcBmList, setPcBmList] = useState([]);


  async function postUploadAttached(values: any): Promise<void> {
    setLoading(true);


    let pcBmToBack = '';

    if(pcBmList.length > 0) {
      pcBmList.forEach((pcBm) => {
        pcBmToBack += `${pcBm};`;
      });
    }


    if(pcBmList.length > 0 || values.input_contrato !== '') {
      const dataForm = new FormData();

      for (const file of filesElement.current.files) {
        dataForm.append('file', file);
      }


      dataForm.append('NUM', pcBmToBack);
      dataForm.append('FILIAL', values.input_filial);
      dataForm.append('TIPO', values.input_tipo);
      dataForm.append('CONTRATO', values.input_contrato);
      dataForm.append('PLANILHA', values.input_planilha);
      dataForm.append('REVISAO', values.input_revisao);
      dataForm.append('DESCR', values.input_descricao);


      ApiWS()
        .post('/suprimento/anexo/envio_anexos', dataForm)
        .then((resp) => {
          if (resp.status === 200) {
            if(resp.data === 'Anexado'){
              toast.success('Anexo enviado com sucesso!', {
                position: 'bottom-center',
                autoClose: Metrics.messageAutoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
            else if(resp.data === 'inexistente_duplicado'){
              toast.warning('PC/BM ou Contrato inválido ou já existe um arquivo com mesmo nome!', {
                position: 'bottom-center',
                autoClose: Metrics.messageAutoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
          else {
            toast.error('Erro ao enviar anexo, contate o suporte!', {
              position: 'bottom-center',
              autoClose: Metrics.messageAutoClose,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          console.error('fetchAll', JSON.stringify(error.response, null, 2));

          if (!error.response) {
            toast.error('Erro ao enviar anexo, contate o suporte!', {
              position: 'bottom-center',
              autoClose: Metrics.messageAutoClose,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          else {
            toast.error(`Erro ao enviar anexo, contate o suporte! ${error.response.status}`, {
              position: 'bottom-center',
              autoClose: Metrics.messageAutoClose,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  };


  function renderFieldDropArrayType() {
    return [
      {
        key: '0',
        label: 'Selecione...',
        value: 'SELECIONE',
      },
      {
        key: '2',
        label: 'PC',
        value: 'PC',
      },
      {
        key: '3',
        label: 'BM',
        value: 'BM',
      },
      {
        key: '4',
        label: 'MD',
        value: 'MD',
      },
      {
        key: '5',
        label: 'Contrato',
        value: 'CONTRATO',
      },
      {
        key: '6',
        label: 'Contrato de Parceria',
        value: 'CP',
      },
      {
        key: '7',
        label: 'Autorização de Entrega',
        value: 'AE',
      },
    ];
  };


  function renderModalAttached() {
    return (
      <AnexosPcbModal
        show={screenListAttached}
        onHide={() => {
          setScreenListAttached(false);
        }}
      />
    );
  };


  function insertPcBmToRender(value: any) {
    let repeat = 0;
    pcBmList.map((pcBm) => {
      if(pcBm === value) {
        repeat++;
      }
      return null;
    });
    value && repeat === 0 && setPcBmList((pcBmList) => [...pcBmList, value]);

    if(repeat > 0) {
      IToast({
        type: 'warning',
        message: 'PC/BM já inserido, verifique a lista',
      });
    }
  }


  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={PcbAnexosValidationSchema}
        initialValues={PcbAnexosModel}
        onSubmit={(values) => {
          let ocurredError = 0;

          if (values.input_tipo !== 'CONTRATO' && pcBmList.length === 0 && ocurredError === 0){
            IToast({
              type: 'warning',
              message: 'PC/BM não pode ser vazio! Em caso de dúvida passe o mouse sobre o botão de ajuda "?".',
              autoClose: Metrics.messageAutoCloseDouble,
            });

            ocurredError = ocurredError + 1;
          }

          console.log(values.input_fileAnexo);

          if(values.input_fileAnexo === null || values.input_fileAnexo === '' && ocurredError === 0) {
            IToast({
              type: 'warning',
              message: 'Anexo é obrigatório!',
            });

            ocurredError = ocurredError + 1;
          }



          if (ocurredError === 0){
            postUploadAttached(values);
          }
        }}>
        {({
          values,
          resetForm,
          handleSubmit,
          handleChange,
          handleBlur,
          setValues,
          setFieldValue,
        }) => (

          <FilterContainerForm>
            <Row>
              <Col>
                <TextInput
                  disabled={loading}
                  type={'text'}
                  name={'input_descricao'}
                  label={'Descrição'}
                  placeholder={'Descrição do Anexo'}
                  value={values.input_descricao || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}

                />


                <DropdownInput
                  disabled={loading}
                  name={'input_tipo'}
                  label={'Tipo'}
                  dropArray={renderFieldDropArrayType()}
                  onChange={handleChange}
                  value={values.input_tipo}
                />

              </Col>



              <Col>
                <TextInput
                  disabled={loading}
                  type={'text'}
                  name={'input_filial'}
                  label={'Filial'}
                  placeholder={'Filial'}
                  value={values.input_filial || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />


                <TextInput
                  disabled={!(values.input_tipo === 'CONTRATO') || loading}
                  type={'text'}
                  name={'input_contrato'}
                  label={'Contrato'}

                  placeholder={
                    values.input_tipo === 'CONTRATO'
                      ? 'Contrato'
                      : 'Somente para Tipo: "Contrato"'}

                  value={
                    values.input_tipo === 'CONTRATO'
                      ? values.input_contrato || ''
                      : ''
                  }

                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>



              <Col>
                <TextInput
                  disabled={!(values.input_tipo === 'CONTRATO') || loading}
                  type={'text'}
                  name={'input_planilha'}
                  label={'Planilha'}

                  placeholder={
                    values.input_tipo === 'CONTRATO'
                      ? 'Planilha'
                      : 'Somente para Tipo: "Contrato"'}

                  value={
                    values.input_tipo === 'CONTRATO'
                      ? values.input_planilha || ''
                      : ''
                  }

                  onChange={handleChange}
                  onBlur={handleBlur}
                />


                <TextInput
                  disabled={!(values.input_tipo === 'CONTRATO') || loading}
                  type={'text'}
                  name={'input_revisao'}
                  label={'Revisão'}

                  placeholder={
                    values.input_tipo === 'CONTRATO'
                      ? 'Revisão'
                      : 'Somente para Tipo: "Contrato"'}

                  value={
                    values.input_tipo === 'CONTRATO'
                      ? values.input_revisao || ''
                      : ''
                  }

                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>

            </Row>


            <Row>
              <Col xs={4}>
                <Row>
                  <Col xs={10}>

                    <TextInput
                      disableFeedBackValid
                      disabled={(values.input_tipo === 'CONTRATO') || loading}
                      type={'text'}
                      name={'input_pcbm'}
                      label={'PC/BM'}

                      placeholder={
                        values.input_tipo !== 'CONTRATO'
                          ? 'Digite o PC/BM aqui!'
                          : 'Somente para Tipo: "PC ou BM"'}

                      value={
                        values.input_tipo !== 'CONTRATO'
                          ? values.input_pcbm || ''
                          : ''
                      }

                      onChange={handleChange}
                      onBlur={handleBlur}
                      buttonIcon={
                        <IconAddTag color={
                          (values.input_tipo === 'CONTRATO') || loading ? 'gray' : 'green'
                        } size={36} />
                      }
                      buttonClick={() => {
                        if(values.input_tipo !== 'CONTRATO' && !loading) {
                          insertPcBmToRender(values.input_pcbm);
                          setFieldValue('input_pcbm', '');
                        };
                      }}
                      onKeyUp={(e) => {
                        if(e.key === 'Enter') {
                          insertPcBmToRender(values.input_pcbm);
                          setFieldValue('input_pcbm','');
                        }
                      }}
                    />
                  </Col>

                  <Col xs={1} style={{ padding: '1em' }}>
                    <ButtonTableAction
                      disabled={loading}
                      tooltip={TextTooltip.HELP}
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Header as="h3">Como adicionar os números dos Pedidos agora?</Popover.Header>
                          <Popover.Body>
                            1. Digite o PC/BM, no campo ao lado;
                            <br/>
                            2. Clique no <IconAddTag color={Colors?.accept}/> ou pressione [ Enter ], aí no seu teclado.
                            <br/>
                            3. Observe se apareceu uma tag
                            <TagStyled key={123}>
                              {123456}
                              <IconClose
                                size={22}
                                color={Colors?.graySecondary}
                              />
                            </TagStyled> ao lado direito, no campo de tag.
                            <hr/>
                            Ah! Caso desista de adicionar o anexo ao PC/BM, é só clicar no <IconClose color={Colors?.gray}/> que aparece na tag.
                          </Popover.Body>
                        </Popover>
                      }
                      icon={
                        <IconHelpOutline
                          color={Colors?.attention}
                          size={48}
                        />
                      }
                    />
                  </Col>
                </Row>

              </Col>



              {(pcBmList.length >= 0 ) && !loading &&
                <ColTagsView>

                  {pcBmList.length > 0 &&
                      pcBmList.map((pcBm, index) => (
                        <TagStyled key={index}>
                          {pcBm}
                          <IconClose
                            size={22}
                            color={Colors?.graySecondary}
                            onClick={() => {
                              setPcBmList(pcBmList.filter((num) => num !== pcBm));
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </TagStyled>

                      ))
                  }

                </ColTagsView>
              }

            </Row>

            <Row>
              <Col>
                <InputFileButtonContainer>
                  <Form.Group>
                    <Form.Label><IconSelect color={Colors?.primary}/>{' Clique em "Escolher arquivos" para selecionar os anexos.'}</Form.Label>
                    <Form.Control
                      disabled={
                        loading
                      }
                      name={'input_fileAnexo'}
                      type="file"
                      multiple
                      accept="
                        application/msword,
                        application/vnd.ms-Excel,
                        application/vnd.ms-PowerPoint,
                        text/plain,
                        application/pdf,
                        image/*"
                      ref={filesElement}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('input_fileAnexo', e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </InputFileButtonContainer>

              </Col>
              <Col></Col>
            </Row>


            <PcbAnexoRowButtonFilterView>
              <IButton
                disabled={loading}
                text={'Limpar'}
                backgroundColor={Colors?.gray}
                type={'reset'}
                onClick={() => {
                  window.location.reload();
                  resetForm();

                  setValues(PcbAnexosModel);
                }}
              />

              <IButton
                disabled={loading}
                text={'Enviar'}
                backgroundColor={Colors?.primary}
                type={'button'}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </PcbAnexoRowButtonFilterView>



          </FilterContainerForm>

        )}

      </Formik>

      {screenListAttached &&
            renderModalAttached()}
    </>
  );
};



export default Tab_AttachedSendScreen;
