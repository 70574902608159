import React, {
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import {
  Formik,
} from 'formik';

import {
  PcbPedidosArray,
} from '../../../../../common/arrays';

import { PcbTiposArray } from '../../../../../common/arrays/PcbPedidos';
import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';

import {
  TextTooltip,
} from '../../../../../common/constants/TextTooltips';

import ExcelConfigs from '../../../../../common/excel';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  SeparatorButton,
  TableRotateNextCss,
  TableTdButtonNextCss,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';


import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import SubtitleComponent from '../../../../../components/Contents/Subtitles';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconAttachment,
  IconDocument,
  IconDocuments,
  IconFolder,
  IconHistory,
  IconMoreOptions,
  IconPrinter,
  IconWriteDown,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';


import {
  PBC_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';

import {
  convertCurrencyBRL,
} from '../../../../../utils/CurrencyUtils';

import {
  formataData,
} from '../../../../../utils/DateUtils';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';

import {
  convertCNPJ,
} from '../../../../../utils/ListUtils';


import AnexosPcbModal from '../Modals/AnexosPcbModal';
import AnotacaoModal from '../Modals/AnotacaoPcbModal';
import CotacaoPcbModal from '../Modals/CotacaoPcbModal';
import ProdutoDadosComplementaresModal from '../Modals/DadosComplementaresModal';
import HistoricoFornecedorModal from '../Modals/HistoricoFornecedorModal';
import HistoricoItemModal from '../Modals/HistoricoItemModal';
import ItensPedidoModal from '../Modals/ItensPedidoModal';
import NotaFiscalModal from '../Modals/NotaFiscalModal';


import { IToast } from '../../../../../components/IToast/Toast';
import PcbPedidosModel from './PBC-pedidos.model';
import {
  RowPedidosLegends,
  StatusPedidosView,
} from './PBC-pedidos.styled';
import PcbPedidosValidationSchema from './PBC-pedidos.validation';



const PBC_PedidosScreen: React.FC = () => {
  const pasta = localStorage.getItem('@intranet/pasta');

  const [showListaPed, setShowListaPed] = useState(false);
  const [listaCarregando, setListaCarregando] = useState(false);
  const [listaPedidos, setListaPedidos] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem pedidos!',
    description: 'Não há nenhum pedido aqui',
  });

  const [valueFornecedor, setValueFornecedor] = useState(null);
  const [showModalFornecedor, setShowModalFornecedor] = useState(false);

  const [telaAnexo, setTelaAnexo] = useState(false);
  const [valueAnexo, setValueAnexo] = useState(null);

  const [valueHistorico, setValueHistorico] = useState(null);
  const [modalHistorico, setModalHistorico] = useState(false);

  const [valueNf, setValueNf] = useState(null);
  const [modalNf, setModalNf] = useState(false);

  const [valueItensPedidos, setValueItensPedidos] = useState(null);
  const [itensModalShow, setItensModalShow] = useState(false);

  const [valueCotacao, setValueCotacao] = useState(null);
  const [showCotacaoModal, setShowCotacaoModal] = useState(false);

  const [valueDadosComplemetares, setValueDadosComplemetares] = useState(null);
  const [dadosComplemetaresModalShow, setDadosComplemetaresModalShow] = useState(false);

  const [writeDownModalShow, setWriteDownModalShow] = useState(false);
  const [writeDownValues, setWriteDownValues] = useState([]);
  const [typeWriteDown, setTypeWriteDown] = useState(null);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  // ----------- AREA FUNCTION'S FETH DATA ---------------------------

  async function fetchRequests(values: any, page_iteration: any) {
    setListaPedidos([]);
    setValuesPayload(values);
    setPage(1);

    const objToBack = {
      'CC': values.input_centro_custo.toUpperCase(),
      'CNPJ': values.input_cnpj,
      'COMPRADOR': values.input_comprador,
      'DTINICIO': values.input_data_inicio.replaceAll('-', ''),
      'DTFIM': values.input_data_final.replaceAll('-', ''),
      'FILIAL': values.input_filial,
      'FORNECEDOR': values.input_fornecedor,
      'NUM': values.input_numero,
      'SM': values.input_sme,
      'SITUAC': values.input_situacao,
      'NOTA': values.input_nota,
      'TIPO': values.input_tipo,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_PBCRequests,
    };

    try {
      setListaCarregando(true);
      setShowListaPed(false);

      const url = '/suprimento/consulta/pedido';
      const response = await ApiWS().post(url, objToBack);

      setListaPedidos(response.data[0]);
      setTotalResults(response.data[1][0].TOTAL);
      setTotalPage(Math.ceil(response.data[1][0].TOTAL / 10));
    }
    catch (error) {
      console.error('fetchAll', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListaCarregando(false);
      setShowListaPed(true);
    }
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    fetchRequests(valuesPayload, page_var);
    setPage(page_var);
  };



  function postExport(values: any) {
    setListaCarregando(true);

    const url = 'suprimentos/pedidos/exporta';

    const payload = {
      'CC': values.input_centro_custo.toUpperCase(),
      'CNPJ': values.input_cnpj,
      'COMPRADOR': values.input_comprador,
      'DTINICIO': values.input_data_inicio.replaceAll('-', ''),
      'DTFIM': values.input_data_final.replaceAll('-', ''),
      'FILIAL': values.input_filial,
      'FORNECEDOR': values.input_fornecedor,
      'NUM': values.input_numero,
      'SM': values.input_sme,
      'SITUAC': values.input_situacao,
      'NOTA': values.input_nota,
      'TIPO': values.input_tipo,
    };


    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setListaCarregando(false);
        setShowListaPed(true);
      });
  };


  function postPrinter(values: any) {
    setListaCarregando(true);

    const url = `/suprimentos/consulta/pedido/impressao/${values.FILIAL}/${values.NUM}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setListaCarregando(false);
      });
  };

  function getPrinterMap(values: any) {
    setListaCarregando(true);

    const url = `/suprimentos/consulta/pedido/mapa/${values.FILIAL}/${values.NUM}/${values.TIPO}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setListaCarregando(false);
      });
  }

  function getPrinterBM(values: any) {
    setListaCarregando(true);

    const url = `/suprimentos/consulta/pedido/medicao/impressao/${values.FILIAL}/${values.NUM}/${values.TIPO}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setListaCarregando(false);
      });
  }

  // ----------- AREA FUNCTION'S RENDER BUTTONS TABLE ---------------

  function renderBtnPrintBM(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.ITEMS}
        onClick={() => {
          // window.open(`/${NameRoutes.PBC_ImpressaoBM}/${row.FILIAL}/${row.NUM}`, '_blank').focus();
          getPrinterBM(row);
        }}
        icon={
          <IconFolder
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalItems(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={row.TIPO === 'PC' ? TextTooltip.ITEMS : TextTooltip.NO_PC}
        onClick={() => {
          if (row.TIPO === 'PC') {
            setValueItensPedidos({
              'TIPO': row.TIPO,
              'FILIAL': row.FILIAL,
              'PEDIDO': row.NUM,
            });

            setItensModalShow(true);
          }
        }}
        icon={
          <IconDocuments
            color={row.TIPO === 'PC' ? Colors?.black : Colors?.gray}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalMapa(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.MAP}
        onClick={() => {
          getPrinterMap(row);
        }}
        icon={
          <IconFolder
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnImpPC(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.PRINTER_PC}
        onClick={() => {
          postPrinter(row);
        }}
        icon={
          <IconPrinter
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function returnSwitchItems(cell: any, row: any): JSX.Element {
    return (
      {
        'BM': renderBtnPrintBM(cell, row),
        'MD': renderBtnPrintBM(cell, row),
        'AE': renderBtnPrintBM(cell, row),
        'PC': renderBtnModalMapa(cell, row),
      }[row.TIPO]
    );
  };


  function returnSwitchImp(cell: any, row: any): JSX.Element {
    return (
      {
        'PC': renderBtnImpPC(cell, row),
      }[row.TIPO]
    );
  };


  function renderBtnModalSupplier(cell: any, row: any) {
    return (
      <TableTdButtonNextCss
        onClick={() => {
          setValueFornecedor({
            FILIAL: row.FILIAL,
            FORNEC: row.CODFORNEC,
            LOJA: row.LOJA,
            CC: row.OS,
          });
          setShowModalFornecedor(true);
        }}>
        {cell}
      </TableTdButtonNextCss>
    );
  };


  function renderBtnModalNF(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={
          row.NF === 'S'
            ? TextTooltip.NF_S
            : TextTooltip.LESS_NF
        }
        onClick={() => {
          if (row.NF === 'S') {
            setValueNf({
              'FILIAL': row.FILIAL,
              'NUM': row.NUM,
              'FORNEC': row.CODFORNEC,
              'LOJA': row.LOJA,
            });
            setModalNf(true);
          };
        }}
        icon={
          <IconDocument
            color={
              row.NF === 'S'
                ? Colors?.black
                : Colors?.gray
            }
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalHistory(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.HISTORIC}
        onClick={() => {
          setValueHistorico({
            'FILIAL': row.FILIAL,
            'CC': row.OS,
            'NUM': row.NUM,
            'TIPO': row.TIPO,
          });
          setModalHistorico(true);
        }}
        icon={
          <IconHistory
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalComplementaryData(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.COMPLEMENTARY_DATA}
        onClick={() => {
          setValueDadosComplemetares({
            'CONDPAG': row.CONDPAG,
            'FILIALENT1': row.FILIALENT1,
            'ENDENTR': row.ENDENTR,
            'DTENTR': row.DTENTR,
            'FRETE': row.FRETE,
            'OP': row.OP,
            'TIPO': row.TIPO,
            'OBS': row.OBS,
            'IMP': row.IMP,
            'VALORI': row.VALORI,
          });
          setDadosComplemetaresModalShow(true);
        }}
        icon={
          <IconMoreOptions
            color={Colors?.black}
            size={14}
          />
        }
      />
    );
  };


  function renderBtnModalAttached(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={
          row.ANEXO === 'S'
            ? TextTooltip.ATTACHMENT
            : TextTooltip.LESS_ATTACHMENT
        }
        onClick={() => {
          if (row.ANEXO === 'S') {
            setValueAnexo({
              'TIPO': row.TIPO,
              'NUM': row.NUM,
              'FILIAL': row.FILIAL,
            });
            setTelaAnexo(true);
          }
        }}
        icon={
          <IconAttachment
            color={
              row.ANEXO === 'S'
                ? Colors?.black
                : Colors?.gray
            }
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalWrite(cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.WRITE_DOWN}
        onClick={() => {
          setWriteDownValues([{
            'FILIAL': row.FILIAL,
            'NUM': row.NUM,
            'TIPO': row.TIPO,
          }]);
          setTypeWriteDown('read');
          setWriteDownModalShow(true);
        }}
        icon={
          <IconWriteDown
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  // ----------- AREA FUNTION'S RENDER MODAL ---------------------------

  function renderModalHistorySupplier() {
    return (
      <HistoricoFornecedorModal
        show={showModalFornecedor}
        supplier={valueFornecedor}
        onHide={() => {
          setShowModalFornecedor(false);
          setTimeout(() => {
            setValueFornecedor(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalRequestItems() {
    return (
      <ItensPedidoModal
        show={itensModalShow}
        values={valueItensPedidos}
        onHide={() => {
          setItensModalShow(false);
          setTimeout(() => {
            setValueItensPedidos(null);
          }, 300);
        }}
        cotacaoClick={(item) => {
          setValueCotacao(item);
          setShowCotacaoModal(true);
        }}
      />
    );
  }


  function renderModalQuotation() {
    return (
      <CotacaoPcbModal
        show={showCotacaoModal}
        values={valueCotacao}
        onHide={() => {
          setShowCotacaoModal(false);
          setTimeout(() => {
            setValueCotacao(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalNF() {
    return (
      <NotaFiscalModal
        show={modalNf}
        values={valueNf}
        onHide={() => {
          setModalNf(false);
          setTimeout(() => {
            setValueNf(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalHistoryItem() {
    return (
      <HistoricoItemModal
        show={modalHistorico}
        values={valueHistorico}
        onHide={() => {
          setModalHistorico(false);
          setTimeout(() => {
            setValueHistorico(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalComplementaryData() {
    return (
      <ProdutoDadosComplementaresModal
        show={dadosComplemetaresModalShow}
        dadosComp={valueDadosComplemetares}
        onHide={() => {
          setDadosComplemetaresModalShow(false);
          setTimeout(() => {
            setValueDadosComplemetares(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalAttached() {
    return (
      <AnexosPcbModal
        show={telaAnexo}
        values={valueAnexo}
        onHide={() => {
          setTelaAnexo(false);
          setTimeout(() => {
            setValueAnexo(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalWriteDownShow() {
    return (
      <AnotacaoModal
        show={writeDownModalShow}
        type={typeWriteDown}
        pedidos={writeDownValues}
        onHide={() => {
          setWriteDownModalShow(false);
          setTimeout(() => {
            setWriteDownValues(null);
          }, 300);
        }}
      />
    );
  };


  const columns = [
    {
      dataField: 'FILIAL',
      text: 'Filial',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'NUM',
      text: 'PC/BM',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'DTEMISSAO',
      text: 'Data',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
      formatter: (cell) => {
        return formataData(cell);
      },
    },
    {
      dataField: 'COMPRADOR',
      text: 'Comprador/Analista',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
    },
    {
      dataField: 'CNPJ',
      text: 'CNPJ',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCNPJ(cell) ? convertCNPJ(cell) : '--';
      },
      csvFormatter: (cell): string => {
        return convertCNPJ(cell) ? convertCNPJ(cell) : '--';
      },
    },
    {
      dataField: 'FORNECEDOR',
      text: 'Fornecedor',
      sort: true,
      formatter: (cell, row): JSX.Element => {
        return renderBtnModalSupplier(cell, row);
      },
      csvFormatter: (cell) => {
        return cell;
      },
    },
    {
      dataField: 'VALOR',
      text: 'Valor',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCurrencyBRL(cell);
      },
    },
    {
      dataField: 'VLATEND',
      text: 'Valor Atend.',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCurrencyBRL(cell);
      },
    },
    {
      dataField: 'SALDO',
      text: 'Saldo',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCurrencyBRL(cell);
      },
    },
    {
      dataField: 'OS',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'SIT',
      text: 'Situação',
      sort: true,
      headerStyle: () => {
        return { width: '4%' };
      },
      formatter: (cell): JSX.Element => {
        const valuePcbSit = PcbPedidosArray.map((pcb) => (
          pcb.key.toString() === cell?.toString() && (
            <SubtitleComponent
              noSubtitle={true}
              backgroundColor={pcb.color}
            />
          )
        ));

        return (
          <StatusPedidosView>
            {valuePcbSit}
          </StatusPedidosView>
        );
      },
      csvText: 'Situacao',
      csvFormatter: (cell): string => {
        const valuePcbStringSit = PcbPedidosArray.find((pcb) => {
          return pcb.key.toString() === cell?.toString();
        });

        return valuePcbStringSit ? valuePcbStringSit.label.toString() : 'Indefinida';
      },
    },
    {
      dataField: '',
      text: 'Itens',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return renderBtnModalItems(cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Mapa',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return returnSwitchItems(cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Imp. PC',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return returnSwitchImp(cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'NF',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return renderBtnModalNF(cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Hist.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return renderBtnModalHistory(cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Comp.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return renderBtnModalComplementaryData(cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Anex.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return renderBtnModalAttached(cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Anot.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row) => {
        return renderBtnModalWrite(cell, row);
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listaPedidos.length,
  };



  return (

    <ToolkitProvider
      keyField={'id'}
      data={listaPedidos}
      columns={columns}
      exportCSV={{
        blobType: ExcelConfigs.blobType,
        fileName: ExcelConfigs.PCB_Pedidos,
        onlyExportFiltered: ExcelConfigs.onlyExportFiltered,
        exportAll: ExcelConfigs.exportAll,
        noAutoBOM: false,
        separator: ';',
      }}>
      {(propsTkProvider) => (

        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={2}
              navArray={PBC_NavRoutes}
              title={'Suprimentos'}
            />
          </NavHeader>


          <Content>
            <ContentStyled fluid>

              <ContainerView>

                <SectionMargin>

                  <TitleGroup>
                    {'Pedidos'.toUpperCase()}
                  </TitleGroup>


                  {listaCarregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  <SectionMargin>
                    <Formik
                      enableReinitialize
                      validateOnMount
                      validationSchema={PcbPedidosValidationSchema}
                      initialValues={PcbPedidosModel}
                      onSubmit={(values) => {
                        fetchRequests(values, 1);
                      }}>
                      {({
                        resetForm,
                        isValid,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setValues,
                        values,
                      }) => (

                        <FilterContainerForm auto onSubmit={handleSubmit}>

                          <Row>
                            <Col>
                              <DropdownInput
                                disabled={listaCarregando}
                                name={'input_situacao'}
                                label={'Situação'}
                                defaultValue={'Todos'.toLocaleUpperCase()}
                                dropArray={[
                                  {
                                    key: 'All',
                                    label: 'Todos',
                                    value: 'Todos'.toLocaleUpperCase(),
                                  },
                                  ...PcbPedidosArray,
                                ]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_situacao || ''}
                              />


                              <TextInput
                                disabled={listaCarregando}
                                type={'date'}
                                name={'input_data_inicio'}
                                label={'Data Início'}
                                placeholder={'Data'}
                                min={0}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_data_inicio || ''}
                              />


                              <TextInput
                                disabled={listaCarregando}
                                type={'text'}
                                name={'input_fornecedor'}
                                label={'Fornecedor'}
                                placeholder={'Fornecedor'}
                                min={0}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_fornecedor || ''}
                              />


                              <TextInput
                                disabled={listaCarregando}
                                type={'text'}
                                name={'input_sme'}
                                label={'SME'}
                                placeholder={'Número'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_sme || ''}
                              />
                            </Col>



                            <Col>
                              <TextInput
                                disabled={listaCarregando}
                                type={'text'}
                                name={'input_filial'}
                                label={'Filial'}
                                placeholder={'Filial'}
                                min={0}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_filial || ''}
                              />


                              <TextInput
                                disabled={listaCarregando}
                                type={'date'}
                                name={'input_data_final'}
                                label={'Data Final'}
                                max={Date.now()}
                                placeholder={'Data'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_data_final || ''}
                              />


                              <TextInput
                                disabled={listaCarregando}
                                type={'text'}
                                name={'input_comprador'}
                                label={'Comprador'}
                                placeholder={'Comprador'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_comprador || ''}
                              />


                              <TextInput
                                disabled={listaCarregando}
                                type={'text'}
                                name={'input_nota'}
                                label={'NF'}
                                placeholder={'NF'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_nota || ''}
                              />
                            </Col>



                            <Col>
                              <TextInput
                                disabled={listaCarregando}
                                type={'text'}
                                name={'input_centro_custo'}
                                label={'Centro de Custo'}
                                placeholder={'Número'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_centro_custo || ''}
                              />


                              <TextInput
                                disabled={listaCarregando}
                                type={'text'}
                                name={'input_numero'}
                                label={'Número'}
                                placeholder={'Número'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_numero || ''}
                              />


                              <TextInput
                                disabled={listaCarregando}
                                type={'text'}
                                name={'input_cnpj'}
                                label={'CNPJ'}
                                placeholder={'CNPJ'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_cnpj || ''}
                              />

                              <DropdownInput
                                disabled={listaCarregando}
                                name={'input_tipo'}
                                label={'Tipo'}
                                defaultValue={'Todos'.toLocaleUpperCase()}
                                dropArray={[
                                  {
                                    key: 'All',
                                    label: 'Todos',
                                    value: 'Todos'.toLocaleUpperCase(),
                                  },
                                  ...PcbTiposArray,
                                ]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.input_tipo || ''}
                              />
                            </Col>



                            <RowPedidosLegends>
                              <SubtitleComponent
                                array={PcbPedidosArray}
                              />
                            </RowPedidosLegends>
                          </Row>



                          <RowButtonFiltersView>
                            {listaPedidos.length > 0 && (
                              <IButton
                                type={'button'}
                                disabled={!isValid || listaCarregando}
                                backgroundColor={Colors?.excel}
                                text={'Exportar'}
                                onClick={() => {
                                  postExport(values);
                                }}
                              />
                            )}


                            {listaPedidos.length > 0 && <SeparatorButton />}


                            <IButton
                              disabled={listaCarregando}
                              text={'Limpar'}
                              backgroundColor={Colors?.gray}
                              type={'reset'}
                              onClick={() => {
                                resetForm();

                                setValues(PcbPedidosModel);
                              }}
                            />


                            <IButton
                              disabled={!isValid || listaCarregando}
                              text={'Filtrar'}
                              backgroundColor={Colors?.primary}
                              type={'submit'}
                            />
                          </RowButtonFiltersView>

                        </FilterContainerForm>

                      )}
                    </Formik>
                  </SectionMargin>



                  <ContainerView>

                    {!listaCarregando && showListaPed && listaPedidos.length === 0 && (
                      <EmptyContent
                        image={emptyObject.image}
                        title={emptyObject.title}
                        description={emptyObject.description}
                      />
                    )}



                    {!listaCarregando && showListaPed && listaPedidos.length > 0 && (
                      <PaginationProvider
                        pagination={paginationFactory(options)}>
                        {({
                          paginationProps,
                          paginationTableProps,
                        }) => (
                          <TableCuston
                            noQuantity={totalPage === 1}
                            data={listaPedidos}
                            columns={columns}
                            baseProps={propsTkProvider.baseProps}
                            paginationProps={paginationProps}
                            paginationTableProps={paginationTableProps}
                            actionPrevious={() => handlePagination(0)}
                            actionNext={() => handlePagination(1)}
                            currentPage={page}
                            totalPage={totalPage}
                            totalResults={totalResults}
                          />
                        )}
                      </PaginationProvider>
                    )}

                  </ContainerView>


                </SectionMargin>

              </ContainerView>

            </ContentStyled>
          </Content>


          {valueFornecedor &&
            renderModalHistorySupplier()}



          {valueItensPedidos &&
            renderModalRequestItems()}



          {valueCotacao &&
            renderModalQuotation()}



          {valueNf &&
            renderModalNF()}


          {valueHistorico &&
            renderModalHistoryItem()}


          {valueDadosComplemetares &&
            renderModalComplementaryData()}


          {valueAnexo &&
            renderModalAttached()}



          {writeDownModalShow && writeDownValues && (
            renderModalWriteDownShow())}

        </Screen>

      )}
    </ToolkitProvider>

  );
};



export default PBC_PedidosScreen;
