import styled from 'styled-components';



export const AddRdButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin: 10px 0;
  padding-bottom: 30px;
`;

export const SoldadorFieldHelp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${40}px;
  margin: 10px 0;
`;

export const SoldadorFieldTextHelp = styled.p`
  margin: 0;
  color: black;
  font-size: 1rem;
`;
