import { currentCompleteYear } from '../../../../../utils/DateUtils';

const IAtualizarMesRefModel = {

  MES: '',

  ANO: String(currentCompleteYear()),

};



export default IAtualizarMesRefModel;
