const RdAtendimentoModel = {
  OS: 'TODOS',
  NUM: '',
  FUNCAO: '',
  SITUAC: 'Pedente',
  DTABERTINI: '',
  DTABERTFIM: '',
  DTNECESINI: '',
  DTNECESFIM: '',
};


export default RdAtendimentoModel;
