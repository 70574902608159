import React,
{
  useState,
  useEffect,
} from 'react';

import {
  toast,
} from 'react-toastify';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  ContainerView,
  Content,
  ContentStyled,
  Screen,
  SectionMargin,
  TableAccordionStyled,
  TitleGroup,
  TableViewButton,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconInfoSquare,
  IconPrinter,
  IconUserCheck,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';

import NameRoutes from '../../../../../navigation/name';

import {
  RD_NavRoutes,
} from '../../../../../navigation/navs/HumanResources';

import ApiWS from '../../../../../services/api.service';

import {
  formataData, formataParaInputDate,
} from '../../../../../utils/DateUtils';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';

import RDApproversModal from '../Modals/ApproversModal';

import {
  FilterRpPendentContainer, TextAreaContainer,
} from './RD-aprovacao.styled';
import './styled.css';



const RD_AprovacaoScreen: React.FC = () => {
  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
  });

  const disQtdVaga = [];
  const disDtNeces = [];

  const [carregando, setCarregando] = useState(false);
  const btnsHabilitados =
    <div>
      <IButton
        backgroundColor={Colors?.buttonOk}
        text={'Aprovar'}
        onClick={() => {
          onclick_aprovar();
        }}
      />

      <IButton
        backgroundColor={Colors?.buttonCancel}
        text={'Reprovar'}
        onClick={() => {
          onclick_reprovar();
        }}
      />
    </div>;

  const btnsDesabilitados =
    <div>
      <IButton
        disabled={true}
        backgroundColor={Colors?.buttonOk}
        text={'Aprovar'}
        onClick={() => {
          onclick_aprovar();
        }}
      />
      <IButton
        disabled={true}
        backgroundColor={Colors?.buttonCancel}
        text={'Reprovar'}
        onClick={() => {
          onclick_reprovar();
        }}
      />
    </div>;


  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem RD\'s pendentes!',
    description: 'Não há nenhuma RD pendente',
  });


  const [telaAprovadores, setTelaAprovadores] = useState(false);
  const [valueAprovadores, setValueAprovadores] = useState(null);

  const [listRD, setListRD] = useState([]);
  const [listAprovador, setListAprovador] = useState([]);

  const [input_qtd, setInput_qtd] = useState('');
  const [input_dtNeces, setInput_dtNeces] = useState('');
  const [textArea_obsRh, setTextArea_obsRh] = useState('');


  const findPendentes = async () => {
    setCarregando(true);
    ApiWS()
      .get('/rd/aprovacao/consulta/')
      .then((resp) => {
        setListRD(resp.data);
        for (let i = 1; i < resp.data.length+1; i++) {
          disQtdVaga.push(false);
          disDtNeces.push(false);
        }
      })
      .catch((error) => {
        console.error('findPendentes', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          setEmptyObject({
            image: Images.other500,
            title: 'Erro!',
            description: 'Sem conexão com o servidor',
          });
        }
        else {
          setEmptyObject(returnErrorObject(error.response.status));
        }
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  const addListAprovador = (rd:any) => {
    setListAprovador((listAprovador) => [...listAprovador, rd]);
    document.querySelector(`#input-qtd-${rd.QS_VAGA}`).setAttribute('disabled', 'disabled');
    document.querySelector(`#input-dtnec-${rd.QS_VAGA}`).setAttribute('disabled', 'disabled');
    document.querySelector(`#textarea-obsrh-${rd.QS_VAGA}`).setAttribute('disabled', 'disabled');
  };


  const removeListAprovador = (rd:any) => {
    listAprovador.forEach((reqPess, index) => {
      (reqPess.QS_VAGA === rd.QS_VAGA)
        && listAprovador.splice(index, 1);
    });
    document.querySelector(`#input-qtd-${rd.QS_VAGA}`).removeAttribute('disabled');
    document.querySelector(`#input-dtnec-${rd.QS_VAGA}`).removeAttribute('disabled');
    document.querySelector(`#textarea-obsrh-${rd.QS_VAGA}`).removeAttribute('disabled');
  };


  const onclick_aprovar = () => {
    const newListToBack = [];
    listAprovador.forEach((requisicao) => {
      requisicao.NRVAGAOLD = requisicao.NRVAGA;
      requisicao.DTNECESOLD = requisicao.DTNECES;

      newListToBack.push({
        'QS_VAGA': requisicao.QS_VAGA,
        'NRVAGA': input_qtd !== '' ? parseInt(input_qtd) : requisicao.NRVAGAOLD,
        'NRVAGAOLD': requisicao.NRVAGAOLD,
        'DTNECES': input_dtNeces !== '' ? input_dtNeces : requisicao.DTNECESOLD,
        'DTNECESOLD': requisicao.DTNECESOLD,
        'APROVADOR': requisicao.APROVADOR,
        'QS_OBSERVR': requisicao.QS_OBSERVR,
        'statusAprovador': requisicao.statusAprovador,
      });
    });



    setCarregando(true);
    ApiWS()
      .post('/rd/aprovacao/aprovar', newListToBack)
      .then((resp) => {
        if(resp.status === 200){
          toast.success('RD Aprovada com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error('aprovacaoRD', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          setEmptyObject({
            image: Images.other500,
            title: 'Erro!',
            description: 'Sem conexão com o servidor',
          });
        }
        else {
          setEmptyObject(returnErrorObject(error.response.status));
        }
      })
      .finally(() => {
        setListAprovador([]);
        findPendentes();
        setCarregando(false);
      });
  };

  const onclick_reprovar = async() => {
    const newListToBack = [];
    listAprovador.forEach((requisicao) => {
      newListToBack.push({
        'QS_VAGA': requisicao.QS_VAGA,
        'APROVADOR': requisicao.APROVADOR,
        'QS_OBSERVR': requisicao.QS_OBSERVR,
        'statusAprovador': requisicao.statusAprovador,
      });
    });

    // console.log(newListToBack);

    setCarregando(true);
    ApiWS()
      .post('/rd/aprovacao/reprovar', newListToBack)
      .then((resp) => {
        if(resp.status === 200){
          toast.success('RD Reprovada com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error('reprovacaoRD', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          setEmptyObject({
            image: Images.other500,
            title: 'Erro!',
            description: 'Sem conexão com o servidor',
          });
        }
        else {
          setEmptyObject(returnErrorObject(error.response.status));
        }
      })
      .finally(() => {
        setListAprovador([]);
        findPendentes();
        setCarregando(false);
      });
  };


  async function findAprovadores(os: any) {
    let listaAprovadores = [];

    try {
      const url = '/rd/consulta/pendentes/aprovadores';
      const response = await ApiWS().post(url, os);

      listaAprovadores = response.data;
    }
    catch (error) {
      console.error('handleModalAprovadores', JSON.stringify(error.response, null, 2));
    }

    return listaAprovadores;
  };

  const retornaAprovador = (status: any) => {
    switch (status) {
      case 'RRO':
        return 'APROVADOROS';
      case 'FINAL':
        return 'APROVADORFINAL';
      case 'RH':
        return 'APROVADORRH';
      case 'RROFINAL ':
        return 'APROVADOROSFINAL';
      case 'CA':
        return 'APROVADORCA';
      default:
        return 'ERRO::: SEM STATUS OU DIFERENTE';
    }
  };


  useEffect(() => {
    findPendentes();
  }, []);


  return (
    <Screen>

      {/* {JSON.stringify(listRD)} */}

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={RD_NavRoutes}
          title={'RD'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          {carregando && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!carregando && listRD.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}



          {!carregando && listRD.length > 0 && (
            <ContainerView>

              <SectionMargin>
                <TitleGroup>
                  {'RD\'s Pendentes de aprovação'.toUpperCase()}
                </TitleGroup>


                <FilterRpPendentContainer>

                  {listAprovador.length > 0
                    ? (btnsHabilitados)
                    : (btnsDesabilitados)
                  }

                </FilterRpPendentContainer>



                <TableAccordionStyled>
                  <thead>
                    <tr>
                      <th></th>
                      <th>OS</th>
                      <th>RD</th>
                      <th>Solicitante</th>
                      <th>Função</th>
                      <th>Desd. Função</th>
                      <th>Qtd.</th>
                      <th>Dt. Abertura</th>
                      <th>Dt. Prev.</th>
                      <th>Dt. Neces.</th>
                      <th>Obs. RH</th>
                      <th>Aprovação</th>
                      <th colSpan={4}>Ações</th>
                    </tr>
                  </thead>


                  <tbody>
                    {listRD.map((rd, index) => (
                      <>
                        <tr key={index}>
                          <td className="actions">
                            <TableViewButton>
                              <input
                                type='checkbox'
                                name={`chkRp${rd.QS_VAGA}`}
                                onChange={(e) => {
                                  const requisicao = {
                                    'QS_VAGA': rd.QS_VAGA,
                                    'NRVAGA': rd.QS_NRVAGA,
                                    'NRVAGAOLD':rd.QS_NRVAGA,
                                    'DTNECES': rd.QS_DTNECES,
                                    'DTNECESOLD': rd.QS_DTNECES,
                                    'QS_OBSERVR': textArea_obsRh,
                                    'statusAprovador': retornaAprovador(rd.TIPO),
                                  };
                                  e.target.checked ? addListAprovador(requisicao) : removeListAprovador(requisicao);
                                }}
                              />
                            </TableViewButton>
                          </td>


                          {/* OS */} <td>{rd.QS_CC}</td>
                          {/* RD */} <td>{rd.QS_VAGA}</td>
                          {/* Solicitante */} <td>{rd.QS_SOLICIT}</td>
                          {/* Função */} <td>{rd.QS_DESCARG}</td>
                          {/* Desd. Função */} <td>{rd.QS_DESCRIC}</td>
                          {/* Qtd. */} <input
                            style={{ marginTop: 2 }}
                            id={`input-qtd-${rd.QS_VAGA}`}
                            type="number"
                            min={1}
                            max={rd.QS_NRVAGA !== undefined ? parseInt(rd.QS_NRVAGA) : 0}
                            defaultValue={rd.QS_NRVAGA}
                            onChange={(e) => {
                              if ((parseInt(e.target.value) > parseInt(rd.QS_NRVAGA) || parseInt(e.target.value) <= 0) && e.target.value !== undefined) {
                                setMessageValue({
                                  head: 'Erro!',
                                  orient: 'A Quantidade para aprovar não pode ser maior que a solicitada ou menor que 1.',
                                });
                                setMessageModal(true);
                                setInput_qtd(rd.QS_NRVAGA);
                                e.target.value = rd.QS_NRVAGA;
                              }
                              else {
                                setInput_qtd(e.target.value);
                              }
                            }}
                            disabled={false}
                          />

                          {/* Dt. Abertura */} <td>{formataData(rd.QS_DTABERT)}</td>
                          {/* Dt. Prev. */} <td>{formataData(rd.QS_DTPREVI)}</td>
                          {/* Dt. Nesc. */} <input
                            id={`input-dtnec-${rd.QS_VAGA}`}
                            type="date"
                            min={formataParaInputDate(rd.QS_DTPREVI)}
                            defaultValue={formataParaInputDate(rd.QS_DTNECES)}
                            disabled={disDtNeces[index]}
                            onBlur={(e) => {
                              const date_dtNeces = new Date(e.target.value);
                              const date_datPrev = new Date(formataParaInputDate(rd.QS_DTPREVI));

                              if ( date_dtNeces >= date_datPrev ){
                                setInput_dtNeces(e.target.value.replaceAll('-', ''));
                              }
                              else {
                                setInput_dtNeces(rd.QS_DTNECES);
                                e.target.value = formataParaInputDate(rd.QS_DTNECES);
                                setMessageValue({
                                  head: 'Erro!',
                                  orient: 'A Dt. Neces. deve ser maior ou igual a Dr. Prev.',
                                });
                                setMessageModal(true);
                              }
                            }}
                          />

                          {/* Obs. RH */} <td>
                            <TextAreaContainer
                              id={`textarea-obsrh-${rd.QS_VAGA}`}
                              rows={1}
                              defaultValue={rd.QS_OBSERVR && rd.QS_OBSERVR}
                              onChange={(e) => {
                                setTextArea_obsRh(e.target.value);
                              }}
                            />
                          </td>

                          {/* Aprovação */} <td>
                            {{
                              'RRO': 'Aprovação OS',
                              'FINAL': 'Aprovação Final',
                              'RH': 'Aprovação RH',
                              'RROFINAL': 'Aprovação OS e Final',
                              'CA': 'Aprovação CA',
                            }[rd.TIPO.replaceAll(' ', '')]}
                          </td>


                          {/* Botão Observacao */}
                          <td className={'actions'}>
                            <ButtonTableAction
                              tooltip={
                                rd.QS_OBSERVA
                                && rd.QS_OBSERVA !== null
                                && rd.QS_OBSERVA !== ''
                                && rd.QS_OBSERVA !== undefined
                                  ? rd.QS_OBSERVA
                                  : TextTooltip.NO_OBSERVATION
                              }
                              icon={
                                <IconInfoSquare
                                  color={
                                    rd.QS_OBSERVA
                                    && rd.QS_OBSERVA !== null
                                    && rd.QS_OBSERVA !== ''
                                    && rd.QS_OBSERVA !== undefined
                                      ? Colors?.black
                                      : Colors?.gray
                                  }
                                  size={16}
                                />
                              }
                            />
                          </td>


                          {/* Botão Impressao */}
                          <td className="actions">
                            <ButtonTableAction
                              tooltip={TextTooltip.PRINTER}
                              onClick={() => {
                                window.open(`/${NameRoutes.RD_Impressao}/${rd.QS_VAGA}`, '_blank').focus();
                              }}
                              icon={
                                <IconPrinter
                                  color={Colors?.black}
                                  size={16}
                                />
                              }
                            />
                          </td>


                          {/* Botão Modal Aprovadores */}
                          <td>
                            {
                              <ButtonTableAction
                                tooltip={TextTooltip.APPROVALS}
                                onClick={async () => {
                                  const resultAproveds = await findAprovadores({ cc: rd.QS_CC });
                                  setValueAprovadores({
                                    CC: rd.QS_CC,
                                    RD: rd.QS_VAGA,
                                    APROVADORES: resultAproveds,
                                  });
                                  setTelaAprovadores(true);
                                }}
                                icon={
                                  <IconUserCheck
                                    color={Colors?.black}
                                    size={16}
                                  />
                                }
                              />
                            }
                          </td>

                        </tr>
                      </>
                    ))}
                  </tbody>

                </TableAccordionStyled>

              </SectionMargin>

            </ContainerView>
          )}

        </ContentStyled>
      </Content>


      {valueAprovadores && (
        <RDApproversModal
          show={telaAprovadores}
          numRD={valueAprovadores.RD}
          aprovadores={valueAprovadores.APROVADORES}
          onHide={() => {
            setTelaAprovadores(false);
            setTimeout(() => {
              setValueAprovadores(null);
            }, 300);
          }}
        />
      )}

      <ConfirmationModal
        show={messageModal}
        head={messageValue.head}
        orient={messageValue.orient}
        negative={() => {
          setMessageModal(false);
        }}
      />

    </Screen>

  );
};



export default RD_AprovacaoScreen;
