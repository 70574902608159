import { Formik } from 'formik';
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Modal, Row,
} from 'react-bootstrap';

import {
  Colors, Images,
} from '../../../../../common/constants';

import {
  FilterContainerForm, TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
  ModalTableSubTitleText,
  ModalTableTitleText,
} from '../../../../../components/Modals/styled';
import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';
import { convertSexo, convertCPF } from '../../../../../utils/ListUtils';
import { validateString } from '../../../../../utils/StringUtils';
import { AdmModalDetailHeader, AdmModalDetailInfoContent, ItemDetailUserImage, AdmModalDetailBody, ItemContent, ItemLabel, ItemText, ItemContentFull, SwitchCheckStyled } from '../../../Supplies/PSTOCK/PSTOCK_Administracao/Modals/styled';



interface IProps {
  show: boolean;
  onHide?: any;
  values: any;
}


const PEXDetalhesModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    NUM: '00001',
    CONTA: '55565632',
    DESCRICAO: 'PEX TESTE',
    VALOR: 'R$99999999999999999,00',
    ORDEM: '89756',
  });


  const Itens = (values) => [
    { label: 'Num.', value: values?.NUM },
    { label: 'Conta', value: values?.CONTA },
    { label: 'Descrição', value: values?.DESCRICAO },
    { label: 'Valor', value: values?.VALOR },
    { label: 'Ordem', value: values?.ORDEM },
  ];

  function postFetch() {
    setLoading(true);

    const url = '/pex/consulta/detalhes';
    const payload = props.values;

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          setData(resp.data);
        }
      })

      .catch((error) => {
        console.error('postFetch', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    postFetch();
  }, []);

  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      {loading && (
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.tertiary}
        />
      )}

      {!loading && (
        <ModalContainerStyled>

          <TitleGroup>
            {'Detalhes do Pex'.toUpperCase()}
          </TitleGroup>


          <ModalBodyStyled>

            {/* <AdmModalDetailHeader>

              <AdmModalDetailInfoContent>

                <ModalTableTitleText>
                </ModalTableTitleText>


                <ModalTableSubTitleText>
                </ModalTableSubTitleText>

              </AdmModalDetailInfoContent>

            </AdmModalDetailHeader> */}


            <AdmModalDetailBody>

              {Itens(data).map((item) => (

                <ItemContent>

                  <ItemLabel>
                    {item.label}
                  </ItemLabel>


                  <ItemText>
                    {item.value}
                  </ItemText>

                </ItemContent>

              ))}

            </AdmModalDetailBody>

          </ModalBodyStyled>



          <ModalFooterStyled>

            <IButton
              backgroundColor={Colors?.primary}
              text={'Fechar'}
              onClick={props.onHide}
            />

          </ModalFooterStyled>

        </ModalContainerStyled>
      )}

    </Modal>

  );
};



export default PEXDetalhesModal;
function setEmptyObject(arg0: { image: string; title: string; description: string; }) {
  throw new Error('Function not implemented.');
}

