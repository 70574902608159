import { Formik } from 'formik';
import React, {
  useState,
  useRef,
} from 'react';

import {
  Col,
  Modal, Row,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../common/constants';

import {
  FilterContainerForm, ObsSubTitleGroup, TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';
import TextInput from '../../../../../components/TextFields/Input';
import ApiWS from '../../../../../services/api.service';



interface IProps {
  show: boolean;
  onHide?: any;
  values: any;
  retorno: any;
  dataForm: any;
}


const PEXImportarCRModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const filesElement = useRef(null);


  async function postImport(): Promise<void> {
    setLoading(true);
    const dataForm = new FormData();
    for (const file of filesElement.current.files) {
      dataForm.append('file', file);
      dataForm.append('TIPO', props.values.TIPO);
      dataForm.append('ANO', props.values.ANO);
      dataForm.append('MES', props.values.MES);
      dataForm.append('CR', props.values.CR);
    };

    ApiWS()
      .post('/pex/importar/', dataForm)
      .then((resp) => {
        if(resp.status === 200) {
          props.retorno(resp.data);
          props.dataForm(dataForm);

          if(props.retorno) {
            props.onHide();
          }
        }
        else {
          IToast({
            type: 'warning',
            message: resp.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Atualize a página ou contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (

    <Modal
      {...props}
      show={props.show}
      size={'lg'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Importar CR'.toUpperCase()}
        </TitleGroup>


        {loading && (
          <LoadingsComponent
            type={'SCREEN'}
            color={Colors?.tertiary}
          />
        )}


        {!loading && (
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={props.values}
            onSubmit={(values) => {
              postImport();
            }}>
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (<>

              <ModalBodyStyled>

                <FilterContainerForm auto onSubmit={handleSubmit}>

                  <Row>

                    <Col>
                      <div>
                        <ObsSubTitleGroup>
                          Obs: Somente serão aceitos arquivos em Excel.
                        </ObsSubTitleGroup>


                        <div className="mb-4">
                          <input
                            type="file"
                            accept='application/vnd.ms-Excel, .xlsx'
                            ref={filesElement}
                          />
                        </div>
                      </div>
                    </Col>

                  </Row>

                </FilterContainerForm>

              </ModalBodyStyled>


              <ModalFooterStyled>
                <IButton
                  backgroundColor={Colors?.gray}
                  text={'Fechar'}
                  onClick={props.onHide}
                />

                <IButton
                  backgroundColor={Colors?.primary}
                  text={'Enviar'}
                  onClick={() =>  postImport()}
                />
              </ModalFooterStyled>


            </>)}

          </Formik>
        )}


      </ModalContainerStyled>

    </Modal>

  );
};



export default PEXImportarCRModal;

