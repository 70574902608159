import React,
{
  useState,
} from 'react';

import {
  Container,
} from 'react-bootstrap';


import {
  toast,
} from 'react-toastify';

import {
  Formik,
  Form,
} from 'formik';

import {
  Colors,
  Metrics,
} from '../../../../../common/constants';

import {
  ContentStyled,
  Content,
  Screen,
} from '../../../../../common/styles/styled.layout';


import IButton from '../../../../../components/Buttons/IButton';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import ConfirmationModal from '../../../../../components/Modals/Confirmation';

import NameRoutes from '../../../../../navigation/name';

import {
  RD_NavRoutes,
} from '../../../../../navigation/navs/HumanResources';

import ApiWS from '../../../../../services/api.service';


import {
  GenerateButtonsSMEView,
} from '../../../Supplies/SME/SME_Solicitacao/SME_Nova_Copia/SME-solicitacao.styled';
import IRdInclusaoModel from './RD-inclusao.model';

import {
  AddRdButtonContainer,
} from './RD-inclusao.styled';
import RdInclusaoValidationSchema from './RD-inclusao.validation';
import RDSecaoCriterios from './Sections/RDSecaoCriterios';
import RDSectionRequisicao from './Sections/RDSecaoRequisicao';
import RDSectionAvaliacaoOperacao from './Sections/RDSecaoAvaliacaoOperacao';
import RDSectionAvaliacaoLiderDir from './Sections/RDSecaoAvaliacaoLiderDir';



const RD_InclusaoScreen: React.FC = () => {
  const [valueProgressModal, setValueProgressModal] = useState(false);
  const [progressModalShow, setProgressModalShow] = useState(false);

  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
    orient2: '',
  });


  const objToServer = (values: any) => {
    let newObj = values;

    newObj = {
      ACOCARB: values.aco_carbono,
      ACOINOX: values.aco_inox,
      ADTRANS: values.tipo_contrato,
      ALUMIN: values.aluminio,
      BENEFIC: values.beneficios,
      CARGO: values.input_cargo,
      CC: values.requisicao_os,
      CHAPA: values.chapa,
      CODESC: values.escolaridade,
      DESCARG: values.desc_cargo,
      DESCESC: values.desc_escolaridade,
      DESCRIC: values.desc_funcao,
      DESCSUM: values.desc_sumaria,
      DESCUST: values.desc_cc,
      DESGRTR: values.desc_grupo_atendimento,
      DTABERT: values.data_abertura.replaceAll('-', ''),
      DTFCONT: values.data_fim_contrato.replaceAll('-', ''),
      DTNECES: values.data_necessidade.replaceAll('-', ''),
      DTPREVI: values.data_prev_atend.replaceAll('-', ''),
      ESPCONH: values.espec_conhec,
      FORMCAO: values.formacao_profissional,
      FUNCAO: values.input_funcao,
      FUNSOLD: values.soldador,
      GRTRIAG: values.grupo_atendimento,
      JUSTIF: values.justificativa,
      LIGNIQ: values.ligas_niquel,
      MOTIVO: values.motivo_solicitacao,
      NRVAGA: parseInt(values.vagas),
      OBSERVA: values.obs_gerais,
      OUTRMAT: values.materiais_outros,
      PERF: values.caracteristicas,
      PERICUL: values.peric_insal,
      PRAZO: 3,
      PRZCONT: values.prazo_contrato,
      PSOUTRO: values.processos_outros,
      SOLDELT: values.eletrodo_revestido,
      SOLDTIG: values.tig,
      SOLICIT: values.solicitante,
      SOLMAG: values.mag,
      SOLMIG: values.mig,
      SOLTUB: values.a_tubular,
      TITANIO: values.titanio,
      TPCONTR: values.tipo_contrato,
      VCUSTO: values.valor_salario,
    };
    return newObj;
  };


  async function postRpInclusao(values: any) {
    ApiWS()
      .post('/rd/nova_rd/insere', objToServer(values))
      .then((resp) => {
        if (resp.status === 200) {
          toast.success('RD salva com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setProgressModalShow(true);
          setValueProgressModal(resp.data);
        }
      })
      .catch((err) => {
        toast.error('Ocorreu um erro ao criar RD! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      });
  };

  return (

    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={RdInclusaoValidationSchema}
      initialValues={IRdInclusaoModel}
      onSubmit={(values, isValid) => {
        if (isValid) {
          postRpInclusao(values);
        }

        if (!isValid) {
          setMessageValue({
            head: 'Erro!',
            orient: 'Existem campos obrigatórios que não foram preenchidos.',
            orient2: 'Por favor revise o formulário!',
          });
          setMessageModal(true);
        }
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (

        <Screen>

          <NavHeader>
            <NavUser backHome />


            <NavComp
              navKey={2}
              navArray={RD_NavRoutes}
              title={'RD'}
            />
          </NavHeader>



          <Content>
            <ContentStyled fluid>
              <Container>

                <Form noValidate onSubmit={handleSubmit}>
                  {/* {JSON.stringify(values)} */}

                  <RDSectionRequisicao
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />


                  <RDSecaoCriterios />


                  <RDSectionAvaliacaoLiderDir
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />


                  <RDSectionAvaliacaoOperacao
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />


                  <AddRdButtonContainer>
                    <IButton
                      // disabled={!isValid} // {!isValid || isSubmitting}
                      type={'submit'}
                      backgroundColor={Colors?.primary}
                      text={'Finalizar'}
                    />
                  </AddRdButtonContainer>

                </Form>

              </Container>
            </ContentStyled>
          </Content>


          {progressModalShow && (
            <ConfirmationModal
              show={progressModalShow}
              head="Feito!"
              orient={`RD ${valueProgressModal} gerada com sucesso`}
              others={
                <GenerateButtonsSMEView>
                  <IButton
                    backgroundColor={Colors?.tertiary}
                    onClick={() => {
                      window.open(`/${NameRoutes.RD_Impressao}/${valueProgressModal}`, '_blank').focus();
                    }}
                    text={'Imprimir'}
                  />

                </GenerateButtonsSMEView>
              }
              negative={() => {
                window.location.reload();
                setProgressModalShow(false);
                setTimeout(() => {
                  setValueProgressModal(null);
                }, 300);
              }}
            />
          )}

          {
            messageModal && (
              <ConfirmationModal
                show={messageModal}
                head={messageValue.head}
                orient={messageValue.orient}
                orient2={messageValue.orient2}
                negative={() => {
                  setMessageModal(false);
                }}
              />
            )
          }

        </Screen>
      )}
    </Formik>

  );
};



export default RD_InclusaoScreen;

