import React from 'react';

import NotFoundScreen from '../../screens/Errors/404';

import USER_HelpScreen from '../../screens/Help';
import HomeScreen from '../../screens/Home';
import LoginScreen from '../../screens/Login/index';
import LogoutScreen from '../../screens/Logout';

import CENC_IndexScreen from '../../screens/Modules/Controllership/CENC';
import GERC_IndexScreen from '../../screens/Modules/Controllership/GERC';
import CHR_IndexScreen from '../../screens/Modules/Corporate/GHR';
import PEX_AprovacaoIndexScreen from '../../screens/Modules/Corporate/PEX/PEX_Aprovacao';
import PEX_AtualizarMesRefIndexScreen from '../../screens/Modules/Corporate/PEX/PEX_AtualizarMesRef';
import PEX_ConsultaIndexScreen from '../../screens/Modules/Corporate/PEX/PEX_Consulta';
import PEX_ContasOperacionaisScreen from '../../screens/Modules/Corporate/PEX/PEX_ContasOperacionais';
import PEX_ImportacaoIndexScreen from '../../screens/Modules/Corporate/PEX/PEX_Importacao';
import PFF_AprovacaoScreen from '../../screens/Modules/Corporate/PFF/PFF_Aprovacao';
import PFF_ConsultaScreen from '../../screens/Modules/Corporate/PFF/PFF_Consulta';
import PFF_LancamentoScreen from '../../screens/Modules/Corporate/PFF/PFF_Lancamento';
import PG_IndexScreen from '../../screens/Modules/Corporate/PG';
import FUNC_IndexScreen from '../../screens/Modules/HumanResources/EFETIVO';
import PAV_ConfiguracoesScreen from '../../screens/Modules/HumanResources/PAV/PAV_Configuracoes';
import PAV_DestaquesScreens from '../../screens/Modules/HumanResources/PAV/PAV_Destaques';
import PAV_ReconhecimentoScreen from '../../screens/Modules/HumanResources/PAV/PAV_Reconhecimento';
import RD_AprovacaoScreen from '../../screens/Modules/HumanResources/RD/RD_Aprovacao';
import RD_AtendimentoScreen from '../../screens/Modules/HumanResources/RD/RD_Atendimento';
import RD_HomeScreen from '../../screens/Modules/HumanResources/RD/RD_Home';
import RD_ImpressaoScreen from '../../screens/Modules/HumanResources/RD/RD_Impressao';
import RD_InclusaoScreen from '../../screens/Modules/HumanResources/RD/RD_Inclusao';
import RD_TriagemScreen from '../../screens/Modules/HumanResources/RD/RD_Triagem';

import RP_AprovacaoScreen from '../../screens/Modules/HumanResources/RP/RP_Aprovacao';
import RP_AtendimentoScreen from '../../screens/Modules/HumanResources/RP/RP_Atendimento';
import RP_HomeScreen from '../../screens/Modules/HumanResources/RP/RP_Home';
import RP_ImpressaoScreen from '../../screens/Modules/HumanResources/RP/RP_Impressao';
import RP_InclusaoScreen from '../../screens/Modules/HumanResources/RP/RP_Inclusao';
import RP_TriagemScreen from '../../screens/Modules/HumanResources/RP/RP_Triagem';

import PIC_CadastroScreen from '../../screens/Modules/SGI/PIC/PIC_Cadastro';
import PIC_CadastroAreaScreen from '../../screens/Modules/SGI/PIC/PIC_Cadastro_Area';
import PIC_CadastroEquipeScreen from '../../screens/Modules/SGI/PIC/PIC_Cadastro_Equipe';
import PIC_ConsultaScreen from '../../screens/Modules/SGI/PIC/PIC_Consulta';
import PIC_ConsultaImpressaoScreen from '../../screens/Modules/SGI/PIC/PIC_Consulta_Impressao';

import POC_CadastroScreen from '../../screens/Modules/SGI/POC/POC_Cadastro';
import POC_ConsultaScreen from '../../screens/Modules/SGI/POC/POC_Consulta';
import POC_ConsultaImpressaoScreen from '../../screens/Modules/SGI/POC/POC_Consulta_Impressao';

import FORNECEDORES_IndexScreen from '../../screens/Modules/Supplies/FORNECEDORES';

import PBC_AnexoScreen from '../../screens/Modules/Supplies/PBC/PBC_Anexo';
import PBC_AprovacaoScreen from '../../screens/Modules/Supplies/PBC/PBC_Aprovacao';
import PBC_ImpressaoBM from '../../screens/Modules/Supplies/PBC/PBC_Bm_Impressao';
import PBC_ContratosScreen from '../../screens/Modules/Supplies/PBC/PBC_Contratos';
import PBC_ContratosItensImpressao from '../../screens/Modules/Supplies/PBC/PBC_Contratos_Impressao';
import PBC_ManutencaoScreen from '../../screens/Modules/Supplies/PBC/PBC_Manutencao';
import PBC_ImpressaoPC from '../../screens/Modules/Supplies/PBC/PBC_Pc_Impressao';
import PBC_PedidosScreen from '../../screens/Modules/Supplies/PBC/PBC_Pedidos';

import PSTOCK_AdministracaoScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Administracao';
import NovoFornecedorScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Administracao/TabFornecedores/Novo';
import NovoFuncionarioScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Administracao/TabFuncionarios/Novo';
import NovoProdutoScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Administracao/TabProdutos/Novo';
import PSTOCK_CriacaoScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Criacao';
import PSTOCK_EntradaScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Entrada';
import PSTOCK_HomeScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Home';
import PSTOCK_MonitorScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Monitor';
import PSTOCK_RelatoriosScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Relatorios';
import PSTOCK_SaidaScreen from '../../screens/Modules/Supplies/PSTOCK/PSTOCK_Saida';

import SME_Aprovacao from '../../screens/Modules/Supplies/SME/SME_Aprovacao';
import SME_AtendimentoScreen from '../../screens/Modules/Supplies/SME/SME_Atendimento';
import SME_HomeScreen from '../../screens/Modules/Supplies/SME/SME_Home';
import SME_Impressao from '../../screens/Modules/Supplies/SME/SME_Impressao';
import SME_ManutencaoScreen from '../../screens/Modules/Supplies/SME/SME_ManutencaoTriagem';
import SME_MonitorScreen from '../../screens/Modules/Supplies/SME/SME_Monitor';
import SME_SolicitacaoScreen from '../../screens/Modules/Supplies/SME/SME_Solicitacao';
import SME_NovaScreen from '../../screens/Modules/Supplies/SME/SME_Solicitacao/SME_Nova_Copia';
import SME_TriagemScreen from '../../screens/Modules/Supplies/SME/SME_Triagem';



import GI_AdministracaoScreen from '../../screens/Modules/TI/GI/GI_Administracao';
import GI_ConsultaScreen from '../../screens/Modules/TI/GI/GI_Consulta';
import USER_HomeScreen from '../../screens/User';
import NameRoutes from '../name';



type Route = {
  path: string;
  exact?: boolean;
  isPublic?: boolean;
  page: React.FC;
};



const ScreenRoutes: Route[] = [

  // Login [Default]
  {
    path: '/',
    page: LoginScreen,
    isPublic: true,
    exact: true,
  },
  // Logout
  {
    path: '/logout',
    page: LogoutScreen,
    isPublic: true,
    exact: true,
  },

  // Home
  {
    path: `/${NameRoutes.Home}`,
    page: HomeScreen,
    isPublic: false,
    exact: true,
  },

  // Usuário
  {
    path: `/${NameRoutes.USER_Home}`,
    page: USER_HomeScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.USER_Home}/:param`,
    page: USER_HomeScreen,
    isPublic: false,
    exact: true,
  },

  // Ajuda
  {
    path: `/${NameRoutes.USER_Help}`,
    page: USER_HelpScreen,
    isPublic: false,
    exact: true,
  },



  // ----- Módulo Corporativo -----

  // Painel Gerencial
  {
    path: `/${NameRoutes.PG_Home}`,
    page: PG_IndexScreen,
    isPublic: false,
    exact: true,
  },

  // PEX
  {
    path: `/${NameRoutes.PEX_ContasOperacionais}`,
    page: PEX_ContasOperacionaisScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PEX_Importacao}`,
    page: PEX_ImportacaoIndexScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PEX_Aprovacao}`,
    page: PEX_AprovacaoIndexScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PEX_Consulta}`,
    page: PEX_ConsultaIndexScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PEX_AtualizarMesRef}`,
    page: PEX_AtualizarMesRefIndexScreen,
    isPublic: false,
    exact: true,
  },

  // PFF
  {
    path: `/${NameRoutes.PFF_Lancamento}`,
    page: PFF_LancamentoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PFF_Consulta}`,
    page: PFF_ConsultaScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PFF_Aprovacao}`,
    page: PFF_AprovacaoScreen,
    isPublic: false,
    exact: true,
  },


  // CHR
  {
    path: `/${NameRoutes.HORAS_Home}`,
    page: CHR_IndexScreen,
    isPublic: false,
    exact: true,
  },


  // ----- Módulo Controladoria -----

  // Custo Gerencial
  {
    path: `/${NameRoutes.GERC_Home}`,
    page: GERC_IndexScreen,
    isPublic: false,
    exact: true,
  },

  // Central de Custos
  {
    path: `/${NameRoutes.CENC_Home}`,
    page: CENC_IndexScreen,
    isPublic: false,
    exact: true,
  },


  // ----- Módulo Recursos Humanos -----

  // RP
  {
    path: `/${NameRoutes.RP_Home}`,
    page: RP_HomeScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RP_Inclusao}`,
    page: RP_InclusaoScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RP_Aprovacao}`,
    page: RP_AprovacaoScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RP_Atendimento}`,
    page: RP_AtendimentoScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RP_Triagem}`,
    page: RP_TriagemScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RP_Impressao}/:id`,
    page: RP_ImpressaoScreen,
    isPublic: false,
    exact: true,
  },

  // Efetivo
  {
    path: `/${NameRoutes.FUNC_Home}`,
    page: FUNC_IndexScreen,
    isPublic: false,
    exact: true,
  },


  // ----- Módulo Requisição Demissão -----

  // RD
  {
    path: `/${NameRoutes.RD_Home}`,
    page: RD_HomeScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RD_Inclusao}`,
    page: RD_InclusaoScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RD_Aprovacao}`,
    page: RD_AprovacaoScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RD_Atendimento}`,
    page: RD_AtendimentoScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RD_Triagem}`,
    page: RD_TriagemScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.RD_Impressao}/:id`,
    page: RD_ImpressaoScreen,
    isPublic: false,
    exact: true,
  },

  // Efetivo
  {
    path: `/${NameRoutes.FUNC_Home}`,
    page: FUNC_IndexScreen,
    isPublic: false,
    exact: true,
  },


  // PAV
  {
    path: `/${NameRoutes.PAV_Destaques}`,
    page: PAV_DestaquesScreens,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PAV_Reconhecimento}`,
    page: PAV_ReconhecimentoScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PAV_Configuracoes}`,
    page: PAV_ConfiguracoesScreen,
    isPublic: false,
    exact: true,
  },



  // ----- Módulo SGI -----

  // PIC
  {
    path: `/${NameRoutes.PIC_Cadastro_Pic}`,
    page: PIC_CadastroScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PIC_Cadastro_Equipe}`,
    page: PIC_CadastroEquipeScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PIC_Cadastro_Area}`,
    page: PIC_CadastroAreaScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PIC_Consulta}`,
    page: PIC_ConsultaScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.PIC_Consulta_Impressao}/:id`,
    page: PIC_ConsultaImpressaoScreen,
    isPublic: false,
    exact: true,
  },


  // POC
  {
    path: `/${NameRoutes.POC_Cadastro}`,
    page: POC_CadastroScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.POC_Consulta}`,
    page: POC_ConsultaScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.POC_Consulta_Impressao}/:id`,
    page: POC_ConsultaImpressaoScreen,
    isPublic: false,
    exact: true,
  },


  // ----- Módulo Tecnologia da Informaçao -----

  // Gestão de Identidade
  {
    path: `/${NameRoutes.GI_Administracao}`,
    page: GI_AdministracaoScreen,
    isPublic: false,
    exact: true,
  },
  {
    path: `/${NameRoutes.GI_Consulta}`,
    page: GI_ConsultaScreen,
    isPublic: false,
    exact: true,
  },



  // ----- Módulo Suprimentos -----

  // Compras / Contratos / BM
  {
    path: `/${NameRoutes.PBC_Aprovacao}`,
    page: PBC_AprovacaoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PBC_Pedidos}`,
    page: PBC_PedidosScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PBC_Contratos}`,
    page: PBC_ContratosScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PBC_Anexo}`,
    page: PBC_AnexoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PBC_Manutencao}`,
    page: PBC_ManutencaoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PBC_ContratosItensImpressao}/:filial/:contrato/:rev/:plan`,
    page: PBC_ContratosItensImpressao,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PBC_ImpressaoBM}/:filial/:pedido`,
    page: PBC_ImpressaoBM,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PBC_ImpressaoPC}/:filial/:pedido`,
    page: PBC_ImpressaoPC,
    isPublic: false,
    exact: true,
  },


  // SME
  {
    path: `/${NameRoutes.SME_Home}`,
    page: SME_HomeScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.SME_Solicitacao}`,
    page: SME_SolicitacaoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.SME_Nova}`,
    page: SME_NovaScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.SME_Aprovacao}`,
    page: SME_Aprovacao,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.SME_Triagem}`,
    page: SME_TriagemScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.SME_Atendimento}`,
    page: SME_AtendimentoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.SME_Impressao}/:id`,
    page: SME_Impressao,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.SME_Monitor}`,
    page: SME_MonitorScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.SME_ManutencaoTriagem}`,
    page: SME_ManutencaoScreen,
    isPublic: false,
    exact: true,
  },


  // Fornecedores
  {
    path: `/${NameRoutes.FORNECEDORES_Home}`,
    page: FORNECEDORES_IndexScreen,
    isPublic: false,
    exact: true,
  },


  // PStock
  {
    path: `/${NameRoutes.PSTOCK_HomeScreen}`,
    page: PSTOCK_HomeScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PSTOCK_AdministracaoScreen}`,
    page: PSTOCK_AdministracaoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PSTOCK_AdministracaoScreen}`,
    page: PSTOCK_AdministracaoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PSTOCK_ParametrizacaoScreen}`,
    page: PSTOCK_CriacaoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PSTOCK_MonitorScreen}`,
    page: PSTOCK_MonitorScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PSTOCK_RelatoriosScreen}`,
    page: PSTOCK_RelatoriosScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.NovoFuncionarioScreen}`,
    page: NovoFuncionarioScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.NovoFornecedorScreen}`,
    page: NovoFornecedorScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.NovoProdutoScreen}`,
    page: NovoProdutoScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PSTOCK_EntradaScreen}`,
    page: PSTOCK_EntradaScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: `/${NameRoutes.PSTOCK_SaidaScreen}`,
    page: PSTOCK_SaidaScreen,
    isPublic: false,
    exact: true,
  },



  // 404
  {
    path: null,
    page: NotFoundScreen,
    isPublic: true,
    exact: false,
  },

];



export default ScreenRoutes;
