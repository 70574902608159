import { currentCompleteYear } from '../../../../../utils/DateUtils';

const IImportacaoModel = {

  TIPO: '',

  MES: '',

  ANO: currentCompleteYear(),

  CR: '',

};



export default IImportacaoModel;
