import React,
{
  useEffect,
  useState,
} from 'react';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { PcbPedidosArray } from '../../../../../common/arrays';

import {
  Colors, Metrics,
} from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';

import {
  TableTdButtonNextCss,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import NoItemsContent from '../../../../../components/Contents/NoItems';
import SubtitleComponent from '../../../../../components/Contents/Subtitles';

import {
  IconList,
} from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';

import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
  ModalFirstStyled,
  ModalTableContainer,
  ModalTableObsText,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';
import NameRoutes from '../../../../../navigation/name';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';
import { convertCurrencyBRL } from '../../../../../utils/CurrencyUtils';
import SME_NovaScreen from '../../SME/SME_Solicitacao/SME_Nova_Copia';
import { StatusPedidosView } from '../PBC_Pedidos/PBC-pedidos.styled';



interface IProps {
  show: boolean;
  values?: any;
  cotacaoClick: any;
  onHide?: any;
}



const ItensPedidoModal: React.FC<IProps> = (props) => {
  const pasta = localStorage.getItem('@intranet/pasta');

  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  function fetchItems(page_iteration) {
    setItems([]);

    const {
      TIPO,
      FILIAL,
      PEDIDO,
    } = props.values;

    const payload = {
      'TIPO': TIPO,
      'FILIAL': FILIAL,
      'NUM': PEDIDO,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    };

    setValuesPayload(payload);

    setLoading(true);

    ApiWS()
      .post('/suprimento/consulta/pedido/itens', payload)
      .then((resp) => {
        setItems(resp.data[0]);
        setTotalResults(resp.data[1][0].TOTAL);
        setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
      })
      .catch((error) => {
        console.error('fetchItems', JSON.stringify(error.response, null, 2));
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postPrinterSME(values: any) {
    setLoading(true);

    const url = `/sme/atendimento/impressao/${values.C7_FILIAL}/${values.C7_NUMSM}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function renderBtnPrintSME(cell, row) {
    return <TableTdButtonNextCss onClick={() => {
      // window.open(`/${NameRoutes.SME_Impressao}/${row.C7_FILIAL}${row.C7_NUMSM}`, '_blank');4
      postPrinterSME(row);
    }}>{row.C7_NUMSM}</TableTdButtonNextCss>;
  };


  function renderBtnQuotation(cell: any, row: any) {
    return (
      !loading
        ? (
          <ButtonTableAction
            tooltip={
              row.TIPO !== 'CP'
                ? TextTooltip.QUOTATION
                : TextTooltip.LESS_QUOTATION
            }
            onClick={() => {
              row.TIPO !== 'CP' &&
                props.cotacaoClick({
                  'FILIAL': row.C7_FILIAL,
                  'NUMCOT': row.C7_NUMCOT.replaceAll(' ', ''),
                  'CODPROD': row.C7_PRODUTO.replaceAll(' ', ''),
                });
            }}
            icon={
              <IconList
                color={
                  row.TIPO !== 'CP'
                    ? Colors?.black
                    : Colors?.gray
                }
                size={16}
              />
            }
          />
        )

        : (
          <LoadingsComponent
            size='1rem'
            type='FIELD'
            color={Colors?.primary}
          />
        )
    );
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    fetchItems(page_var);
    setPage(page_var);
  };

  useEffect(() => {
    fetchItems(1);
  }, []);



  return (

    <ModalFirstStyled
      {...props}
      dialogClassName="modal-first-dialog modal-95"
      backdropClassName="modal-first-backdrop"
      show={props.show}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Itens do Pedido'.toUpperCase()}
        </TitleGroup>



        <ModalBodyStyled>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!loading && (!items || items === null || items.length === 0) && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                Filial: {props.values.FILIAL} | Num: {props.values.PEDIDO}
              </ModalTableObsText>

              <NoItemsContent />

            </ModalTableContainer>
          )}



          {!loading && items && items.length > 0 && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                Filial: {props.values.FILIAL} | Num: {props.values.PEDIDO}
              </ModalTableObsText>


              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  totalSize: items.length,
                })}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={totalPage === 1}
                    data={items}
                    columns={[
                      {
                        dataField: 'C7_ITEM',
                        text: 'Item',
                        sort: true,
                        headerStyle: () => {
                          return { width: '4%' };
                        },
                      },
                      {
                        dataField: 'TIPO',
                        text: 'Tipo',
                        sort: true,
                        headerStyle: () => {
                          return { width: '3%' };
                        },
                      },
                      {
                        dataField: 'C7_NUM',
                        text: 'PC',
                        sort: true,
                        headerStyle: () => {
                          return { width: '6%' };
                        },
                      },
                      {
                        dataField: 'C7_DESCRI',
                        text: 'Produto',
                        sort: true,
                        headerStyle: () => {
                          return { width: '12%' };
                        },
                      },
                      {
                        dataField: 'C7_QUANT',
                        text: 'Qtd.',
                        sort: true,
                        headerStyle: () => {
                          return { width: '3%' };
                        },
                      },
                      {
                        dataField: 'C7_QTDATEND',
                        text: 'Qtd. Atend.',
                        sort: true,
                        headerStyle: () => {
                          return { width: '4%' };
                        },
                      },
                      {
                        dataField: 'C7_QTDPEN',
                        text: 'Qtd. Pend.',
                        sort: true,
                        headerStyle: () => {
                          return { width: '4%' };
                        },
                      },
                      {
                        dataField: 'C7_UM',
                        text: 'UN',
                        sort: true,
                        headerStyle: () => {
                          return { width: '4%' };
                        },
                      },
                      {
                        dataField: 'C7_PRECO',
                        text: 'Valor UN',
                        sort: true,
                        formatter: (cell) => {
                          return convertCurrencyBRL(cell);
                        },
                        headerStyle: () => {
                          return { width: '10%' };
                        },
                      },
                      {
                        dataField: 'C7_TOTAL',
                        text: 'Total',
                        sort: true,
                        formatter: (cell) => {
                          return convertCurrencyBRL(cell);
                        },
                        headerStyle: () => {
                          return { width: '10%' };
                        },
                      },
                      {
                        dataField: '',
                        text: 'SME',
                        sort: true,
                        formatter: (cell, row) => {
                          return renderBtnPrintSME(cell, row);
                        },
                        headerStyle: () => {
                          return { width: '6%' };
                        },
                      },
                      {
                        dataField: 'C7_ITEMSM',
                        text: 'Item SME',
                        sort: true,
                        headerStyle: () => {
                          return { width: '6%' };
                        },
                      },
                      {
                        dataField: 'PRE_ULT',
                        text: '% Ult. Compra',
                        sort: true,
                        headerStyle: () => {
                          return { width: '6%' };
                        },
                      },
                      {
                        dataField: 'C7_QTDACLA',
                        text: 'Pré NF',
                        sort: true,
                        headerStyle: () => {
                          return { width: '6%' };
                        },
                      },
                      {
                        dataField: 'SIT',
                        text: 'Situação',
                        headerStyle: () => {
                          return { width: '5%' };
                        },
                        formatter: (cell): JSX.Element => {
                          const valuePcbSit = PcbPedidosArray.map((pcb) => (
                            pcb.key.toString() === cell?.toString() && (
                              <SubtitleComponent
                                noSubtitle={true}
                                backgroundColor={pcb.color}
                              />
                            )
                          ));

                          return (
                            <StatusPedidosView>
                              {valuePcbSit}
                            </StatusPedidosView>
                          );
                        },
                      },
                      {
                        dataField: 'B1_UCOM',
                        text: 'Data Ult. Compra',
                        sort: true,
                        headerStyle: () => {
                          return { width: '10%' };
                        },
                      },
                      {
                        dataField: 'DTENT',
                        text: 'Data Entrega',
                        sort: true,
                        headerStyle: () => {
                          return { width: '10%' };
                        },
                      },
                      {
                        dataField: '',
                        text: 'Cotação',
                        headerStyle: () => {
                          return { width: '3%' };
                        },
                        formatter: (cell, row) => {
                          // Botão Modal Cotação
                          return renderBtnQuotation(cell, row);
                        },
                      },
                    ]}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                    actionPrevious={() => handlePagination(0)}
                    actionNext={() => handlePagination(1)}
                    currentPage={page}
                    totalPage={totalPage}
                    totalResults={totalResults}
                  />
                )}
              </PaginationProvider>

            </ModalTableContainer>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>
          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />
        </ModalFooterStyled>

      </ModalContainerStyled>

    </ModalFirstStyled>

  );
};



export default ItensPedidoModal;
