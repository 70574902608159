import * as Yup from 'yup';


const PEXConsultaValidationSchema = Yup.object().shape({

  CO: Yup.string(),

  CC: Yup.string(),

});



export default PEXConsultaValidationSchema;
